import { EventEmitter } from '@angular/core';
import { StatusType } from 'src/app/Enums/status.type.enum';
import { LangsRepository } from 'src/app/services/langs.repository';
import { LeadClientRepositoryBUT } from 'src/app/services/lead.clients.repository.but';
import { ProcessesRepositoryBUT } from 'src/app/services/processes.repository.but';
import { SubstatusesRepository } from 'src/app/services/substatuses.repository';
import { AppService } from '../../services/app.service';
var ProcessesReplaceOverlayComponent = /** @class */ (function () {
    function ProcessesReplaceOverlayComponent(langsRepository, processesRepository, appService, leadClientsRepository, substatusesRepository) {
        this.langsRepository = langsRepository;
        this.processesRepository = processesRepository;
        this.appService = appService;
        this.leadClientsRepository = leadClientsRepository;
        this.substatusesRepository = substatusesRepository;
        this.ready = false;
        this.activeChange = new EventEmitter();
        this.cancel = new EventEmitter();
        this.processId = 0;
        this.langsReady = false;
        this.substatusesReady = false;
        this.processesReady = false;
        this.targetProcessId = null;
        this.ready = true;
        this.init();
    }
    ProcessesReplaceOverlayComponent.prototype.close = function () {
        this.active = false;
        this.activeChange.emit(false);
        this.targetProcessId = null;
    };
    ProcessesReplaceOverlayComponent.prototype.init = function () {
        var _this = this;
        this.langsRepository.ready.subscribe(function (r) { if (r)
            _this.langsReady = true; });
    };
    Object.defineProperty(ProcessesReplaceOverlayComponent.prototype, "processes", {
        get: function () {
            var _this = this;
            return this.processesRepository.processes.filter(function (x) { return x.id != _this.processId; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProcessesReplaceOverlayComponent.prototype, "targetProcess", {
        get: function () {
            var _this = this;
            return this.processesRepository.processes.find(function (x) { return x.id == _this.targetProcessId; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProcessesReplaceOverlayComponent.prototype, "substatusesFromTargetProcess", {
        get: function () {
            var list = [];
            if (this.targetProcessId) {
                list.push.apply(list, this.targetProcess.statuses.find(function (x) { return x.id == StatusType.InProcess; }).subStatuses.filter(function (x) { return x.isSelected; }));
                list.push.apply(list, this.targetProcess.statuses.find(function (x) { return x.id == StatusType.Meeting; }).subStatuses.filter(function (x) { return x.isSelected; }));
                list.push.apply(list, this.targetProcess.statuses.find(function (x) { return x.id == StatusType.Closed; }).subStatuses.filter(function (x) { return x.isSelected; }));
                list.push.apply(list, this.targetProcess.statuses.find(function (x) { return x.id == StatusType.NotGood; }).subStatuses.filter(function (x) { return x.isSelected; }));
            }
            return list;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProcessesReplaceOverlayComponent.prototype, "subStatusForInProcess", {
        // get substatuses(): ISubstatus[] { return this.substatusesRepository.substatuses; }
        get: function () { return this.substatusesFromTargetProcess.filter(function (x) { return x.statusId == StatusType.InProcess; }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProcessesReplaceOverlayComponent.prototype, "subStatusForMeetingProcess", {
        get: function () { return this.substatusesFromTargetProcess.filter(function (x) { return x.statusId == StatusType.Meeting; }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProcessesReplaceOverlayComponent.prototype, "subStatusForDealProcess", {
        get: function () { return this.substatusesFromTargetProcess.filter(function (x) { return x.statusId == StatusType.Closed; }); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProcessesReplaceOverlayComponent.prototype, "subStatusForNotGoodProcess", {
        get: function () { return this.substatusesFromTargetProcess.filter(function (x) { return x.statusId == StatusType.NotGood; }); },
        enumerable: true,
        configurable: true
    });
    ProcessesReplaceOverlayComponent.prototype.setTargetProcess = function (pr) {
        this.targetProcessId = pr.id;
    };
    ProcessesReplaceOverlayComponent.prototype.delete = function (ss) {
        var _this = this;
        if (confirm(this.currentLang.words["sure"])) {
            this.appService.showNetworkstatus("sending data...");
            var sub = this.processesRepository.processes.find(function (x) { return x.id == _this.processId; });
            sub.targetProcessId = this.targetProcessId;
            sub.targetSubStatusId = ss.id;
            this.processesRepository.deleteProcess(sub).subscribe(function (res) {
                if (res.responseCode === 200) {
                    _this.appService.showNetworkstatus("sent ok");
                    _this.processesRepository.load();
                }
                else
                    _this.appService.showError("API error", res.error || res.Error, true, res.responseCode);
                _this.close();
                _this.targetProcessId = null;
            }, function (err) {
                var message = _this.appService.getErrorMessage(err);
                _this.appService.showError("HTTP error", _this.currentLang.words["http_error"] + " in process-replace-overlay delete deleteProcess: " + message, true);
            });
            var leadClients = this.appService.leadClients.filter(function (x) { return x.processId == sub.id; });
            leadClients.forEach(function (leadClient) {
                leadClient.processId = sub.targetProcessId;
                leadClient.additionalStatusId = sub.targetSubStatusId;
                leadClient.leadStatusId = ss.statusId;
            });
            this.processesRepository.delete(sub);
        }
    };
    ProcessesReplaceOverlayComponent.prototype.replaceToGlobalStatus = function (statusId) {
        var _this = this;
        if (confirm(this.currentLang.words["sure"])) {
            var ss = { statusId: statusId, position: 0, replaceStatusId: 0, id: 0 };
            this.appService.showNetworkstatus("sending data...");
            var sub = this.processesRepository.processes.find(function (x) { return x.id == _this.processId; });
            sub.targetProcessId = this.targetProcessId;
            sub.targetSubStatusId = ss.id;
            sub.targetLeadStatusId = statusId;
            this.processesRepository.deleteProcess(sub).subscribe(function (res) {
                if (res.responseCode === 200) {
                    _this.appService.showNetworkstatus("sent ok");
                    _this.processesRepository.load();
                }
                else
                    _this.appService.showError("API error", res.error || res.Error, true, res.responseCode);
                _this.close();
                _this.targetProcessId = null;
            }, function (err) {
                var message = _this.appService.getErrorMessage(err);
                _this.appService.showError("HTTP error", _this.currentLang.words["http_error"] + " in process-replace-overlay replaceToGlobalStatus deleteProcess: " + message, true);
            });
            var leadClients = this.appService.leadClients.filter(function (x) { return x.processId == sub.id; });
            leadClients.forEach(function (leadClient) {
                leadClient.processId = sub.targetProcessId;
                leadClient.additionalStatusId = sub.targetSubStatusId;
                leadClient.leadStatusId = ss.statusId;
            });
            this.processesRepository.delete(sub);
        }
    };
    return ProcessesReplaceOverlayComponent;
}());
export { ProcessesReplaceOverlayComponent };
