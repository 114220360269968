import * as tslib_1 from "tslib";
import { DataService } from './DataServices/data.service';
import { BehaviorSubject } from 'rxjs';
import { LeadsRepository } from './leads.repository';
import { LangsRepository } from './langs.repository';
var LeadsRepositoryBUT = /** @class */ (function (_super) {
    tslib_1.__extends(LeadsRepositoryBUT, _super);
    function LeadsRepositoryBUT(dataService, langsRepository) {
        var _this = _super.call(this, dataService, langsRepository) || this;
        _this.dataService = dataService;
        _this.langsRepository = langsRepository;
        _this.lastReload = 0;
        _this.ready = new BehaviorSubject(false);
        _this.dataService.token.subscribe(function (token) {
            if (token) {
                _this.ready.next(true);
            }
            else {
                _this.leads = [];
                _this.ready.next(false);
            }
        });
        return _this;
    }
    LeadsRepositoryBUT.prototype.loadLeads = function (from, q, search, filter, sort, dir) {
        return this.dataService.but_getLeads(from, q, search, filter, sort, dir);
    };
    return LeadsRepositoryBUT;
}(LeadsRepository));
export { LeadsRepositoryBUT };
