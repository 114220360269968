var ProcessEditComponent = /** @class */ (function () {
    function ProcessEditComponent() {
    }
    ProcessEditComponent.prototype.isEmpty = function (v) {
        return v === null || v === undefined;
    };
    Object.defineProperty(ProcessEditComponent.prototype, "blueSubs", {
        get: function () { return this.getStatuses(4); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProcessEditComponent.prototype, "redSubs", {
        get: function () { return this.getStatuses(11); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProcessEditComponent.prototype, "yellowSubs", {
        get: function () { return this.getStatuses(7); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProcessEditComponent.prototype, "greenSubs", {
        get: function () { return this.getStatuses(9); },
        enumerable: true,
        configurable: true
    });
    ProcessEditComponent.prototype.getStatuses = function (statusType) {
        if (this.process.statuses) {
            var globalStatus = this.process.statuses.some(function (x) { return x.id == 4; });
            if (globalStatus) {
                var status_1 = this.process.statuses.find(function (x) { return x.id == statusType; });
                if (status_1) {
                    return status_1.subStatuses.sort(this.sortBy);
                }
            }
        }
        return [];
    };
    ProcessEditComponent.prototype.sortBy = function (item1, item2) {
        if (item1.position < item2.position)
            return -1;
        if (item1.position > item2.position)
            return 1;
        if (item1.title > item2.title)
            return 1;
        else if (item1.title < item2.title)
            return -1;
        return 0;
    };
    ProcessEditComponent.prototype.clearInput = function (field) {
        switch (field) {
            case 'title':
                this.process.title = '';
                break;
            default: break;
        }
    };
    return ProcessEditComponent;
}());
export { ProcessEditComponent };
