import * as tslib_1 from "tslib";
import { Model } from '../model';
var LeadClientWhatsappReceivedMessage = /** @class */ (function (_super) {
    tslib_1.__extends(LeadClientWhatsappReceivedMessage, _super);
    function LeadClientWhatsappReceivedMessage(leadClientID, message, messageContentType) {
        var _this = _super.call(this) || this;
        _this.leadClientID = leadClientID;
        _this.message = message;
        _this.messageContentType = messageContentType;
        return _this;
    }
    return LeadClientWhatsappReceivedMessage;
}(Model));
export { LeadClientWhatsappReceivedMessage };
