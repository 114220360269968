import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Calendar } from '@ionic-native/calendar/ngx';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { Platform } from '@ionic/angular';
import { LeadClientsGroup } from "../model/leadClients/lead.clients.group.model";
import * as Sentry from "@sentry/angular";
var AppService = /** @class */ (function () {
    function AppService(platform, router, calendar, firebaseX) {
        this.platform = platform;
        this.router = router;
        this.calendar = calendar;
        this.firebaseX = firebaseX;
        this.menuClass = "";
        this.networkstatus = "";
        this.networkstatusClass = "";
        this.networkstatusTimer = null;
        this.error = "";
        this.errorActive = false;
        this.downloadPopupActive = false;
        this.introPopupActive = false;
        this.permissionPopupActive = false;
        this.ieWarningPopupActive = false;
        this.versionUpdatePopupActive = false;
        this.tooltipPopupActive = false;
        this.textOfTooltipPopup = "";
        this.titleOfTooltipPopup = "";
        this.isBusy = false;
        this.leadClients = [];
        this.leadClientsGroup = [];
        this.sorting = "lastMessageDate";
        this.dir = 1;
        this.processId = null;
        this.isLoadingLeadCLients = false;
        this.statusesClass = "";
        this.userClass = "";
        this.currentFilter = 0;
        // for overlays
        this.statusesActive = false;
        this.calendarActive = false;
        this.needResyncLeadClient = false;
    }
    Object.defineProperty(AppService.prototype, "isMobile", {
        get: function () { return this.platform.is("mobile"); },
        enumerable: true,
        configurable: true
    });
    AppService.prototype.range = function (a, b) {
        var res = [];
        for (var i = a; i <= b; i++) {
            res.push(i);
        }
        return res;
    };
    AppService.prototype.twoDigits = function (n) {
        return (n < 10) ? "0" + n : "" + n;
    };
    AppService.prototype.showNetworkstatus = function (s) {
        var _this = this;
        if (this.networkstatusTimer) {
            clearTimeout(this.networkstatusTimer);
        }
        this.networkstatus = s;
        this.networkstatusClass = "active";
        this.networkstatusTimer = setTimeout(function () {
            _this.networkstatusClass = "";
            _this.networkstatusTimer = null;
        }, 2000);
    };
    AppService.prototype.showError = function (errType, errMsg, isServiceNotAvailable, responseCode, throwException, throwErrorMsg) {
        if (isServiceNotAvailable === void 0) { isServiceNotAvailable = false; }
        if (responseCode === void 0) { responseCode = undefined; }
        if (throwException === void 0) { throwException = true; }
        if (throwErrorMsg === void 0) { throwErrorMsg = null; }
        var unauthorized = errMsg && (errMsg === "User not found"
            || errMsg === "code: 404, error: User not found"
            || errMsg.includes("Unauthorized")
            || errMsg.includes("- token filter error:")
            || responseCode === 401);
        var uuid = this.uuid();
        errMsg = errMsg.replace("{FAULT_NUMBER}", uuid);
        if (!isServiceNotAvailable) {
            this.error = uuid + " " + errMsg;
            this.errorActive = true;
            var description = "url:" + window.location.href + ", id:" + uuid + ", type:" + errType + ", msg:" + errMsg;
            if (this.platform.is("cordova") && !unauthorized) {
                this.firebaseX.logError(description); // crashlytics
            }
        }
        else {
            this.tooltipPopupActive = true;
            this.textOfTooltipPopup = errMsg;
            this.titleOfTooltipPopup = errType;
        }
        if (unauthorized) {
            this.router.navigateByUrl("/auth/logout");
        }
        else if (throwException) {
            throw new Error(throwErrorMsg || errType + " - " + errMsg);
        }
    };
    AppService.prototype.hideError = function () {
        this.error = "";
        this.errorActive = false;
    };
    AppService.prototype.getErrorMessage = function (error) {
        if (error instanceof Error) {
            return error.message;
        }
        else if (typeof error === 'object') {
            return JSON.stringify(error);
        }
        else {
            return String(error);
        }
    };
    AppService.prototype.initDownloadPopup = function () {
        var _this = this;
        if (!localStorage.getItem("lastPopup")) {
            localStorage.setItem("lastPopup", "0");
        }
        setInterval(function () {
            var lastPopup = parseInt(localStorage.getItem("lastPopup"));
            var now = (new Date()).getTime();
            if (now - lastPopup > 1000 * 60 * 60 * 24 * 5) { // once per 5 days            
                _this.downloadPopupActive = true;
                localStorage.setItem("lastPopup", now.toString());
            }
        }, 5000);
    };
    AppService.prototype.initPermissionPopup = function () {
        var _this = this;
        if (!localStorage.getItem("lastPopup1")) {
            localStorage.setItem("lastPopup1", "0");
        }
        setInterval(function () {
            var lastPopup = parseInt(localStorage.getItem("lastPopup1"));
            var now = (new Date()).getTime();
            if (now - lastPopup > 1000 * 60 * 60 * 24 * 5 && _this.platform.is("cordova")) { // once per 5 days
                _this.permissionPopupActive = true;
                localStorage.setItem("lastPopup1", now.toString());
            }
        }, 2000);
    };
    AppService.prototype.initVersionUpdatePopup = function () {
        var _this = this;
        setInterval(function () {
            if (!localStorage.getItem("versionUpdatePopup")) {
                localStorage.setItem("versionUpdatePopup", "0");
            }
            var lastPopup = parseInt(localStorage.getItem("versionUpdatePopup"));
            var now = (new Date()).getTime();
            if (now - lastPopup > 1000 * 60 * 60 * 24 && _this.platform.is("cordova")) { // once per 5 days
                _this.versionUpdatePopupActive = true;
                localStorage.setItem("versionUpdatePopup", now.toString());
            }
        }, 2000);
    };
    AppService.prototype.initIntroPopup = function () {
        if (!localStorage.getItem("notfirstrun")) {
            localStorage.setItem("notfirstrun", "1");
            this.introPopupActive = true;
        }
    };
    AppService.prototype.uuid = function () {
        return Array
            .from(Array(32))
            .map(function (e, i) {
            var someRandomValue = i === 12 ? 4 : (+new Date() + Math.random() * 16) % 16 | 0;
            return "" + (~[8, 12, 16, 20].indexOf(i) ? "-" : "") + (i === 16 ? someRandomValue & 0x3 | 0x8 : someRandomValue).toString(16);
        })
            .join("");
    };
    AppService.prototype.leaddatetime2date = function (ldt) {
        if (Array.isArray(ldt)) {
            var year_1 = ldt[0].year.toString();
            var month_1 = this.twoDigits(ldt[0].month);
            var day_1 = this.twoDigits(ldt[0].day);
            var hour_1 = this.twoDigits(ldt[0].hour);
            var minute_1 = this.twoDigits(ldt[0].minute);
            return new Date(year_1 + "/" + month_1 + "/" + day_1 + " " + hour_1 + ":" + minute_1 + ":00");
        }
        var year = ldt.year.toString();
        var month = this.twoDigits(ldt.month);
        var day = this.twoDigits(ldt.day);
        var hour = this.twoDigits(ldt.hour);
        var minute = this.twoDigits(ldt.minute);
        return new Date(year + "/" + month + "/" + day + " " + hour + ":" + minute + ":00");
    };
    // raw Date to dd/mm/yyyy
    AppService.prototype.fulldate2date = function (date) {
        return date.getDate() + "/" + this.twoDigits(date.getMonth() + 1) + "/" + date.getFullYear();
    };
    AppService.prototype.SetCalendarEvent = function (leadCLient, schema, subject) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var eventName, eventNotes, eventTitle, endDate, eventTitle, existingEvents, _i, existingEvents_1, ev;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        eventName = this.buildEventName(leadCLient, schema);
                        eventNotes = "lead id: " + leadCLient.id;
                        eventTitle = subject == null ? eventName : subject;
                        return [4 /*yield*/, this.getCalendarPermissions()];
                    case 1:
                        if (!_a.sent()) return [3 /*break*/, 3];
                        endDate = new Date(leadCLient.rawFollowup);
                        endDate.setHours(leadCLient.rawFollowup.getHours() + 1);
                        eventTitle = subject == null ? eventName : subject;
                        return [4 /*yield*/, this.calendar.findEvent(eventTitle, "", eventNotes, leadCLient.rawFollowup, endDate)];
                    case 2:
                        existingEvents = _a.sent();
                        if (existingEvents.length) {
                            for (_i = 0, existingEvents_1 = existingEvents; _i < existingEvents_1.length; _i++) {
                                ev = existingEvents_1[_i];
                                this.calendar.deleteEventById(ev.id);
                            }
                        }
                        this.calendar.createEvent(eventTitle, "", eventNotes, leadCLient.rawFollowup, endDate);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AppService.prototype.ModifyCalendarEvent = function (leadClient, schema, startDate, subject) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var eventName, eventNotes, eventTitle, endDate;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        eventName = this.buildEventName(leadClient, schema);
                        eventNotes = "lead id: " + leadClient.id;
                        eventTitle = subject == null ? eventName : subject;
                        return [4 /*yield*/, this.getCalendarPermissions()];
                    case 1:
                        if (_a.sent()) {
                            endDate = new Date(startDate);
                            endDate.setHours(startDate.getHours() + 1);
                            this.calendar.modifyEvent(eventTitle, "", eventNotes, leadClient.rawFollowup, endDate, eventTitle, "", eventNotes, startDate, endDate);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AppService.prototype.DeleteCalendarEvent = function (leadClient, schema, startDate, subject) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var eventName, eventNotes, eventTitle, endDate;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        eventName = this.buildEventName(leadClient, schema);
                        eventNotes = "lead id: " + leadClient.id;
                        eventTitle = subject == null ? eventName : subject;
                        return [4 /*yield*/, this.getCalendarPermissions()];
                    case 1:
                        if (_a.sent()) {
                            startDate.setHours(startDate.getHours());
                            endDate = new Date(startDate);
                            endDate.setHours(startDate.getHours() + 1);
                            this.calendar.deleteEvent(eventTitle, "", eventNotes, startDate, endDate);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AppService.prototype.getCalendarPermissions = function () {
        var _this = this;
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.calendar.hasReadWritePermission()];
                    case 1:
                        if (!_a.sent()) return [3 /*break*/, 2];
                        resolve(true);
                        return [3 /*break*/, 5];
                    case 2: return [4 /*yield*/, this.calendar.requestReadWritePermission()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.calendar.hasReadWritePermission()];
                    case 4:
                        if (_a.sent()) {
                            resolve(true);
                        }
                        else {
                            resolve(false);
                        }
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        }); });
    };
    AppService.prototype.buildEventName = function (leadCLient, schema) {
        return schema
            .replace("{fullname}", leadCLient.fullName)
            .replace("{phone}", leadCLient.phone)
            .replace("{email}", leadCLient.email)
            .replace("{createdate}", leadCLient.formatCreatedate)
            .replace("{followup}", leadCLient.formatCreatedate)
            .replace(/\n/g, " ")
            .replace(/\r/g, " ");
    };
    AppService.prototype.formatText = function (s, rebuildSpaces) {
        if (rebuildSpaces === void 0) { rebuildSpaces = true; }
        var res = s.trim().replace(/\n/g, "<br>");
        if (rebuildSpaces) {
            res = res.replace(/ /g, "&nbsp;");
        }
        return res;
    };
    AppService.prototype.rebuildGroups = function (leadClients) {
        var _this = this;
        this.leadClientsGroup = [];
        leadClients.forEach(function (l) {
            var date = _this.fulldate2date(new Date(l[_this.sorting]));
            var existingLg = _this.leadClientsGroup.find(function (lg) { return lg.date === date; }) || null;
            if (l.preview) {
                if (existingLg) {
                    var existingLeadClient = existingLg.leadClients.find(function (x) { return x.id == l.id; });
                    if (!existingLeadClient) {
                        existingLg.leadClients.push(l);
                    }
                }
                else {
                    var newLg = new LeadClientsGroup(date, [l]);
                    _this.leadClientsGroup.push(newLg);
                }
            }
        });
    };
    AppService.prototype.buildGroups = function (leadClients) {
        var _this = this;
        leadClients.filter(function (l) { return l[_this.sorting] !== null; }).forEach(function (l) {
            var date = _this.fulldate2date(new Date(l[_this.sorting]));
            var existingLg = _this.leadClientsGroup.find(function (lg) { return lg.date === date; }) || null;
            if (l.preview) {
                if (existingLg) {
                    var existingLeadClient = existingLg.leadClients.find(function (x) { return x.id == l.id; });
                    if (!existingLeadClient) {
                        existingLg.leadClients.push(l);
                    }
                }
                else {
                    var newLg = new LeadClientsGroup(date, [l]);
                    _this.leadClientsGroup.push(newLg);
                }
            }
        });
    };
    AppService.prototype.logToSentry = function (message, params) {
        var newError = new Error(message);
        console.error("TSTERR logToSentry", newError, params ? JSON.stringify(params) : "");
        Sentry.withScope(function (scope) {
            scope.setExtra("errorExtraData", newError);
            scope.setExtra("handlerSource", "app-service");
            if (params) {
                for (var key in params) {
                    scope.setExtra(key, params[key]);
                }
            }
            var eventId = Sentry.captureException(newError);
        });
    };
    // check if execution time worse than needed and log it
    AppService.prototype.logTimeToSentry = function (desc, startTime, endTime) {
        var timeDiff = endTime - startTime;
        if (timeDiff > 5000) {
            var message = "Request takes long time | " + desc;
            this.logToSentry(message, { time: Math.round(timeDiff / 100) / 10 + " seconds" });
        }
    };
    return AppService;
}());
export { AppService };
