import * as tslib_1 from "tslib";
import { NgZone } from "@angular/core";
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Platform, NavController } from '@ionic/angular';
import { AppService } from './app.service';
import { LogService } from "./log.service";
var NotificationService = /** @class */ (function () {
    function NotificationService(firebaseX, platform, appService, navCtrl, ngZone, logService) {
        this.firebaseX = firebaseX;
        this.platform = platform;
        this.appService = appService;
        this.navCtrl = navCtrl;
        this.ngZone = ngZone;
        this.logService = logService;
        this.leadId = null;
        this.active = false;
        this.followUpAlert = false;
        this.whatsAppAlert = false;
        this.notificationTitle = "";
        this.link = "";
        this.message = "";
    }
    Object.defineProperty(NotificationService.prototype, "canWork", {
        get: function () { return this.platform.is("cordova"); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NotificationService.prototype, "isAndroid", {
        get: function () { return this.platform.is("android"); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NotificationService.prototype, "isIOS", {
        get: function () { return this.platform.is("ios"); },
        enumerable: true,
        configurable: true
    });
    NotificationService.prototype.subscribe = function (userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.platform.ready()];
                    case 1:
                        _a.sent();
                        if (!this.canWork) return [3 /*break*/, 6];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        this.logService.add("canWork. userId: " + userId);
                        return [4 /*yield*/, this.firebaseX.subscribe("fix-" + userId).then(function (res) { })];
                    case 3:
                        _a.sent();
                        this.logService.add("check hasPermission");
                        this.firebaseX.hasPermission().then(function (hasPerm) {
                            _this.logService.add("hasPerm: " + hasPerm);
                            if (!hasPerm) {
                                _this.firebaseX.grantPermission().then(function (permissionGranted) { });
                                _this.logService.add("grantPermission");
                            }
                        });
                        if (this.isAndroid) {
                            this.logService.add("isAndroid");
                            this.firebaseX.onTokenRefresh().subscribe(function (token) {
                                _this.logService.add("onTokenRefresh success: " + token);
                            });
                            this.logService.add("onTokenRefresh");
                            this.firebaseX.getToken().then(function (token) {
                                _this.logService.add("getToken success: " + token);
                            });
                            this.logService.add("getToken");
                        }
                        else if (this.isIOS) {
                            this.logService.add("isIOS");
                            // TEMP block (as in Android)
                            this.firebaseX.onTokenRefresh().subscribe(function (token) {
                                _this.logService.add("onTokenRefresh success: " + token);
                            });
                            this.logService.add("onTokenRefresh");
                            this.firebaseX.getToken().then(function (token) {
                                _this.logService.add("getToken success: " + token);
                            });
                            this.logService.add("getToken");
                            this.firebaseX.onApnsTokenReceived().subscribe(function (token) {
                                _this.logService.add("onApnsTokenReceived success: " + token);
                            });
                            this.logService.add("onApnsTokenReceived");
                            this.firebaseX.getAPNSToken().then(function (token) {
                                _this.logService.add("getAPNSToken success: " + token);
                            });
                            this.logService.add("getAPNSToken");
                        }
                        this.logService.add("before onMessageReceived");
                        this.firebaseX.onMessageReceived().subscribe(function (msg) {
                            _this.logService.add("onMessageReceived: " + JSON.stringify(msg));
                            if ((msg.messageType === 'notification' || msg.messageType == 'data') && msg.leadClientId > 0) {
                                _this.logService.add("notification leadClientId");
                                _this.leadId = msg.leadClientId;
                                setTimeout(function () {
                                    _this.appService.leadClientsGroup.forEach(function (x) {
                                        var lc = x.leadClients.find(function (c) { return c.id == msg.leadClientId; });
                                        if (lc) {
                                            if (!lc.countOfWhatsAppMessages) {
                                                lc.countOfWhatsAppMessages = 0;
                                            }
                                            lc.countOfWhatsAppMessages++;
                                        }
                                    });
                                }, 100);
                                if (msg.tap) {
                                    _this.ngZone.run(function () {
                                        _this.navCtrl.navigateForward("/leadClients/" + msg.leadClientId + "/whatsapp");
                                    });
                                }
                                else {
                                    _this.ngZone.run(function () {
                                        _this.notificationTitle = msg.notification.title;
                                        _this.message = msg.notification.body;
                                        _this.whatsAppAlert = true;
                                    });
                                }
                            }
                            else if ((msg.messageType === "notification" || msg.messageType == 'data') && msg.leadId > 0) {
                                _this.logService.add("notification leadId");
                                _this.leadId = msg.leadId;
                                if (msg.tap) {
                                    _this.ngZone.run(function () {
                                        // if (this.isAndroid) {
                                        //     (<any>window).plugins.bringtofront();
                                        // }
                                        _this.navCtrl.navigateForward("/leadClients/" + msg.leadId);
                                    });
                                }
                                else {
                                    _this.ngZone.run(function () {
                                        _this.active = true;
                                    });
                                }
                            }
                            else if ((msg.messageType === 'notification' || msg.messageType == 'data') && msg.followUpLeadId > 0) {
                                _this.logService.add("notification followUpLeadId");
                                _this.leadId = msg.followUpLeadId;
                                if (msg.tap || msg.tap == "background") {
                                    _this.ngZone.run(function () {
                                        // if (this.isAndroid) {
                                        //     (<any>window).plugins.bringtofront();
                                        // }
                                        _this.navCtrl.navigateForward("/leadClients/" + msg.followUpLeadId);
                                    });
                                }
                                else {
                                    _this.ngZone.run(function () {
                                        _this.followUpAlert = true;
                                    });
                                }
                            }
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.logService.add("Firebase subscription error. " + JSON.stringify(err_1));
                        this.appService.showError("Firebase subscription error", err_1);
                        return [3 /*break*/, 5];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        this.logService.add("NO canWork. userId: " + userId);
                        _a.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    NotificationService.prototype.unsubscribe = function (userId) {
        if (this.canWork) {
            this.firebaseX.unsubscribe("fix-" + userId);
        }
    };
    return NotificationService;
}());
export { NotificationService };
