var LeadClientsRepository = /** @class */ (function () {
    function LeadClientsRepository(dataService, langsRepository) {
        this.dataService = dataService;
        this.langsRepository = langsRepository;
        this.leadCLients = [];
    }
    Object.defineProperty(LeadClientsRepository.prototype, "locale", {
        get: function () { return this.langsRepository.current.locale; },
        enumerable: true,
        configurable: true
    });
    LeadClientsRepository.prototype.sortLeadClients = function (sorting, dir) {
        var realSorting = null;
        // we must sort only if sorting setted to followUp or lastUpdate, in other cases date will not change and sorting is not needed!
        if (sorting === "followUp") {
            realSorting = "rawFollowup";
        }
        if (realSorting) {
            this.leadCLients = this.leadCLients.sort(function (a, b) { return (dir)
                ? b[realSorting] - a[realSorting]
                : a[realSorting] - b[realSorting]; });
        }
    };
    LeadClientsRepository.prototype.reset = function () {
        this.leadCLients = [];
    };
    return LeadClientsRepository;
}());
export { LeadClientsRepository };
