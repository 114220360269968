import { EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { LeadClient } from '../../model/leadClients/lead.client.model';
import { AppService } from '../../services/app.service';
import { StatusType } from "../../Enums/status.type.enum";
var LeadStatusesWinComponent = /** @class */ (function () {
    function LeadStatusesWinComponent(appService) {
        this.appService = appService;
        this.activeChange = new EventEmitter();
        this.leadClientParamsChange = new EventEmitter();
        this.leadClientFollowupChange = new EventEmitter();
        this.step = 1;
        this.substatuses = [];
        this.amount = 0;
        this.amountRequiredError = "";
        // calendar
        this.calendarOptions = {
            inline: true,
            showTodayBtn: false,
            selectorWidth: "100%",
        };
        this.currentDate = {};
        this.currentHour = 0;
        this.currentMinute = 0;
        this.calendarReady = false;
        this.hours = [];
        this.minutes = [];
    }
    LeadStatusesWinComponent.prototype.ngOnInit = function () {
        this.hours = this.appService.range(0, 23);
        this.minutes = this.appService.range(0, 59);
        this.calendarOptions.dayLabels = this.currentLang.slug === 'he'
            ? { su: 'א', mo: 'ב', tu: 'ג', we: 'ד', th: 'ה', fr: 'ו', sa: 'ש' }
            : { su: 'Sun', mo: 'Mon', tu: 'Tue', we: 'Wed', th: 'Thu', fr: 'Fri', sa: 'Sat' };
        this.calendarOptions.monthLabels = this.currentLang.slug === 'he'
            ? { 1: 'ינואר', 2: 'פברואר', 3: 'מרץ', 4: 'אפריל', 5: 'מאי', 6: 'יוני', 7: 'יולי', 8: 'אוגוסט', 9: 'ספטמבר', 10: 'אוקטובר', 11: 'נובמבר', 12: 'דצמבר' }
            : { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' };
    };
    LeadStatusesWinComponent.prototype.ngOnChanges = function (changes) {
        this.step = 1;
        this.substatuses = [];
        if (this.leadClient) {
            this.currentDate = this.leadClient.calendarFollowupDate;
            this.currentHour = this.leadClient.followupHour;
            this.currentMinute = this.leadClient.followupMinute;
            this.calendarReady = true;
        }
        else {
            this.calendarReady = false;
        }
    };
    Object.defineProperty(LeadStatusesWinComponent.prototype, "title", {
        get: function () {
            switch (this.step) {
                case 1:
                    return this.currentLang.words["stage"];
                case 2:
                    return this.currentLang.words["status"];
                case 3:
                    return this.currentLang.words["set-amount"];
                case 4:
                    return this.currentLang.words["save-meeting"];
                default:
                    return "";
            }
        },
        enumerable: true,
        configurable: true
    });
    LeadStatusesWinComponent.prototype.close = function () {
        this.active = false;
        this.activeChange.emit(false);
    };
    LeadStatusesWinComponent.prototype.apply = function () {
        this.amountRequiredError = "";
        if (this.step === 3 && this.amount <= 0) {
            this.amountRequiredError = this.currentLang.words["amount-required"];
            return;
        }
        this.active = false;
        this.activeChange.emit(false);
    };
    LeadStatusesWinComponent.prototype.setLeadStatus = function (statusId) {
        var _this = this;
        if (this.leadClient.leadStatusId !== statusId) {
            this.leadClient.leadStatusId = statusId;
            this.leadClient.additionalStatusId = null;
            this.leadClientParamsChange.emit(true);
        }
        var currentStatus = this.statuses.find(function (s) { return s.id === statusId; }) || null;
        // next step if needed
        if (currentStatus && currentStatus.subStatuses.filter(function (x) { return x.isSelected || !_this.leadClient.processId; }).length) {
            this.substatuses = currentStatus.subStatuses.filter(function (x) { return x.isSelected || !_this.leadClient.processId; });
            this.step = 2;
        }
        else if (this.leadClient.leadStatusId === StatusType.Closed) {
            this.step = 3;
        }
        else if (this.leadClient.leadStatusId === StatusType.Meeting) {
            this.step = 4;
            this.currentDate = { date: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() } };
            this.currentHour = new Date().getHours();
            this.currentMinute = new Date().getMinutes();
        }
    };
    LeadStatusesWinComponent.prototype.setLeadSubstatus = function (substatusId) {
        if (this.leadClient.additionalStatusId !== substatusId) {
            this.leadClient.additionalStatusId = substatusId;
            this.leadClientParamsChange.emit(true);
        }
        // next step if needed
        if (this.leadClient.leadStatusId === StatusType.Closed) {
            this.step = 3;
        }
        else if (this.leadClient.leadStatusId === StatusType.Meeting) {
            this.step = 4;
            this.currentDate = { date: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() } };
            this.currentHour = new Date().getHours();
            this.currentMinute = new Date().getMinutes();
        }
    };
    LeadStatusesWinComponent.prototype.onLeadAmountChanged = function () {
        this.leadClient.amount = this.amount;
        this.leadClientParamsChange.emit(true);
    };
    // now + N days
    LeadStatusesWinComponent.prototype.calendarForward = function (nDays) {
        var now = new Date();
        var timestamp = now.getTime() + 1000 * 60 * 60 * 24 * nDays;
        var newDate = new Date(timestamp);
        this.currentDate = { date: { year: newDate.getFullYear(), month: newDate.getMonth() + 1, day: newDate.getDate() } };
        this.currentHour = newDate.getHours();
        this.currentMinute = newDate.getMinutes();
    };
    LeadStatusesWinComponent.prototype.calendarApply = function () {
        var leaddatetime = {
            year: this.currentDate.date.year,
            month: this.currentDate.date.month,
            day: this.currentDate.date.day,
            hour: this.currentHour,
            minute: this.currentMinute
        };
        var newDate = this.appService.leaddatetime2date(leaddatetime);
        var serverDate = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000);
        this.leadClient.rawFollowup = newDate;
        this.leadClient.followUp = serverDate.toISOString();
        this.leadClientParamsChange.emit(true);
        this.leadClientFollowupChange.emit(true);
        this.close();
    };
    return LeadStatusesWinComponent;
}());
export { LeadStatusesWinComponent };
