import { LangsRepository } from '../../services/langs.repository';
var Page404 = /** @class */ (function () {
    function Page404(langsRepository) {
        var _this = this;
        this.langsRepository = langsRepository;
        this.langsReady = false;
        this.langsRepository.ready.subscribe(function (r) {
            if (r) {
                _this.langsReady = true;
            }
        });
    }
    Object.defineProperty(Page404.prototype, "currentLang", {
        get: function () { return this.langsRepository.current; },
        enumerable: true,
        configurable: true
    });
    return Page404;
}());
export { Page404 };
