import { OnInit } from '@angular/core';
import { LangsRepository } from '../../services/langs.repository';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { AppService } from '../../services/app.service';
import { Location } from '@angular/common';
var PaymentPage = /** @class */ (function () {
    function PaymentPage(langsRepository, navCtrl, route, authService, appService, location) {
        this.langsRepository = langsRepository;
        this.navCtrl = navCtrl;
        this.route = route;
        this.authService = authService;
        this.appService = appService;
        this.location = location;
        this.token = "";
        this.langsReady = false;
        this.step = 1;
        this.busy = false;
        this.step1Status = "&nbsp;";
        this.step1Error = false;
        this.step2Status = "&nbsp;";
        this.step2Error = false;
        this.phone = "";
        this.email = "";
        this.login = "";
        this.emailError = "&nbsp;";
        this.phoneError = "&nbsp;";
        this.passcodeError = "&nbsp";
        this.passcode = "";
        this.iframeSrc = "";
        this.showSendSms = false;
        this._httpError = "HTTP error";
    }
    PaymentPage.prototype.ngOnInit = function () {
        var _this = this;
        this.langsRepository.ready.subscribe(function (r) {
            _this.langsReady = r;
        });
        this.route.params.subscribe(function (p) {
            _this.token = p["token"];
            _this.step = 1;
            _this.step1Status = "&nbsp;";
            _this.step1Error = false;
            _this.step2Status = "&nbsp;";
            _this.step2Error = false;
            _this.emailError = "&nbsp;";
            _this.phoneError = "&nbsp;";
            _this.passcodeError = "&nbsp;";
            _this.passcode = "";
            _this.iframeSrc = "";
            _this.showSendSms = false;
            _this.busy = true;
            _this.authService.getPaymentIframe(_this.token, _this.login).subscribe(function (res) {
                if (res.responseCode === 200) {
                    _this.step1Status = "&nbsp;";
                    _this.step1Error = false;
                    _this.iframeSrc = res.data.iframeSrc;
                    _this.showSendSms = res.data.showSendSms;
                    _this.step = 2;
                }
                else {
                    _this.step1Status = res.error;
                    _this.step1Error = true;
                }
                _this.busy = false;
            }, function (err) {
                var message = _this.appService.getErrorMessage(err);
                _this.appService.showError(_this._httpError, _this.currentLang.words["http_error"] + " in payment-page init getPaymentIframe: " + message, true);
            });
        });
    };
    Object.defineProperty(PaymentPage.prototype, "currentLang", {
        get: function () { return this.langsRepository.current; },
        enumerable: true,
        configurable: true
    });
    PaymentPage.prototype.back = function () {
        this.location.back();
    };
    PaymentPage.prototype.toStep1 = function () {
        this.step = 1;
        this.step1Status = "&nbsp;";
        this.step1Error = false;
        this.step2Status = "&nbsp;";
        this.step2Error = false;
        this.emailError = "&nbsp;";
        this.phoneError = "&nbsp;";
        this.passcodeError = "&nbsp;";
        this.passcode = "";
        this.iframeSrc = "";
        this.showSendSms = false;
    };
    PaymentPage.prototype.toStep2 = function () {
        var _this = this;
        this.email = this.email.trim();
        if (this.validateStep1()) {
            this.busy = true;
            this.authService.getPaymentIframe(this.token, this.login).subscribe(function (res) {
                if (res.responseCode === 200) {
                    _this.step1Status = "&nbsp;";
                    _this.step1Error = false;
                    _this.iframeSrc = res.data.iframeSrc;
                    _this.showSendSms = res.data.showSendSms;
                    _this.step = 2;
                }
                else {
                    _this.step1Status = res.error;
                    _this.step1Error = true;
                }
                _this.busy = false;
            }, function (err) {
                var message = _this.appService.getErrorMessage(err);
                _this.appService.showError(_this._httpError, _this.currentLang.words["http_error"] + " in payment-page toStep2 getPaymentIframe: " + message, true);
            });
        }
    };
    PaymentPage.prototype.validatePhone = function () {
        this.phone = this.phone.replace(/[^0-9]+/g, "");
    };
    PaymentPage.prototype.validateEmail = function () {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(this.email).toLowerCase());
    };
    PaymentPage.prototype.validateStep1 = function () {
        var error = false;
        if (!this.email.length) {
            error = true;
            this.emailError = this.currentLang.words["enter-email"];
        }
        else if (!this.validateEmail()) {
            error = true;
            this.emailError = this.currentLang.words["correct-email"];
        }
        else {
            this.emailError = "&nbsp;";
        }
        if (!this.phone.length) {
            error = true;
            this.phoneError = this.currentLang.words["enter-phone"];
        }
        else {
            this.phoneError = "&nbsp;";
        }
        return !error;
    };
    PaymentPage.prototype.validateStep2 = function () {
        var error = false;
        if (!this.passcode.length) {
            error = true;
            this.passcodeError = this.currentLang.words["enter-passcode"];
        }
        else {
            this.passcodeError = "&nbsp;";
        }
        return !error;
    };
    PaymentPage.prototype.finish = function () {
        var _this = this;
        this.passcode = this.passcode.trim();
        if (this.validateStep2()) {
            this.busy = true;
            this.authService.addUserBySms(this.token, this.passcode, this.login).subscribe(function (res) {
                if (res.body.responseCode === 200) {
                    var token = res.headers.get("X-CLIENT-TOKEN");
                    var user = res.body.data;
                    if (token && user) {
                        _this.step2Status = "&nbsp;";
                        _this.step2Error = false;
                        _this.authService.saveAuthData({ token: token, user: user });
                        _this.navCtrl.navigateForward("/leads");
                    }
                    else {
                        _this.step2Status = _this.currentLang.words["token-not-found"];
                        _this.step2Error = true;
                    }
                }
                else {
                    _this.step2Status = res.body.error;
                    _this.step2Error = true;
                }
                _this.busy = false;
            }, function (err) {
                var message = _this.appService.getErrorMessage(err);
                _this.appService.showError(_this._httpError, _this.currentLang.words["http_error"] + " in payment-page finish addUserBySms: " + message, true);
            });
        }
    };
    return PaymentPage;
}());
export { PaymentPage };
