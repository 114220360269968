import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
var WhatsAppDataService = /** @class */ (function () {
    function WhatsAppDataService(http) {
        this.http = http;
        this.tokenKey = "X-WHATSAPP-TOKEN";
        this.locale = "";
        this.token = new BehaviorSubject("");
        this.baseUrl = environment.baseUrlV1;
        this.leadClientBaseUrl = environment.baseUrlV2;
        this.locale = localStorage.getItem("currentLang") == "en" ? "en-US" : "he-IL";
    }
    WhatsAppDataService.prototype.sendRequest = function (verb, url, body, options) {
        if (options === void 0) { options = {}; }
        if (verb === "POST") {
            return this.http.post(this.baseUrl + url, body, { headers: this.headers });
        }
        else if (verb === "PATCH") {
            return this.http.patch(this.baseUrl + url, body, { headers: this.headers });
        }
        else if (verb === "PUT") {
            return this.http.put(this.baseUrl + url, body, { headers: this.headers });
        }
        else if (verb === "DELETE") {
            return this.http.delete(this.baseUrl + url, { headers: this.headers });
        }
        else { // GET
            return this.http.get(this.baseUrl + url, { headers: this.headers });
        }
    };
    WhatsAppDataService.prototype.sendRequest2 = function (verb, url, body, options) {
        if (options === void 0) { options = {}; }
        if (verb === "POST") {
            return this.http.post(environment.baseUrlV2 + url, body, { headers: this.headers });
        }
        else if (verb === "PATCH") {
            return this.http.patch(environment.baseUrlV2 + url, body, { headers: this.headers });
        }
        else if (verb === "PUT") {
            return this.http.put(environment.baseUrlV2 + url, body, { headers: this.headers });
        }
        else if (verb === "DELETE") {
            return this.http.delete(environment.baseUrlV2 + url, { headers: this.headers });
        }
        else { // GET
            return this.http.get(environment.baseUrlV2 + url, { headers: this.headers });
        }
    };
    Object.defineProperty(WhatsAppDataService.prototype, "headers", {
        // v2
        get: function () { return new HttpHeaders({ "X-WHATSAPP-TOKEN": this.token.value, "X-LOCALE": this.locale }); },
        enumerable: true,
        configurable: true
    });
    ;
    WhatsAppDataService.prototype.getUserByToken = function () {
        return this.sendRequest2("GET", "users/getByWhatsAppToken", null, null);
    };
    WhatsAppDataService.prototype.getLeadClient = function (phone) {
        return this.sendRequest2("GET", "leadClient/getByPhone?phone=" + phone, null, null);
    };
    WhatsAppDataService.prototype.getLeadClientByEmail = function (email) {
        return this.sendRequest2("GET", "leadClient/getByEmail?email=" + email, null, null);
    };
    WhatsAppDataService.prototype.getAssignees = function () {
        return this.sendRequest("GET", "assignees/getAssignees", null, { headers: this.headers });
    };
    WhatsAppDataService.prototype.getProcesses = function () {
        return this.sendRequest("GET", "processes/GetProcessesStatusesSubStatuses", null, null);
    };
    WhatsAppDataService.prototype.getLeads = function (leadClientId) {
        return this.sendRequest2("GET", "leadClient/leads?id=" + leadClientId, null, null);
    };
    WhatsAppDataService.prototype.getDeals = function (leadClientId) {
        return this.sendRequest2("GET", "leadClient/deals?id=" + leadClientId, null, null);
    };
    WhatsAppDataService.prototype.getMeetings = function (leadClientId) {
        return this.sendRequest2("GET", "leadClient/meetings?id=" + leadClientId, null, null);
    };
    WhatsAppDataService.prototype.getNotes = function (id) {
        return this.sendRequest2("GET", "leadClient/getNotes?id=" + id, null, null);
    };
    WhatsAppDataService.prototype.addLeadClientNote = function (leadClientId, note, assigneeId, gpsLongitude, gpsLatitude, createdDate) {
        return this.sendRequest2("POST", "leadClient/createNote", { leadClientId: leadClientId, note: note, assigneeId: assigneeId, gpsLongitude: gpsLongitude, gpsLatitude: gpsLatitude, createdDate: createdDate }, null);
    };
    WhatsAppDataService.prototype.updateLeadClientFollowUp = function (id, followUp) {
        return this.sendRequest2("PATCH", "leadClient/setFollowup", { Id: id, followUp: followUp }, null);
    };
    WhatsAppDataService.prototype.updateLeadClientStatus = function (leadClient) {
        return this.sendRequest2("PATCH", "leadClient/setStatus", leadClient, null);
    };
    WhatsAppDataService.prototype.updateLeadClient = function (leadClient) {
        return this.sendRequest2("PUT", "leadClient/update", leadClient, null);
    };
    WhatsAppDataService.prototype.addLeadClient = function (leadClient) {
        return this.sendRequest2("POST", "leadClient/create", { fullname: leadClient.fullName, email: leadClient.email, phone: leadClient.phone, assigneeId: leadClient.assigneeId, leadStatusId: leadClient.leadStatusId, AdditionalStatusId: leadClient.additionalStatusId, source: leadClient.source, address: leadClient.address }, null);
    };
    WhatsAppDataService.prototype.updateLeadClientDeal = function (dealId, leadClientId, subject, amount, assigneeId, dealDate) {
        return this.sendRequest2("PATCH", "leadClient/setDeals", { dealId: dealId, leadClientId: leadClientId, subject: subject, amount: amount, assigneeId: assigneeId, dealDate: dealDate, externalCrmId: 0 }, null);
    };
    WhatsAppDataService.prototype.UpdateDeal = function (id, leadClientId, subject, amount, assigneeId) {
        return this.sendRequest2("PATCH", "leadClient/updateDeal", { id: id, leadClientId: leadClientId, subject: subject, amount: amount, assigneeId: assigneeId }, null);
    };
    WhatsAppDataService.prototype.getDealById = function (id) {
        return this.sendRequest2("GET", "leadClient/deal/getById?id=" + id, null, null);
    };
    WhatsAppDataService.prototype.LeadClientCancelDeal = function (id) {
        return this.sendRequest2("GET", "leadClient/cancelDealById?id=" + id, null, null);
    };
    WhatsAppDataService.prototype.GetMeetingById = function (id) {
        return this.sendRequest2("GET", "leadClient/getMeetingById?id=" + id, null, null);
    };
    WhatsAppDataService.prototype.CancelMeetingById = function (id, locale) {
        return this.sendRequest2("GET", "leadClient/cancelMeetingById?id=" + id, null, null);
    };
    WhatsAppDataService.prototype.UpdateMeeting = function (id, leadClientId, meetingDate, meetingEndDate, subject, meetingAssigneeId, externalId, note, meetingStatus) {
        return this.sendRequest2("PATCH", "leadClient/updateMeeting", {
            id: id,
            leadClientId: leadClientId,
            meetingDate: meetingDate,
            meetingEndDate: meetingEndDate,
            subject: subject,
            meetingAssigneeId: meetingAssigneeId,
            externalId: externalId,
            note: note,
            meetingStatus: meetingStatus
        }, null);
    };
    WhatsAppDataService.prototype.AddMeeting = function (id, leadClientId, meetingDate, meetingEndDate, subject, meetingAssigneeId, externalId, note, meetingStatus) {
        return this.sendRequest2("PATCH", "leadClient/addMeeting", {
            id: id,
            leadClientId: leadClientId,
            meetingDate: meetingDate,
            meetingEndDate: meetingEndDate,
            subject: subject,
            meetingAssigneeId: meetingAssigneeId,
            externalId: externalId,
            note: note,
            meetingStatus: meetingStatus
        }, null);
    };
    WhatsAppDataService.prototype.getSubstatuses = function () {
        return this.sendRequest("GET", "substatuses/getSubStatuses", null, null);
    };
    WhatsAppDataService.prototype.addSubstatus = function (substatus) {
        return this.sendRequest("PUT", "substatuses/createSubStatus", substatus, null);
    };
    return WhatsAppDataService;
}());
export { WhatsAppDataService };
