import { LangsRepository } from '../../services/langs.repository';
var PrivacyPolicyPage = /** @class */ (function () {
    function PrivacyPolicyPage(langsRepository) {
        var _this = this;
        this.langsRepository = langsRepository;
        this.langsReady = false;
        this.langsRepository.ready.subscribe(function (r) {
            if (r) {
                _this.langsReady = true;
            }
        });
    }
    Object.defineProperty(PrivacyPolicyPage.prototype, "currentLang", {
        get: function () { return this.langsRepository.current; },
        enumerable: true,
        configurable: true
    });
    PrivacyPolicyPage.prototype.back = function () { window.history.back(); };
    return PrivacyPolicyPage;
}());
export { PrivacyPolicyPage };
