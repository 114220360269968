import { OnInit } from '@angular/core';
import { LangsRepository } from '../../services/langs.repository';
import { NavController, Platform } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { AppService } from '../../services/app.service';
import { Location } from '@angular/common';
var RegistrationPage = /** @class */ (function () {
    function RegistrationPage(langsRepository, navCtrl, route, authService, appService, location, platform) {
        this.langsRepository = langsRepository;
        this.navCtrl = navCtrl;
        this.route = route;
        this.authService = authService;
        this.appService = appService;
        this.location = location;
        this.platform = platform;
        this.token = "";
        this.langsReady = false;
        this.step = 1;
        this.busy = false;
        this.step1Status = "";
        this.step1Error = false;
        this.step2Status = "";
        this.step2Error = false;
        this.firstName = "";
        this.lastName = "";
        this.firstNameError = "";
        this.lastNameError = "";
        this.phone = "";
        this.email = "";
        this.emailError = "";
        this.phoneError = "";
        this.passcodeError = "";
        this.passcode = "";
        this.iframeSrc = "";
        this.showSendSms = false;
        this.createdUser = null;
        this.IsApp = false;
        this.IsRtl = this.langsRepository.current.dir === "rtl";
        this._httpError = "HTTP error";
        this.IsApp = this.platform.is("desktop");
        this.innerWidth = window.innerWidth;
        if (this.innerWidth > 1024) {
            this.IsApp = true;
        }
    }
    RegistrationPage.prototype.ngOnInit = function () {
        var _this = this;
        this.langsRepository.ready.subscribe(function (r) {
            _this.langsReady = r;
        });
        this.route.params.subscribe(function (p) {
            _this.token = p["token"];
            _this.step = 1;
            _this.step1Status = "";
            _this.step1Error = false;
            _this.step2Status = "";
            _this.step2Error = false;
            _this.emailError = "";
            _this.phoneError = "";
            _this.passcodeError = "";
            _this.passcode = "";
            _this.iframeSrc = "";
            _this.showSendSms = false;
        });
    };
    Object.defineProperty(RegistrationPage.prototype, "currentLang", {
        get: function () { return this.langsRepository.current; },
        enumerable: true,
        configurable: true
    });
    RegistrationPage.prototype.back = function () {
        (this.step === 1) ? this.location.back() : this.toStep1();
    };
    RegistrationPage.prototype.toStep1 = function () {
        this.step = 1;
        this.step1Status = "";
        this.step1Error = false;
        this.step2Status = "";
        this.step2Error = false;
        this.emailError = "";
        this.phoneError = "";
        this.firstNameError = "";
        this.lastNameError = "";
        this.passcodeError = "";
        this.passcode = "";
        this.iframeSrc = "";
        this.showSendSms = false;
    };
    RegistrationPage.prototype.toStep2 = function () {
        var _this = this;
        this.email = this.email.trim();
        this.firstName = this.firstName.trim();
        this.lastName = this.lastName.trim();
        if (this.validateStep1()) {
            this.busy = true;
            this.authService.registrationProcess(this.token, this.phone, this.email, this.firstName, this.lastName).subscribe(function (res) {
                if (res.body.responseCode === 200) {
                    _this.createdUser = res.body.data;
                    _this.step1Status = "";
                    _this.step1Error = false;
                    _this.showSendSms = true;
                    _this.step = 2;
                }
                else {
                    _this.step1Status = res.body.error;
                    _this.step1Error = true;
                }
                _this.busy = false;
            }, function (err) {
                var message = _this.appService.getErrorMessage(err);
                _this.appService.showError(_this._httpError, _this.currentLang.words["http_error"] + " in registration-page toStep2 registrationProcess: " + message, true);
                _this.busy = false;
            });
        }
    };
    RegistrationPage.prototype.validatePhone = function () {
        this.phone = this.phone.replace(/[^0-9]+/g, "");
    };
    RegistrationPage.prototype.validateEmail = function () {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(this.email).toLowerCase());
    };
    RegistrationPage.prototype.validateStep1 = function () {
        var error = false;
        if (!this.firstName.length) {
            error = true;
            this.firstNameError = this.currentLang.words['enter-firstName'];
        }
        else {
            this.firstNameError = "";
        }
        if (!this.lastName.length) {
            error = true;
            this.lastNameError = this.currentLang.words['enter-lastName'];
        }
        else {
            this.lastNameError = "";
        }
        if (!this.email.length) {
            error = true;
            this.emailError = this.currentLang.words["enter-email"];
        }
        else if (!this.validateEmail()) {
            error = true;
            this.emailError = this.currentLang.words["correct-email"];
        }
        else {
            this.emailError = "";
        }
        if (!this.phone.length) {
            error = true;
            this.phoneError = this.currentLang.words["enter-phone"];
        }
        else if (this.phone.length !== 10) {
            error = true;
            this.phoneError = this.currentLang.words["enter-phone-validation-10-digits"];
        }
        else if (!this.phone.startsWith('05')) {
            error = true;
            this.phoneError = this.currentLang.words["enter-phone-validation-start"];
        }
        else {
            this.phoneError = "";
        }
        return !error;
    };
    RegistrationPage.prototype.validateStep2 = function () {
        var error = false;
        if (!this.passcode.length) {
            error = true;
            this.passcodeError = this.currentLang.words["enter-passcode"];
        }
        else {
            this.passcodeError = "";
        }
        return !error;
    };
    RegistrationPage.prototype.resendSms = function () {
        var _this = this;
        this.passcode = this.passcode.trim();
        this.busy = true;
        if (this.createdUser) {
            this.authService.resendSmsForRegistration(this.token, this.createdUser.id).subscribe(function (res) {
                _this.busy = false;
                if (res.body.responseCode === 200) {
                    _this.appService.showNetworkstatus(_this.currentLang.words["resend-message"]);
                }
                else {
                    _this.step2Status = res.body.error;
                    _this.step2Error = true;
                }
            }, function (err) {
                var message = _this.appService.getErrorMessage(err);
                _this.appService.showError(_this._httpError, _this.currentLang.words["http_error"] + " in registration-page resendSms resendSmsForRegistration: " + message, true);
                _this.busy = false;
            });
        }
    };
    RegistrationPage.prototype.finish = function () {
        var _this = this;
        this.passcode = this.passcode.trim();
        if (this.validateStep2()) {
            this.busy = true;
            this.authService.registrationComplete(this.token, this.passcode, this.email, this.phone, this.firstName, this.lastName).subscribe(function (res) {
                if (res.body.responseCode === 200) {
                    var token = res.headers.get("X-CLIENT-TOKEN");
                    var user = res.body.data;
                    if (token && user) {
                        _this.step2Status = "";
                        _this.step2Error = false;
                        _this.authService.saveAuthData({ token: token, user: user });
                        _this.navCtrl.navigateForward("/leadClients");
                    }
                    else {
                        _this.step2Status = _this.currentLang.words["token-not-found"];
                        _this.step2Error = true;
                    }
                }
                else {
                    _this.step2Status = res.body.error;
                    _this.step2Error = true;
                }
                _this.busy = false;
            }, function (err) {
                var message = _this.appService.getErrorMessage(err);
                _this.appService.showError(_this._httpError, _this.currentLang.words["http_error"] + " in registration-page finish registrationComplete: " + message, true);
            });
        }
    };
    return RegistrationPage;
}());
export { RegistrationPage };
