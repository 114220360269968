import * as tslib_1 from "tslib";
import { Model } from './model';
var User = /** @class */ (function (_super) {
    tslib_1.__extends(User, _super);
    function User(canDeleteLeads, canEditAllLeads, canViewAllLeads, canViewEmail, canViewAssignee, canViewDealsAmount, canViewFullname, canViewOtherData, canViewPhone, canViewPrice, email, firstName, id, isAdmin, isDefaultAssignee, lastName, phone, emailError, phoneError, firstNameError, lastNameError, clientHasCRM, replaceUserId, sendPushNewLead, sendPushFollowUp, sendMeetingCreatedEmail, sendMeetingCanceledEmail, isPushNotifications, activeRestrictions) {
        var _this = _super.call(this) || this;
        _this.canDeleteLeads = canDeleteLeads;
        _this.canEditAllLeads = canEditAllLeads;
        _this.canViewAllLeads = canViewAllLeads;
        _this.canViewEmail = canViewEmail;
        _this.canViewAssignee = canViewAssignee;
        _this.canViewDealsAmount = canViewDealsAmount;
        _this.canViewFullname = canViewFullname;
        _this.canViewOtherData = canViewOtherData;
        _this.canViewPhone = canViewPhone;
        _this.canViewPrice = canViewPrice;
        _this.email = email;
        _this.firstName = firstName;
        _this.id = id;
        _this.isAdmin = isAdmin;
        _this.isDefaultAssignee = isDefaultAssignee;
        _this.lastName = lastName;
        _this.phone = phone;
        _this.emailError = emailError;
        _this.phoneError = phoneError;
        _this.firstNameError = firstNameError;
        _this.lastNameError = lastNameError;
        _this.clientHasCRM = clientHasCRM;
        _this.replaceUserId = replaceUserId;
        _this.sendPushNewLead = sendPushNewLead;
        _this.sendPushFollowUp = sendPushFollowUp;
        _this.sendMeetingCreatedEmail = sendMeetingCreatedEmail;
        _this.sendMeetingCanceledEmail = sendMeetingCanceledEmail;
        _this.isPushNotifications = isPushNotifications;
        _this.activeRestrictions = activeRestrictions;
        return _this;
    }
    User.prototype.init = function () {
        this.canDeleteLeads = true;
        this.canEditAllLeads = true;
        this.canViewAllLeads = true;
        this.canViewEmail = true;
        this.canViewAssignee = true;
        this.canViewDealsAmount = true;
        this.canViewFullname = true;
        this.canViewOtherData = true;
        this.canViewPhone = true;
        this.canViewPrice = true;
        this.email = "";
        this.firstName = "";
        this.isAdmin = false;
        this.isDefaultAssignee = false;
        this.lastName = "";
        this.phone = "";
        this.emailError = "";
        this.phoneError = "";
        this.firstNameError = "";
        this.lastNameError = "";
        this.clientHasCRM = false;
        this.replaceUserId = 0;
        this.sendPushNewLead = true;
        this.sendPushFollowUp = true;
        this.sendMeetingCreatedEmail = true;
        this.sendMeetingCanceledEmail = true;
        this.isPushNotifications = true;
        this.activeRestrictions = [];
        return this;
    };
    return User;
}(Model));
export { User };
