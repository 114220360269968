import { EventEmitter } from '@angular/core';
import { LangsRepository } from 'src/app/services/langs.repository';
import { LeadsRepositoryBUT } from 'src/app/services/leads.repository.but';
import { ProcessesRepositoryBUT } from 'src/app/services/processes.repository.but';
import { SubstatusesRepository } from 'src/app/services/substatuses.repository';
import { AppService } from '../../services/app.service';
var SubStatusEditOverlayComponent = /** @class */ (function () {
    function SubStatusEditOverlayComponent(langsRepository, substatusesRepository, processesRepository, leadsRespotory, appService) {
        this.langsRepository = langsRepository;
        this.substatusesRepository = substatusesRepository;
        this.processesRepository = processesRepository;
        this.leadsRespotory = leadsRespotory;
        this.appService = appService;
        this.ready = false;
        this.activeChange = new EventEmitter();
        this.cancel = new EventEmitter();
        this.globalStatus = 0;
        this.substatus = null;
        this.langsReady = false;
        this.substatusesReady = false;
        this.processesReady = false;
        this.ready = true;
        this.init();
    }
    SubStatusEditOverlayComponent.prototype.close = function () {
        this.active = false;
        this.activeChange.emit(false);
    };
    SubStatusEditOverlayComponent.prototype.init = function () {
        var _this = this;
        this.langsRepository.ready.subscribe(function (r) { if (r)
            _this.langsReady = true; });
        this.substatusesRepository.prepare().then(function () { _this.substatusesReady = true; });
    };
    Object.defineProperty(SubStatusEditOverlayComponent.prototype, "statuses", {
        get: function () {
            var process = this.processesRepository.processes[0];
            return process ? process.statuses : [];
        },
        enumerable: true,
        configurable: true
    });
    SubStatusEditOverlayComponent.prototype.validate = function () {
        var err = false;
        if (!this.substatus.title.trim().length) {
            this.substatus.titleError = this.currentLang.words['enter-status'];
            err = true;
        }
        else if (this.substatus.title.trim().length > 250) {
            this.substatus.titleError = this.currentLang.words['substatus-limit-error'];
            err = true;
        }
        else if (!/^[0-9a-zA-Z\u0590-\u05FF _-]+$/.test(this.substatus.title)) {
            this.substatus.titleError = this.currentLang.words['substatus-title-error'];
            err = true;
        }
        else {
            this.substatus.titleError = "";
        }
        if (!this.substatus.position) {
            this.substatus.positionError = this.currentLang.words['substatus-position-error'];
            err = true;
        }
        if (this.substatus.position && this.substatus.position.toString().length > 10) {
            this.substatus.positionError = this.currentLang.words['substatus-position-error'];
            err = true;
        }
        if (!this.substatus.statusId) {
            this.substatus.statusIdError = this.currentLang.words['enter-status-stage'];
            err = true;
        }
        else {
            this.substatus.statusIdError = "";
        }
        return !err;
    };
    SubStatusEditOverlayComponent.prototype.save = function () {
        var _this = this;
        if (this.substatus.template)
            this.substatus.template = this.appService.formatText(this.substatus.template, false);
        if (this.validate()) {
            this.substatusesRepository.sort();
            this.appService.showNetworkstatus("sending data...");
            this.substatusesRepository.sendUpdateSubstatus(this.substatus).subscribe(function (res) {
                if (res.responseCode === 200) {
                    _this.appService.showNetworkstatus("sent ok");
                    _this.processesRepository.load();
                    _this.substatusesRepository.load();
                    _this.substatusesRepository.sort();
                    _this.close();
                }
                else if (res.responseCode === 400) {
                    _this.appService.showError("API error", _this.currentLang.words['status-exist'], true, res.responseCode);
                }
                else {
                    _this.appService.showError("API error", res.error || res.Error, true, res.responseCode);
                }
            }, function (err) {
                var message = _this.appService.getErrorMessage(err);
                _this.appService.showError("HTTP error", _this.currentLang.words["http_error"] + " in substatus-edit-overlay save sendUpdateSubstatus: " + message, true);
            });
        }
    };
    return SubStatusEditOverlayComponent;
}());
export { SubStatusEditOverlayComponent };
