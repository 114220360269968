import * as tslib_1 from "tslib";
import { Model } from './model';
var Lead = /** @class */ (function (_super) {
    tslib_1.__extends(Lead, _super);
    function Lead(assigneeId, assigneeName, callRecordingUrl, callStatus, campaignId, campaignTitle, createDate, //2019-11-09T16:00:53.39
    dealAmount, email, followUp, //2019-11-09T16:00:53.39
    formUrl, fullname, googleKeyword, id, leadAdditionalStatusId, leadProcessId, leadStatusId, leadType, otherFields, phone, refererUrl, isCalculatedLead, leadClientId, assetTitle, restrictions, 
    // not from API
    rawFollowup, rawCreatedate, token, prices, notes, preview, 
    // validate
    emailError, phoneError, expand) {
        var _this = _super.call(this) || this;
        _this.assigneeId = assigneeId;
        _this.assigneeName = assigneeName;
        _this.callRecordingUrl = callRecordingUrl;
        _this.callStatus = callStatus;
        _this.campaignId = campaignId;
        _this.campaignTitle = campaignTitle;
        _this.createDate = createDate;
        _this.dealAmount = dealAmount;
        _this.email = email;
        _this.followUp = followUp;
        _this.formUrl = formUrl;
        _this.fullname = fullname;
        _this.googleKeyword = googleKeyword;
        _this.id = id;
        _this.leadAdditionalStatusId = leadAdditionalStatusId;
        _this.leadProcessId = leadProcessId;
        _this.leadStatusId = leadStatusId;
        _this.leadType = leadType;
        _this.otherFields = otherFields;
        _this.phone = phone;
        _this.refererUrl = refererUrl;
        _this.isCalculatedLead = isCalculatedLead;
        _this.leadClientId = leadClientId;
        _this.assetTitle = assetTitle;
        _this.restrictions = restrictions;
        _this.rawFollowup = rawFollowup;
        _this.rawCreatedate = rawCreatedate;
        _this.token = token;
        _this.prices = prices;
        _this.notes = notes;
        _this.preview = preview;
        _this.emailError = emailError;
        _this.phoneError = phoneError;
        _this.expand = expand;
        return _this;
    }
    Lead.prototype.build = function (o, token) {
        _super.prototype.build.call(this, o);
        this.rawFollowup = new Date(this.followUp);
        this.rawCreatedate = new Date(this.createDate);
        if (token) {
            this.token = token;
        }
        return this;
    };
    Lead.prototype.init = function () {
        this.fullname = "";
        this.leadProcessId = 0;
        this.leadStatusId = 0;
        var d = new Date();
        // current date in format "1900-01-01T00:00:00.00"
        this.followUp = d.getFullYear() + "-" + this.twoDigits(d.getMonth() + 1) + "-" + this.twoDigits(d.getDate()) + "T" + this.twoDigits(d.getHours()) + ":" + this.twoDigits(d.getMinutes()) + ":00.00";
        this.createDate = this.followUp;
        this.rawFollowup = new Date(this.followUp);
        this.rawCreatedate = new Date(this.createDate);
        return this;
    };
    Object.defineProperty(Lead.prototype, "formatFollowup", {
        get: function () {
            return this.rawFollowup.getDate() + "/" + this.twoDigits(this.rawFollowup.getMonth() + 1) + "/" + this.rawFollowup.getFullYear() + " " + this.twoDigits(this.rawFollowup.getHours()) + ":" + this.twoDigits(this.rawFollowup.getMinutes());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Lead.prototype, "formatCreatedate", {
        get: function () {
            return this.rawCreatedate.getDate() + "/" + this.twoDigits(this.rawCreatedate.getMonth() + 1) + "/" + this.rawCreatedate.getFullYear() + " " + this.twoDigits(this.rawCreatedate.getHours()) + ":" + this.twoDigits(this.rawCreatedate.getMinutes());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Lead.prototype, "calendarFollowupDate", {
        get: function () {
            return { date: { year: this.rawFollowup.getFullYear(), month: this.rawFollowup.getMonth() + 1, day: this.rawFollowup.getDate() } };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Lead.prototype, "followupHour", {
        get: function () {
            return this.rawFollowup.getHours();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Lead.prototype, "followupMinute", {
        get: function () {
            return this.rawFollowup.getMinutes();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Lead.prototype, "fullRefererUrl", {
        get: function () {
            if (this.refererUrl.length) {
                return (this.refererUrl.indexOf("http") === -1) ? "http://" + this.refererUrl : this.refererUrl;
            }
            else {
                return this.refererUrl;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Lead.prototype, "whatsapp", {
        get: function () {
            return this.phone.replace(/[^0-9]/g, '');
        },
        enumerable: true,
        configurable: true
    });
    return Lead;
}(Model));
export { Lead };
