import * as tslib_1 from "tslib";
import { Model } from './model';
var LeadNote = /** @class */ (function (_super) {
    tslib_1.__extends(LeadNote, _super);
    function LeadNote(assigneeId, leadId, content, createDate, gpsLatitude, gpsLongitude, id, 
    // not from API
    rawCreateDate) {
        var _this = _super.call(this) || this;
        _this.assigneeId = assigneeId;
        _this.leadId = leadId;
        _this.content = content;
        _this.createDate = createDate;
        _this.gpsLatitude = gpsLatitude;
        _this.gpsLongitude = gpsLongitude;
        _this.id = id;
        _this.rawCreateDate = rawCreateDate;
        return _this;
    }
    LeadNote.prototype.build = function (o) {
        _super.prototype.build.call(this, o);
        this.rawCreateDate = new Date(this.createDate);
        return this;
    };
    LeadNote.prototype.init = function () {
        this.assigneeId = 0;
        this.leadId = 0;
        this.content = "";
        this.rawCreateDate = new Date();
        this.createDate = (new Date()).toISOString();
        this.gpsLatitude = "";
        this.gpsLongitude = "";
        return this;
    };
    Object.defineProperty(LeadNote.prototype, "formatCreateDate", {
        get: function () {
            return this.rawCreateDate.getDate() + "/" + this.twoDigits(this.rawCreateDate.getMonth() + 1) + "/" + this.rawCreateDate.getFullYear() + " " + this.rawCreateDate.getHours() + ":" + this.twoDigits(this.rawCreateDate.getMinutes());
        },
        enumerable: true,
        configurable: true
    });
    return LeadNote;
}(Model));
export { LeadNote };
