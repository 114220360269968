import * as tslib_1 from "tslib";
import { NgZone } from "@angular/core";
import { Platform, NavController } from '@ionic/angular';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { AuthService } from './auth.service';
import { LeadsRepositoryBUT } from './leads.repository.but';
import { AppService } from './app.service';
import { Lead } from '../model/lead.model';
var CallService = /** @class */ (function () {
    function CallService(platform, androidPermissions, authService, leadsRepository, appService, ngZone, navCtrl) {
        this.platform = platform;
        this.androidPermissions = androidPermissions;
        this.authService = authService;
        this.leadsRepository = leadsRepository;
        this.appService = appService;
        this.ngZone = ngZone;
        this.navCtrl = navCtrl;
        this.prevState = "";
        this.state = "";
        this.oldCalls = [];
    }
    Object.defineProperty(CallService.prototype, "canWork", {
        get: function () { return this.platform.is("android") && (!this.platform.is('mobileweb')); },
        enumerable: true,
        configurable: true
    });
    CallService.prototype.init = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var strOldCalls;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.canWork) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.setPermissions()];
                    case 1:
                        if (_a.sent()) {
                            this.initCallProcessing();
                            window.drawover.initialize(function () { }, function () { }, {});
                            strOldCalls = localStorage.getItem("oldCalls");
                            if (strOldCalls) {
                                this.oldCalls = JSON.parse(strOldCalls);
                            }
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    CallService.prototype.setPermissions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var values;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.setPermission(this.androidPermissions.PERMISSION.READ_PHONE_STATE),
                            this.setPermission(this.androidPermissions.PERMISSION.READ_CALL_LOG)
                        ])];
                    case 1:
                        values = _a.sent();
                        return [2 /*return*/, values.every(function (i) { return i; })];
                }
            });
        });
    };
    CallService.prototype.setPermission = function (permission) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, res2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.androidPermissions.checkPermission(permission)];
                    case 1:
                        res = _a.sent();
                        if (res.hasPermission) {
                            return [2 /*return*/, true];
                        }
                        return [4 /*yield*/, this.androidPermissions.requestPermission(permission)];
                    case 2:
                        res2 = _a.sent();
                        return [2 /*return*/, res2.hasPermission];
                }
            });
        });
    };
    CallService.prototype.initCallProcessing = function () {
        var _this = this;
        if (PhoneCallTrap) {
            PhoneCallTrap.onCall(function (obj) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var call, lead;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!this.authService.authenticated) return [3 /*break*/, 2];
                            this.prevState = this.state;
                            call = JSON.parse(obj);
                            if (!call || !call.incomingNumber) {
                                return [2 /*return*/];
                            }
                            this.state = call.state;
                            if (call.incomingNumber.includes('+')) {
                                call.incomingNumber = call.incomingNumber.replace('+', '');
                            }
                            return [4 /*yield*/, this.findLead(call.incomingNumber)];
                        case 1:
                            lead = _a.sent();
                            if (this.state === "RINGING") { // incoming call
                                if (lead) {
                                    this.showCallReceiveDrawOver(lead, call.incomingNumber);
                                }
                                else {
                                    this.showCallEndDrawOver(call.incomingNumber);
                                }
                            }
                            else if (this.state === "IDLE") {
                                if (this.prevState === "RINGING") { // call ended without answer
                                    if (lead) {
                                        this.showCallReceiveDrawOver(lead, call.incomingNumber);
                                    }
                                    else {
                                        this.showCallEndDrawOver(call.incomingNumber);
                                    }
                                }
                                else if (this.prevState === 'OFFHOOK') { // call ended with answer
                                    if (!this.oldCalls.includes(call.incomingNumber)) {
                                        if (lead) {
                                            this.showCallReceiveDrawOver(lead, call.incomingNumber);
                                        }
                                        else {
                                            this.showCallEndDrawOver(call.incomingNumber);
                                        }
                                        this.oldCalls.push(call.incomingNumber);
                                        localStorage.setItem("oldCalls", JSON.stringify(this.oldCalls));
                                    }
                                }
                            }
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    CallService.prototype.findLead = function (phone) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.leadsRepository.ready.subscribe(function (r) {
                if (r) {
                    _this.leadsRepository.loadLeads(0, 1, phone, 0, "followUp", 0).subscribe(function (res) {
                        if (res.responseCode === 200) {
                            if (res.data.leads.length) {
                                resolve(new Lead().build(res.data.leads[0]));
                            }
                            else {
                                resolve(null);
                            }
                        }
                        else {
                            _this.appService.showError("API error", res.responseCode + " " + (res.error || res.Error), false, res.responseCode);
                            resolve(null);
                        }
                    }, function (err) {
                        var message = _this.appService.getErrorMessage(err);
                        _this.appService.showError("HTTP error", message + " in CallService findLead loadLeads");
                        resolve(null);
                    });
                }
            });
        });
    };
    // using Dhaval plugin
    CallService.prototype.showCallReceiveDrawOver = function (lead, phoneNumber) {
        var _this = this;
        window.drawover.callReceive(function () {
            _this.ngZone.run(function () {
                window.plugins.bringtofront();
                _this.navCtrl.navigateForward("/leadClients/" + lead.leadClientId);
            });
        }, function (error) { }, { phoneNumber: phoneNumber, name: lead.fullname });
    };
    // using Dhaval plugin
    CallService.prototype.showCallEndDrawOver = function (phoneNumber) {
        var _this = this;
        window.drawover.callEnd(function () {
            _this.ngZone.run(function () {
                window.plugins.bringtofront();
                _this.navCtrl.navigateForward("/leadClients/new/fromcalls/" + phoneNumber);
            });
        }, function (error) { }, { phoneNumber: phoneNumber });
    };
    return CallService;
}());
export { CallService };
