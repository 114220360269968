import { ChangeDetectorRef } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppService } from './services/app.service';
import { LangsRepository } from './services/langs.repository';
import { AuthService } from './services/auth.service';
import { CallService } from './services/call.service';
import { NotificationService } from './services/notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { environment } from './../environments/environment';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { WhatsappService } from './services/whatsapp.service';
import { LeadClientWhatsappReceivedMessage } from './model/leadClients/lead.client.whatsapp.receivedmessage';
import { MessageStatus } from './model/leadClients/lead.client.whatsapp.messagestatus';
var AppComponent = /** @class */ (function () {
    function AppComponent(platform, splashScreen, statusBar, appService, langsRepository, authService, callService, notificationService, router, route, appVersion, mobileAccessibility, cdref, whatsappService) {
        var _this = this;
        this.platform = platform;
        this.splashScreen = splashScreen;
        this.statusBar = statusBar;
        this.appService = appService;
        this.langsRepository = langsRepository;
        this.authService = authService;
        this.callService = callService;
        this.notificationService = notificationService;
        this.router = router;
        this.route = route;
        this.appVersion = appVersion;
        this.mobileAccessibility = mobileAccessibility;
        this.cdref = cdref;
        this.whatsappService = whatsappService;
        this.langsReady = false;
        this.routeCounter = 0;
        this.showLeadsMenu = false;
        this.token = "";
        this.twoComponents = false;
        this.substatusActive = false;
        this.usersActive = false;
        this.settingsActive = false;
        this.processesActive = false;
        this.initializeApp();
        this.langsRepository.ready.subscribe(function (r) {
            _this.langsReady = r;
        }, function (err) { return console.error(err); });
        this.versionNumber = environment.version;
        this.route.params.subscribe(function (p) {
            _this.token = p["token"];
            if (!_this.token) {
                if (location.hash !== "#/auth/login") {
                    var strs = location.hash.split('/');
                    _this.token = strs[strs.length - 1];
                }
            }
        });
    }
    AppComponent.prototype.goToFixdigital = function () {
        if (this.isMobile) {
            var obj = new InAppBrowser().create(this.fixToken, '_system');
            obj.show();
        }
        else {
            window.open(this.fixToken);
        }
    };
    AppComponent.prototype.hideLeadClient = function () {
        var elem = document.getElementsByClassName('lead-client-routing')[0];
        if (elem) {
            elem.classList.remove('lead-client-visible');
            elem.classList.add('lead-client-hidden');
        }
    };
    AppComponent.prototype.initializeApp = function () {
        var _this = this;
        if (this.isIeBrowser()) {
            this.appService.ieWarningPopupActive = true;
        }
        this.platform.ready().then(function () {
            if (_this.isMobile) {
                _this.statusBar.styleDefault();
                _this.splashScreen.hide();
                _this.callService.init();
                _this.mobileAccessibility.usePreferredTextZoom(false);
                _this.mobileAccessibility.setTextZoom(100);
            }
            // keep tab awake
            // https://learn.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-7.0&tabs=visual-studio#bsleep
            var lockResolver;
            var anyTypeNavigator;
            anyTypeNavigator = navigator;
            if (anyTypeNavigator && anyTypeNavigator.locks && anyTypeNavigator.locks.request) {
                var promise_1 = new Promise(function (res) {
                    lockResolver = res;
                });
                anyTypeNavigator.locks.request('unique_lock_name', { mode: "shared" }, function () {
                    return promise_1;
                });
            }
            _this.whatsappService.signalRConnection$.subscribe(function (con) {
                if (con !== null) {
                    con.on("receiveMessage", function (message, leadClientId, messageContentType) {
                        if (_this.router.url.indexOf("/" + leadClientId + "/whatsapp") === -1) {
                            var leadClient = _this.appService.leadClients.find(function (x) { return x.id === leadClientId; });
                            if (leadClient) {
                                _this.notificationService.message = leadClient.fullName + ": " + message;
                                leadClient.lastMessageStatus = MessageStatus.Delivered;
                            }
                            else {
                                _this.notificationService.message = message;
                            }
                            _this.notificationService.leadId = leadClientId;
                            _this.notificationService.link = "/leadClients/" + leadClientId + "/whatsapp";
                            _this.notificationService.active = true;
                            setTimeout(function () {
                                _this.notificationService.active = false;
                                _this.cdref.detectChanges();
                            }, 5000);
                            _this.cdref.detectChanges();
                        }
                        _this.whatsappService.setReceivedMessage(new LeadClientWhatsappReceivedMessage(leadClientId, message, messageContentType));
                    });
                    con.on("receiveMessageStatus", function (message, leadClientId) {
                        _this.whatsappService.setReceivedMessage(new LeadClientWhatsappReceivedMessage(leadClientId, message));
                    });
                    con.on("receiveNotification", function (message, leadClientId) {
                        var leadClient = _this.appService.leadClients.find(function (x) { return x.id === leadClientId; });
                        _this.notificationService.message = message;
                        _this.notificationService.leadId = leadClientId;
                        _this.notificationService.link = "/leadClients/" + leadClientId;
                        _this.notificationService.active = true;
                        setTimeout(function () {
                            _this.notificationService.active = false;
                            _this.cdref.detectChanges();
                        }, 5000);
                        _this.cdref.detectChanges();
                        _this.whatsappService.setReceivedMessage(new LeadClientWhatsappReceivedMessage(leadClientId, message));
                    });
                }
            }, function (err) { return console.error(err); });
        });
    };
    Object.defineProperty(AppComponent.prototype, "networkstatusClass", {
        get: function () { return this.appService.networkstatusClass; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "networkstatus", {
        get: function () { return this.appService.networkstatus; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "errorActive", {
        get: function () { return this.appService.errorActive; },
        set: function (v) { this.appService.errorActive = v; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "error", {
        get: function () { return this.appService.error; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "currentLang", {
        get: function () { return this.langsRepository.current; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "langs", {
        get: function () { return this.langsRepository.langs; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "downloadPopupActive", {
        get: function () { return this.appService.downloadPopupActive; },
        set: function (v) { this.appService.downloadPopupActive = v; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "androidPopupActive", {
        get: function () { return this.appService.permissionPopupActive; },
        set: function (v) { this.appService.permissionPopupActive = v; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "ieWarningPopupActive", {
        get: function () { return this.appService.ieWarningPopupActive; },
        set: function (v) { this.appService.ieWarningPopupActive = v; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "versionUpdatePopupActive", {
        get: function () { return this.appService.versionUpdatePopupActive; },
        set: function (v) { this.appService.versionUpdatePopupActive = v; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "menuClass", {
        get: function () { return this.appService.menuClass; },
        set: function (v) { this.appService.menuClass = v; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "introPopupActive", {
        get: function () { return this.appService.introPopupActive; },
        set: function (v) { this.appService.introPopupActive = v; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "user", {
        get: function () { return this.authService.user; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "notificationActive", {
        get: function () { return this.notificationService.active; },
        set: function (v) { this.notificationService.active = v; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "tooltipPopupActive", {
        get: function () { return this.appService.tooltipPopupActive; },
        set: function (v) { this.appService.tooltipPopupActive = v; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "textOfTooltipPopup", {
        get: function () { return this.appService.textOfTooltipPopup; },
        set: function (v) { this.appService.textOfTooltipPopup = v; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "titleOfTooltipPopup", {
        get: function () { return this.appService.titleOfTooltipPopup; },
        set: function (v) { this.appService.titleOfTooltipPopup = v; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "followUpAlertNoticationActive", {
        get: function () { return this.notificationService.followUpAlert; },
        set: function (v) { this.notificationService.followUpAlert = v; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "whatsAppNotificationActive", {
        get: function () { return this.notificationService.whatsAppAlert; },
        set: function (v) { this.notificationService.whatsAppAlert = v; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "notificationTitle", {
        get: function () { return this.notificationService.notificationTitle; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "notificationLeadId", {
        get: function () { return this.notificationService.leadId; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "notificationLink", {
        get: function () { return this.notificationService.link; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "notificationMessage", {
        get: function () { return this.notificationService.message; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "isDesctop", {
        get: function () { return !this.platform.is("cordova"); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "isMobile", {
        get: function () { return this.platform.is("ios") || this.platform.is("android"); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "fixToken", {
        get: function () { return environment.baseFixUrl + "LogAs?fixToken=" + this.authService.getToken(); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "isWhatsApp", {
        get: function () { return location.hash.includes("#/lc/phone/"); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "isGmail", {
        get: function () { return location.hash.includes("#/lc/email/"); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "isIOS", {
        get: function () { return this.platform.is("ios"); },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.onActivate = function () {
        if (!this.platform.is("cordova")) {
            if (this.routeCounter < 3) {
                this.routeCounter++;
            }
            if (this.routeCounter === 3) {
                this.appService.initDownloadPopup();
            }
            this.showLeadsMenu = this.router.url.includes('/l/');
            if (this.showLeadsMenu) {
                this.token = this.router.url.replace('/l/', '');
            }
        }
    };
    AppComponent.prototype.showDownloadPopup = function () {
        this.appService.downloadPopupActive = true;
    };
    AppComponent.prototype.setCurrentLang = function (langSlug) {
        this.menuClass = "";
        this.langsRepository.setCurrent(langSlug);
    };
    AppComponent.prototype.isIeBrowser = function () {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }
        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }
        // other browser
        return false;
    };
    return AppComponent;
}());
export { AppComponent };
