import { LangsRepository } from '../services/langs.repository';
import { AppService } from '../services/app.service';
import { SubstatusesRepository } from '../services/substatuses.repository';
import { ProcessesRepositoryBUT } from '../services/processes.repository.but';
import { Location } from '@angular/common';
import { StatusType } from '../Enums/status.type.enum';
import { AuthService } from '../services/auth.service';
var SubstatusesPage = /** @class */ (function () {
    function SubstatusesPage(langsRepository, substatusesRepository, processesRepository, authService, appService, location) {
        this.langsRepository = langsRepository;
        this.substatusesRepository = substatusesRepository;
        this.processesRepository = processesRepository;
        this.authService = authService;
        this.appService = appService;
        this.location = location;
        this.langsReady = false;
        this.substatusesReady = false;
        this.processesReady = false;
        this.substatusNewActive = false;
        this.currentGlobalStatusId = 0;
        this.currentStatusToEdit = null;
        this.substatusEditActive = false;
        this.substatusReplaceActive = false;
        this.currentReplaceStatusId = 0;
        this.init();
    }
    SubstatusesPage.prototype.back = function () {
        this.location.back();
    };
    SubstatusesPage.prototype.init = function () {
        var _this = this;
        this.langsRepository.ready.subscribe(function (r) { if (r) {
            _this.langsReady = true;
        } });
        this.processesRepository.prepare().then(function () { _this.processesReady = true; });
        this.substatusesRepository.prepare().then(function () { _this.substatusesReady = true; });
    };
    Object.defineProperty(SubstatusesPage.prototype, "currentLang", {
        get: function () { return this.langsRepository.current; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SubstatusesPage.prototype, "substatuses", {
        get: function () { return this.substatusesRepository.substatuses.sort(this.sortBy); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SubstatusesPage.prototype, "menuClass", {
        set: function (v) { this.appService.menuClass = v; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SubstatusesPage.prototype, "blueSubs", {
        get: function () { return this.substatusesRepository.substatuses.filter(function (x) { return x.statusId == StatusType.InProcess; }).sort(this.sortBy); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SubstatusesPage.prototype, "redSubs", {
        get: function () { return this.substatusesRepository.substatuses.filter(function (x) { return x.statusId == StatusType.NotGood; }).sort(this.sortBy); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SubstatusesPage.prototype, "yellowSubs", {
        get: function () { return this.substatusesRepository.substatuses.filter(function (x) { return x.statusId == StatusType.Meeting; }).sort(this.sortBy); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SubstatusesPage.prototype, "greenSubs", {
        get: function () { return this.substatusesRepository.substatuses.filter(function (x) { return x.statusId == StatusType.Closed; }).sort(this.sortBy); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SubstatusesPage.prototype, "user", {
        get: function () { return this.authService.user; },
        enumerable: true,
        configurable: true
    });
    SubstatusesPage.prototype.sortBy = function (item1, item2) {
        if (item1.position < item2.position)
            return -1;
        if (item1.position > item2.position)
            return 1;
        if (item1.title > item2.title)
            return 1;
        else if (item1.title < item2.title)
            return -1;
        return 0;
    };
    SubstatusesPage.prototype.updatePositionInArray = function (substatus, isUp) {
        var _this = this;
        this.appService.showNetworkstatus("sending data...");
        this.substatusesRepository.setPositionToSubstatus(substatus, isUp).subscribe(function (res) {
            if (res.data)
                _this.appService.showNetworkstatus("sent ok");
            else
                _this.appService.showError("API error", res.error || res.Error, true, res.responseCode);
        }, function (err) {
            var message = _this.appService.getErrorMessage(err);
            _this.appService.showError("HTTP error", _this.currentLang.words["http_error"] + " in substatuses-page updatePositionInArray setPositionToSubstatus: " + message, true);
        });
        var sub = this.substatusesRepository.substatuses.find(function (x) { return x.id == substatus.id; });
        if (sub) {
            var oldPosition = sub.position;
            var currentPosition = isUp ? sub.position - 1 : sub.position + 1;
            var sub2 = this.substatusesRepository.substatuses.find(function (x) { return x.statusId == substatus.statusId && x.position == currentPosition; });
            sub.position = currentPosition;
            if (sub2)
                sub2.position = oldPosition;
        }
    };
    SubstatusesPage.prototype.goToEdit = function (substatus) {
        this.currentStatusToEdit = substatus;
        this.substatusEditActive = true;
    };
    return SubstatusesPage;
}());
export { SubstatusesPage };
