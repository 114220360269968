import { EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { LangsRepository } from 'src/app/services/langs.repository';
import { UsersRepository } from 'src/app/services/users.repository';
import { AppService } from '../../services/app.service';
var SettingsOverlayComponent = /** @class */ (function () {
    function SettingsOverlayComponent(appService, authService, langsRepository, usersRepository) {
        this.appService = appService;
        this.authService = authService;
        this.langsRepository = langsRepository;
        this.usersRepository = usersRepository;
        this.ready = false;
        this.activeChange = new EventEmitter();
        this.cancel = new EventEmitter();
        this.ready = true;
    }
    Object.defineProperty(SettingsOverlayComponent.prototype, "user", {
        get: function () { return this.authService.user; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SettingsOverlayComponent.prototype, "menuClass", {
        set: function (v) { this.appService.menuClass = v; },
        enumerable: true,
        configurable: true
    });
    SettingsOverlayComponent.prototype.close = function () {
        this.active = false;
        this.activeChange.emit(false);
    };
    SettingsOverlayComponent.prototype.init = function () { };
    SettingsOverlayComponent.prototype.save = function () {
        var _this = this;
        this.usersRepository.sendUpdateUserSettings(this.user).subscribe(function (res) {
            _this.authService.updateUserSettings(_this.user);
            _this.active = false;
            _this.activeChange.emit(false);
        });
    };
    return SettingsOverlayComponent;
}());
export { SettingsOverlayComponent };
