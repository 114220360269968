import { BehaviorSubject } from 'rxjs';
var ProcessesRepository = /** @class */ (function () {
    function ProcessesRepository(langsRepository) {
        this.langsRepository = langsRepository;
        this.loading = new BehaviorSubject(false);
        this.ready = new BehaviorSubject(false);
        this.processes = [];
    }
    Object.defineProperty(ProcessesRepository.prototype, "locale", {
        get: function () { return this.langsRepository.current.locale; },
        enumerable: true,
        configurable: true
    });
    return ProcessesRepository;
}());
export { ProcessesRepository };
