import * as tslib_1 from "tslib";
import { DataService } from './DataServices/data.service';
import { ProcessesRepository } from './processes.repository';
import { LangsRepository } from './langs.repository';
import { AppService } from './app.service';
var ProcessesRepositoryBUT = /** @class */ (function (_super) {
    tslib_1.__extends(ProcessesRepositoryBUT, _super);
    function ProcessesRepositoryBUT(dataService, langsRepository, appService) {
        var _this = _super.call(this, langsRepository) || this;
        _this.dataService = dataService;
        _this.langsRepository = langsRepository;
        _this.appService = appService;
        _this.lastReload = 0;
        _this.cacheKey = "";
        _this.cacheKey = _this.getCurrentCacheKeyFromLocalStorage();
        return _this;
    }
    ProcessesRepositoryBUT.prototype.load = function () {
        var _this = this;
        this.dataService.token.subscribe(function (token) {
            _this.loading.next(true);
            _this.ready.next(false);
            _this.processes = [];
            if (token) {
                _this.dataService.but_getProcesses(_this.locale).subscribe(function (res) {
                    if (res.responseCode === 200) {
                        _this.processes = (res.data.length) ? res.data : [];
                        _this.lastReload = (new Date()).getTime();
                        _this.setCacheKeyIfNotExistToLocalStorage(res.settingsCacheToken);
                        _this.loading.next(false);
                        _this.ready.next(true);
                    }
                    else {
                        _this.appService.showError("API error", res.error || res.Error, false, res.responseCode);
                    }
                }, function (err) {
                    var message = _this.appService.getErrorMessage(err);
                    _this.appService.showError("HTTP error", _this.langsRepository.current.words["http_error"] + " in ProcessesRepositoryBUT load but_getProcesses: " + message);
                });
            }
        });
    };
    ProcessesRepositoryBUT.prototype.prepare = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if ((_this.ready.value || _this.loading.value) && _this.getCurrentCacheKeyFromLocalStorage() === _this.cacheKey) {
                resolve();
            }
            else {
                _this.load();
                _this.ready.subscribe(function (r) {
                    if (r) {
                        resolve();
                        _this.updateCacheKeyInLocalStorage(_this.cacheKey);
                    }
                });
            }
        });
    };
    ProcessesRepositoryBUT.prototype.setCacheKeyIfNotExistToLocalStorage = function (key) {
        var storeKey = localStorage.getItem('cacheKey');
        if (!storeKey)
            localStorage.setItem('cacheKey', key);
    };
    ProcessesRepositoryBUT.prototype.getCurrentCacheKeyFromLocalStorage = function () {
        return localStorage.getItem('cacheKey');
    };
    ProcessesRepositoryBUT.prototype.updateCacheKeyInLocalStorage = function (key) {
        localStorage.setItem('cacheKey', key);
    };
    ProcessesRepositoryBUT.prototype.getStatuses = function () {
        return this.dataService.but_getStatuses();
    };
    ProcessesRepositoryBUT.prototype.updateProcess = function (process) {
        return this.dataService.but_updateProcess(process);
    };
    ProcessesRepositoryBUT.prototype.createProcess = function (process) {
        return this.dataService.but_createProcess(process);
    };
    ProcessesRepositoryBUT.prototype.deleteProcess = function (process) {
        return this.dataService.but_deleteProcess(process);
    };
    ProcessesRepositoryBUT.prototype.delete = function (process) {
        var index = this.processes.findIndex(function (ss) { return ss.id === process.id; });
        if (index !== -1) {
            this.processes.splice(index, 1);
        }
    };
    return ProcessesRepositoryBUT;
}(ProcessesRepository));
export { ProcessesRepositoryBUT };
