import * as tslib_1 from "tslib";
import { Model } from '../model';
var LeadClient = /** @class */ (function (_super) {
    tslib_1.__extends(LeadClient, _super);
    function LeadClient(id, assigneeId, callRecordingUrl, callStatus, createDate, updateDate, amount, email, followUp, fullName, additionalStatusId, leadStatusId, phone, leads, meetings, deals, notes, assetTitle, campaignTitle, referralUrl, source, address, addressCoords, processId, lastMessageDate, lastMessageStatus, whatsappPhoneId, countryCode, 
    // not from API
    rawFollowup, rawCreatedate, token, preview, current, showEmails, showPhones, countOfWhatsAppMessages, 
    //
    emailError, phoneError, error) {
        var _this = _super.call(this) || this;
        _this.id = id;
        _this.assigneeId = assigneeId;
        _this.callRecordingUrl = callRecordingUrl;
        _this.callStatus = callStatus;
        _this.createDate = createDate;
        _this.updateDate = updateDate;
        _this.amount = amount;
        _this.email = email;
        _this.followUp = followUp;
        _this.fullName = fullName;
        _this.additionalStatusId = additionalStatusId;
        _this.leadStatusId = leadStatusId;
        _this.phone = phone;
        _this.leads = leads;
        _this.meetings = meetings;
        _this.deals = deals;
        _this.notes = notes;
        _this.assetTitle = assetTitle;
        _this.campaignTitle = campaignTitle;
        _this.referralUrl = referralUrl;
        _this.source = source;
        _this.address = address;
        _this.addressCoords = addressCoords;
        _this.processId = processId;
        _this.lastMessageDate = lastMessageDate;
        _this.lastMessageStatus = lastMessageStatus;
        _this.whatsappPhoneId = whatsappPhoneId;
        _this.countryCode = countryCode;
        _this.rawFollowup = rawFollowup;
        _this.rawCreatedate = rawCreatedate;
        _this.token = token;
        _this.preview = preview;
        _this.current = current;
        _this.showEmails = showEmails;
        _this.showPhones = showPhones;
        _this.countOfWhatsAppMessages = countOfWhatsAppMessages;
        _this.emailError = emailError;
        _this.phoneError = phoneError;
        _this.error = error;
        return _this;
    }
    LeadClient.prototype.init = function () {
        this.fullName = "";
        this.leadStatusId = 0;
        var d = new Date();
        // current date in format "1900-01-01T00:00:00.00"
        this.followUp = d.getFullYear() + "-" + this.twoDigits(d.getMonth() + 1) + "-" + this.twoDigits(d.getDate()) + "T" + this.twoDigits(d.getHours()) + ":" + this.twoDigits(d.getMinutes()) + ":00.00";
        // this.CreateDate = this.FollowUp;
        this.notes = [];
        this.rawFollowup = new Date(this.followUp);
        if (!this.createDate)
            this.createDate = new Date().toString();
        this.rawCreatedate = new Date(this.createDate);
        return this;
    };
    LeadClient.prototype.build = function (o, token) {
        _super.prototype.build.call(this, o);
        if (this.followUp)
            this.followUp = this.followUp.replace(/-/g, '/').replace("T", " ").split('.')[0];
        if (this.meetings) {
            this.meetings.sort(function (d1, d2) { return new Date(d2.meetingDate).getTime() - new Date(d1.meetingDate).getTime(); });
            this.meetings.forEach(function (item) {
                item.meetingDate = item.meetingDate.replace(/-/g, '/').replace("T", " ");
            });
        }
        this.notes = [];
        this.rawFollowup = new Date(this.followUp);
        this.rawCreatedate = new Date(this.createDate);
        if (token) {
            this.token = token;
        }
        return this;
    };
    Object.defineProperty(LeadClient.prototype, "formatFollowup", {
        get: function () {
            return this.rawFollowup.getDate() + "/" + this.twoDigits(this.rawFollowup.getMonth() + 1) + "/" + this.rawFollowup.getFullYear() + " " + this.twoDigits(this.rawFollowup.getHours()) + ":" + this.twoDigits(this.rawFollowup.getMinutes());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeadClient.prototype, "formatCreatedate", {
        get: function () {
            return this.rawCreatedate.getDate() + "/" + this.twoDigits(this.rawCreatedate.getMonth() + 1) + "/" + this.rawCreatedate.getFullYear() + " " + this.twoDigits(this.rawCreatedate.getHours()) + ":" + this.twoDigits(this.rawCreatedate.getMinutes());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeadClient.prototype, "calendarFollowupDate", {
        get: function () {
            return { date: { year: this.rawFollowup.getFullYear(), month: this.rawFollowup.getMonth() + 1, day: this.rawFollowup.getDate() } };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeadClient.prototype, "calendarCurrentDate", {
        get: function () {
            return { date: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() } };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeadClient.prototype, "followupHour", {
        get: function () {
            return this.rawFollowup.getHours();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeadClient.prototype, "followupMinute", {
        get: function () {
            return this.rawFollowup.getMinutes();
        },
        enumerable: true,
        configurable: true
    });
    return LeadClient;
}(Model));
export { LeadClient };
