import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { LeadNote } from '../../model/leadnote.model';
import { environment } from '../../../environments/environment';
var DataService = /** @class */ (function () {
    function DataService(http) {
        this.http = http;
        this.token = new BehaviorSubject("");
        this.userId = new BehaviorSubject(null);
        this.locale = "";
        this.baseUrl = environment.baseUrlV1;
        this.locale = localStorage.getItem("currentLang") == "en" ? "en-US" : "he-IL";
    }
    Object.defineProperty(DataService.prototype, "headers", {
        get: function () {
            return new HttpHeaders({
                "X-CLIENT-TOKEN": this.token.value,
                "X-LOCALE": this.locale,
                "X-USER-ID": this.userId.value !== null ? this.userId.value.toString() : "",
            });
        },
        enumerable: true,
        configurable: true
    });
    ;
    // by lead token (BLT)
    DataService.prototype.blt_getLead = function (token, locale) {
        return this.blt_sendRequest("GET", "lead/getByToken", null, token, locale);
    };
    DataService.prototype.blt_getLeadPrices = function (token) {
        return this.blt_sendRequest("GET", "lead/getLeadPrices", null, token);
    };
    DataService.prototype.blt_getLeadNotes = function (token) {
        return this.blt_sendRequest("GET", "notes/getNotes", null, token);
    };
    DataService.prototype.blt_addLeadNote = function (token, note) {
        return this.blt_sendRequest("PUT", "notes/addNote", note, token);
    };
    DataService.prototype.blt_updateLeadFollowup = function (token, followUp) {
        return this.blt_sendRequest("PATCH", "lead/setFollowup", { followUp: followUp }, token);
    };
    DataService.prototype.blt_updateLead = function (token, lead) {
        return this.blt_sendRequest("POST", "lead/setByToken", lead, token);
    };
    DataService.prototype.blt_updateLeadStatus = function (token, statusId) {
        return this.blt_sendRequest("PATCH", "lead/setStatus", { leadStatusId: statusId }, token);
    };
    DataService.prototype.blt_updateLeadSubstatus = function (token, substatusId) {
        return this.blt_sendRequest("PATCH", "lead/setSubstatus", { leadAdditionalStatusId: substatusId }, token);
    };
    DataService.prototype.blt_updateLeadDealAmount = function (token, amount) {
        return this.blt_sendRequest("PATCH", "lead/setDealAmount", { dealAmount: amount }, token);
    };
    DataService.prototype.blt_getAssignees = function (token) {
        return this.blt_sendRequest("GET", "assignees/getAssignees", null, token);
    };
    DataService.prototype.blt_getProcesses = function (token, locale) {
        return this.blt_sendRequest("GET", "processes/GetProcessesStatusesSubStatuses", null, token, locale);
    };
    DataService.prototype.blt_getPaymentIframe = function (token, locale, login) {
        return this.blt_sendRequest("POST", "payment/requestPaymentIframe", { login: login }, token, locale);
    };
    DataService.prototype.blt_addUserBySms = function (token, smsCode, login, locale) {
        return this.sendRequest("PUT", "users/createUserBySms", { smsCode: smsCode, login: login }, { headers: new HttpHeaders({ "X-LEAD-TOKEN": token, "X-LOCALE": locale }), observe: "response" });
    };
    DataService.prototype.blt_registrationProcess = function (token, email, phone, locale, firstName, lastName) {
        return this.sendRequest("PUT", "users/registrationProcess", { email: email, phone: phone, firstName: firstName, lastName: lastName }, { headers: new HttpHeaders({ "X-LEAD-TOKEN": token, "X-LOCALE": locale }), observe: "response" });
    };
    DataService.prototype.blt_registrationComplete = function (token, smsCode, email, phone, locale, firstName, lastName) {
        return this.sendRequest("PUT", "users/registrationComplete", { smsCode: smsCode, email: email, phone: phone, firstName: firstName, lastName: lastName }, { headers: new HttpHeaders({ "X-LEAD-TOKEN": token, "X-LOCALE": locale }), observe: "response" });
    };
    DataService.prototype.blt_resendSmsForRegistration = function (token, id, locale) {
        return this.sendRequest("POST", 'users/resendSmsForRegistration', { id: id }, { headers: new HttpHeaders({ "X-LEAD-TOKEN": token, "X-LOCALE": locale }), observe: 'response' });
    };
    DataService.prototype.blt_sendRequest = function (verb, url, body, token, locale) {
        if (locale === void 0) { locale = null; }
        var headers = new HttpHeaders();
        if (token)
            headers = headers.set("X-LEAD-TOKEN", token);
        if (locale)
            headers = headers.set("X-LOCALE", locale);
        return this.sendRequest(verb, url, body, { headers: headers });
    };
    // BUT
    // by user token
    // WARNING! SortDirection==0 <=> [old first], SortDirection==1 <=> [new first]
    DataService.prototype.but_getLeads = function (from, q, search, filter, sort, dir) {
        return this.sendRequest("POST", "lead/list", { Take: q, Skip: from, Search: search, Filter: filter, Sort: { Member: sort, SortDirection: "" + dir } }, { headers: this.headers });
    };
    DataService.prototype.but_getAssignees = function () {
        return this.sendRequest("GET", "assignees/getAssignees", null, { headers: this.headers });
    };
    DataService.prototype.but_getProcesses = function (locale) {
        return this.sendRequest("GET", "processes/GetProcessesStatusesSubStatuses", null, { headers: this.headers });
    };
    DataService.prototype.but_getStatuses = function () {
        return this.sendRequest("GET", "processes/GetStatusesSubStatuses", null, { headers: this.headers });
    };
    DataService.prototype.but_createProcess = function (process) {
        return this.sendRequest("POST", "processes/createProcess", process, { headers: this.headers });
    };
    DataService.prototype.but_updateProcess = function (process) {
        return this.sendRequest("POST", "processes/updateProcess", process, { headers: this.headers });
    };
    DataService.prototype.but_deleteProcess = function (process) {
        return this.sendRequest("POST", "processes/deleteProcess", process, { headers: this.headers });
    };
    DataService.prototype.but_getSubstatuses = function () {
        return this.sendRequest("GET", "substatuses/getSubStatuses", null, { headers: this.headers });
    };
    DataService.prototype.but_addSubstatus = function (substatus) {
        return this.sendRequest("PUT", "substatuses/createSubStatus", substatus, { headers: this.headers });
    };
    DataService.prototype.but_updateSubstatus = function (substatus) {
        return this.sendRequest("POST", "substatuses/updateSubStatus", substatus, { headers: this.headers });
    };
    DataService.prototype.but_deleteSubstatus = function (substatus) {
        return this.sendRequest("DELETE", "substatuses/deleteSubStatus", null, { headers: this.headers, body: substatus });
    };
    DataService.prototype.but_getUsers = function () {
        return this.sendRequest("GET", "users/getUsers", null, { headers: this.headers });
    };
    DataService.prototype.but_addUser = function (user, locale) {
        return this.sendRequest("PUT", "users/createUser", user, { headers: this.headers });
    };
    DataService.prototype.but_updateUser = function (user) {
        return this.sendRequest("POST", "users/updateUser", user, { headers: this.headers, observe: "response" });
    };
    DataService.prototype.but_updateUserSettings = function (user) {
        return this.sendRequest("POST", "users/updateUserSettings", user, { headers: this.headers, observe: "response" });
    };
    DataService.prototype.but_deleteUser = function (user, locale) {
        return this.sendRequest("DELETE", "users/deleteUser", null, { headers: this.headers, body: user });
    };
    // universal
    DataService.prototype.getLangs = function () {
        return this.http.get("assets/json/langs.json");
    };
    DataService.prototype.getTemplates = function () {
        return this.http.get("assets/json/templates.json");
    };
    DataService.prototype.authGetToken = function () {
        return this.sendRequest("GET", "auth/getAuthToken", null, { headers: this.headers });
    };
    DataService.prototype.getCurrentVersionFromServer = function () {
        return this.sendRequest2("GET", "info/version", null);
    };
    DataService.prototype.authLogAs = function (clientToken) {
        return this.sendRequest("GET", "auth/logAs?clientToken=" + clientToken, null, { observe: "response" });
    };
    DataService.prototype.login = function (username, password, locale) {
        return this.sendRequest("POST", "auth/login", { "username": "" + username, "password": "" + password, "Locale": "" + locale }, { observe: "response" });
    };
    DataService.prototype.forgotPassword = function (username, locale) {
        return this.sendRequest("POST", "auth/forgotPassword", { "username": "" + username, "Locale": "" + locale }, { observe: "response" });
    };
    // generic request
    DataService.prototype.sendRequest = function (verb, url, body, options) {
        if (options === void 0) { options = {}; }
        if (verb === "POST") {
            return this.http.post(this.baseUrl + url, body, options);
        }
        else if (verb === "PATCH") {
            return this.http.patch(this.baseUrl + url, body, options);
        }
        else if (verb === "PUT") {
            return this.http.put(this.baseUrl + url, body, options);
        }
        else if (verb === "DELETE") {
            return this.http.delete(this.baseUrl + url, options);
        }
        else {
            return this.http.get(this.baseUrl + url, options);
        }
    };
    DataService.prototype.sendRequest2 = function (verb, url, body) {
        if (verb === "POST") {
            return this.http.post(environment.baseUrlV2 + url, body, { headers: this.headers });
        }
        else if (verb === "PATCH") {
            return this.http.patch(environment.baseUrlV2 + url, body, { headers: this.headers });
        }
        else if (verb === "PUT") {
            return this.http.put(environment.baseUrlV2 + url, body, { headers: this.headers });
        }
        else if (verb === "DELETE") {
            return this.http.delete(environment.baseUrlV2 + url, { headers: this.headers });
        }
        else if (verb === "FILE") {
            return this.http.get(environment.baseUrlV2 + url, { headers: this.headers, observe: "response", responseType: "arraybuffer" });
        }
        else { // GET
            return this.http.get(environment.baseUrlV2 + url, { headers: this.headers });
        }
    };
    DataService.prototype.sendRequest2WithCrmToken = function (token, verb, url, body) {
        var headers = new HttpHeaders({ "X-WHATSAPPCRM-TOKEN": token });
        if (verb === "POST") {
            return this.http.post(environment.baseUrlV2 + url, body, { headers: headers });
        }
        else if (verb === "PATCH") {
            return this.http.patch(environment.baseUrlV2 + url, body, { headers: headers });
        }
        else if (verb === "PUT") {
            return this.http.put(environment.baseUrlV2 + url, body, { headers: headers });
        }
        else if (verb === "DELETE") {
            return this.http.delete(environment.baseUrlV2 + url, { headers: headers });
        }
        else if (verb === "FILE") {
            return this.http.get(environment.baseUrlV2 + url, { headers: headers, observe: "response", responseType: "arraybuffer" });
        }
        else { // GET
            return this.http.get(environment.baseUrlV2 + url, { headers: headers });
        }
    };
    // v2
    DataService.prototype.but_getLeadClients = function (from, q, search, filter, sort, dir, processId) {
        return this.sendRequest2("POST", "leadClient/list", { Take: q, Skip: from, Search: search, Filter: filter, processId: processId, Sort: { Member: sort, SortDirection: "" + dir } });
    };
    DataService.prototype.but_updateLeadClientFollowUp = function (id, followUp) {
        return this.sendRequest2("PATCH", "leadClient/setFollowup", { Id: id, followUp: followUp });
    };
    DataService.prototype.but_updateLeadClientStatus = function (leadClient) {
        return this.sendRequest2("PATCH", "leadClient/setStatus", leadClient);
    };
    DataService.prototype.but_getLeadClient = function (id) {
        return this.sendRequest2("GET", "leadClient/getByToken?id=" + id, null);
    };
    DataService.prototype.but_addLeadClient = function (leadClient) {
        return this.sendRequest2("POST", "leadClient/create", { fullname: leadClient.fullName, email: leadClient.email, phone: leadClient.phone, assigneeId: leadClient.assigneeId, processId: leadClient.processId, leadStatusId: leadClient.leadStatusId, AdditionalStatusId: leadClient.additionalStatusId, source: leadClient.source, address: leadClient.address });
    };
    DataService.prototype.but_updateLeadClient = function (leadClient) {
        return this.sendRequest2("PUT", "leadClient/update", {
            AdditionalStatusId: leadClient.additionalStatusId,
            address: leadClient.address,
            addressCoords: leadClient.addressCoords,
            assigneeId: leadClient.assigneeId,
            id: leadClient.id,
            email: leadClient.email,
            followUp: leadClient.followUp,
            fullname: leadClient.fullName,
            leadStatusId: leadClient.leadStatusId,
            phone: leadClient.phone,
            processId: leadClient.processId,
            source: leadClient.source,
        });
    };
    DataService.prototype.but_updateLeadClientDeal = function (dealId, leadClientId, subject, amount, assigneeId, dealDate) {
        return this.sendRequest2("PATCH", "leadClient/setDeals", { dealId: dealId, leadClientId: leadClientId, subject: subject, amount: amount, assigneeId: assigneeId, dealDate: dealDate, externalCrmId: 0 });
    };
    DataService.prototype.but_getDealById = function (id) {
        return this.sendRequest2("GET", "leadClient/deal/getById?id=" + id, null);
    };
    DataService.prototype.but_UpdateDeal = function (id, leadClientId, subject, amount, assigneeId) {
        return this.sendRequest2("PATCH", "leadClient/updateDeal", { id: id, leadClientId: leadClientId, subject: subject, amount: amount, assigneeId: assigneeId });
    };
    DataService.prototype.but_LeadClientAddMeeting = function (id, leadClientId, meetingDate, meetingEndDate, subject, meetingAssigneeId, externalId, note, meetingStatus) {
        return this.sendRequest2("PATCH", "leadClient/addMeeting", { id: id, leadClientId: leadClientId, meetingDate: meetingDate, meetingEndDate: meetingEndDate, subject: subject, meetingAssigneeId: meetingAssigneeId, externalId: externalId, note: note, meetingStatus: meetingStatus });
    };
    DataService.prototype.but_LeadClientGetMeeting = function (id) {
        return this.sendRequest2("GET", "leadClient/getMeetingById?id=" + id, null);
    };
    DataService.prototype.but_LeadClientCancelMeeting = function (id, locale) {
        return this.sendRequest2("GET", "leadClient/cancelMeetingById?id=" + id, null);
    };
    DataService.prototype.but_LeadClientCancelDeal = function (id) {
        return this.sendRequest2("GET", "leadClient/cancelDealById?id=" + id, null);
    };
    DataService.prototype.but_LeadClientUpdateMeeting = function (id, leadClientId, meetingDate, meetingEndDate, subject, meetingAssigneeId, externalId, note, meetingStatus) {
        return this.sendRequest2("PATCH", "leadClient/updateMeeting", { id: id, leadClientId: leadClientId, meetingDate: meetingDate, meetingEndDate: meetingEndDate, subject: subject, meetingAssigneeId: meetingAssigneeId, externalId: externalId, note: note, meetingStatus: meetingStatus });
    };
    DataService.prototype.but_getLeadClientNotes = function (id) {
        return this.sendRequest2("GET", "leadClient/getNotes?id=" + id, null);
    };
    DataService.prototype.but_addLeadClientNote = function (leadClientId, note, assigneeId, gpsLongitude, gpsLatitude, createdDate) {
        return this.sendRequest2("POST", "leadClient/createNote", { leadClientId: leadClientId, note: note, assigneeId: assigneeId, gpsLongitude: gpsLongitude, gpsLatitude: gpsLatitude, createdDate: createdDate });
    };
    DataService.prototype.but_getLeadNotes = function (leadId) {
        return this.sendRequest2("GET", "lead/getNotes?leadId=" + leadId, null);
    };
    DataService.prototype.but_addLeadNote = function (leadId, note, assigneeId, gpsLongitude, gpsLatitude, createdDate) {
        var leadNote = new LeadNote();
        leadNote.assigneeId = assigneeId;
        leadNote.leadId = leadId;
        leadNote.content = note;
        leadNote.createDate = createdDate;
        leadNote.gpsLatitude = gpsLatitude;
        leadNote.gpsLongitude = gpsLongitude;
        return this.sendRequest2("POST", "lead/createLeadNote", leadNote);
    };
    DataService.prototype.but_setPositionToSubstatus = function (substatus, isUp) {
        return this.sendRequest2("PATCH", "substatus/setPositionToSubstatus", { Id: substatus.id, isUp: isUp });
    };
    DataService.prototype.but_getLeadsForLeadClient = function (id) {
        return this.sendRequest2("GET", "leadClient/leads?id=" + id, null);
    };
    DataService.prototype.but_getLead = function (leadId) {
        return this.sendRequest2("GET", "lead?leadId=" + leadId, null);
    };
    DataService.prototype.but_getDealsForLeadClient = function (id) {
        return this.sendRequest2("GET", "leadClient/deals?id=" + id, null);
    };
    DataService.prototype.but_getMeetingsForLeadClient = function (id) {
        return this.sendRequest2("GET", "leadClient/meetings?id=" + id, null);
    };
    DataService.prototype.but_getAdPreview = function (channelId, adGroupId, adId) {
        return this.sendRequest2("GET", "adpreview?channelId=" + channelId + "&adGroupId=" + adGroupId + "&adId=" + adId, null);
    };
    //#region SignalR
    DataService.prototype.getAllWhatsappMessagesLeadClient = function (leadClientId) {
        return this.sendRequest2("GET", "leadClient/getAllMessages?id=" + leadClientId, null);
    };
    DataService.prototype.getMessageFile = function (url) {
        return this.convertFileResponseToBlob(this.sendRequest2("FILE", url, null));
    };
    DataService.prototype.getTemplatesForLeadClient = function (leadClientId) {
        return this.sendRequest2("GET", "leadClient/templates?id=" + leadClientId, null);
    };
    DataService.prototype.markMessagesAsRead = function (leadClientId) {
        return this.sendRequest2("POST", "leadClient/markMessagesAsRead", { leadClientId: leadClientId });
    };
    DataService.prototype.sendReplyWhatsappMessage = function (leadClientId, message, userIdentityName) {
        return this.sendRequest2("PUT", "leadClient/sendReplyMessage", { "leadClientId": "" + leadClientId, "message": "" + message, "userIdentityName": "" + userIdentityName });
    };
    DataService.prototype.sendReplyWhatsappFile = function (leadClientId, userIdentityName, files) {
        var formData = new FormData();
        formData.append('leadClientId', leadClientId.toString());
        formData.append('userIdentityName', userIdentityName);
        if (files && files.length > 0) {
            for (var i = 0; i < files.length; i++) {
                formData.append('files', files[i], files[i].name);
            }
        }
        return this.sendRequest2("POST", "leadClient/sendReplyFile", formData);
    };
    DataService.prototype.sendTemplateWhatsappMessage = function (leadClientId, templateName, userIdentityName) {
        return this.sendRequest2("PUT", "leadClient/sendTemplateMessage", { "leadClientId": "" + leadClientId, "templateName": "" + templateName, "userIdentityName": "" + userIdentityName });
    };
    DataService.prototype.getLeadClient = function (phone) {
        return this.sendRequest2("GET", "leadClient/getByPhone?phone=" + phone, null);
    };
    DataService.prototype.getLeadClientByEmail = function (email) {
        return this.sendRequest2("GET", "leadClient/getByEmail?email=" + email, null);
    };
    DataService.prototype.updateLeadClientAddress = function (leadClientId, address, addressCoords) {
        return this.sendRequest2("PUT", "leadClient/updateAddress", { "leadClientId": "" + leadClientId, "address": address, "addressCoords": addressCoords });
    };
    //#region WithCrmToken
    DataService.prototype.getAllWhatsappMessagesLeadClientWithCrmToken = function (token, leadClientId) {
        return this.sendRequest2WithCrmToken(token, "GET", "leadClient/getAllMessages?id=" + leadClientId, null);
    };
    DataService.prototype.getMessageFileWithCrmToken = function (token, url) {
        return this.convertFileResponseToBlob(this.sendRequest2WithCrmToken(token, "FILE", url, null));
    };
    DataService.prototype.getLeadClientWithCrmToken = function (token, id) {
        return this.sendRequest2WithCrmToken(token, "GET", "leadClient/getByCrmToken?leadClientId=" + id, null);
    };
    DataService.prototype.getTemplatesForLeadClientWithCrmToken = function (token, leadClientId) {
        return this.sendRequest2WithCrmToken(token, "GET", "leadClient/templates?id=" + leadClientId, null);
    };
    DataService.prototype.markMessagesAsReadWithCrmToken = function (token, leadClientId) {
        return this.sendRequest2WithCrmToken(token, "POST", "leadClient/markMessagesAsRead", { leadClientId: leadClientId });
    };
    DataService.prototype.sendReplyWhatsappMessageWithCrmToken = function (token, leadClientId, message, userIdentityName) {
        return this.sendRequest2WithCrmToken(token, "PUT", "leadClient/sendReplyMessage", { "leadClientId": "" + leadClientId, "message": "" + message, "userIdentityName": "" + userIdentityName });
    };
    DataService.prototype.sendReplyWhatsappFileMessageWithCrmToken = function (token, leadClientId, message, userIdentityName, files) {
        var formData = new FormData();
        formData.append("leadClientId", leadClientId.toString());
        formData.append("userIdentityName", userIdentityName);
        for (var i = 0; i < files.length; i++) {
            formData.append('files', files[i], files[i].name);
        }
        return this.sendRequest2WithCrmToken(token, "POST", "leadClient/sendReplyFile", formData);
    };
    DataService.prototype.sendTemplateWhatsappMessageWithCrmToken = function (token, leadClientId, templateName, userIdentityName) {
        return this.sendRequest2WithCrmToken(token, "PUT", "leadClient/sendTemplateMessage", { "leadClientId": "" + leadClientId, "templateName": "" + templateName, "userIdentityName": "" + userIdentityName });
    };
    DataService.prototype.but_getAdPreviewWithCrmToken = function (token, channelId, adGroupId, adId) {
        return this.sendRequest2WithCrmToken(token, "GET", "adpreview?channelId=" + channelId + "&adGroupId=" + adGroupId + "&adId=" + adId, null);
    };
    //#endregion WithCrmToken
    DataService.prototype.convertFileResponseToBlob = function (response) {
        return new Promise(function (resolve, reject) {
            response.toPromise().then(function (response) {
                var contentType = response.headers.get("Content-Type");
                if (contentType.startsWith('application/json')) {
                    var decoder = new TextDecoder("utf-8");
                    var resp = JSON.parse(decoder.decode(new Uint8Array(response.body)));
                    reject(resp);
                }
                else {
                    var blob = new Blob([response.body], { type: contentType });
                    resolve(blob);
                }
            }).catch(reject);
        });
    };
    return DataService;
}());
export { DataService };
