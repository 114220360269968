import { DataService } from './DataServices/data.service';
import { BehaviorSubject } from 'rxjs';
import { User } from '../model/user.model';
import { LangsRepository } from './langs.repository';
var UsersRepository = /** @class */ (function () {
    function UsersRepository(dataService, langsRepository) {
        this.dataService = dataService;
        this.langsRepository = langsRepository;
        this.loading = new BehaviorSubject(false);
        this.ready = new BehaviorSubject(false);
        this.lastReload = 0;
        this.users = [];
    }
    Object.defineProperty(UsersRepository.prototype, "locale", {
        get: function () { return this.langsRepository.current.locale; },
        enumerable: true,
        configurable: true
    });
    UsersRepository.prototype.load = function () {
        var _this = this;
        this.dataService.token.subscribe(function (token) {
            _this.loading.next(true);
            _this.ready.next(false);
            _this.users = [];
            if (token) {
                _this.dataService.but_getUsers().subscribe(function (res) {
                    if (res.responseCode === 200) {
                        _this.users = (res.data.length) ? res.data.map(function (d) { return (new User()).build(d); }) : [];
                        _this.lastReload = (new Date()).getTime();
                        _this.loading.next(false);
                        _this.ready.next(true);
                    }
                    else {
                        _this.loading.next(false);
                        _this.ready.next(true);
                        _this.users = [];
                    }
                }, function (err) { });
            }
            else {
                _this.loading.next(false);
                _this.ready.next(false);
                _this.users = [];
            }
        });
    };
    UsersRepository.prototype.prepare = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.ready.value || _this.loading.value) {
                resolve();
            }
            else {
                _this.load();
                _this.ready.subscribe(function (r) {
                    if (r) {
                        resolve();
                    }
                });
            }
        });
    };
    UsersRepository.prototype.getUserById = function (id) { return this.users.find(function (u) { return u.id === id; }) || null; };
    UsersRepository.prototype.sendAddUser = function (user) { return this.dataService.but_addUser(user, this.locale); };
    UsersRepository.prototype.sendUpdateUser = function (user) { return this.dataService.but_updateUser(user); };
    UsersRepository.prototype.sendUpdateUserSettings = function (user) { return this.dataService.but_updateUserSettings(user); };
    UsersRepository.prototype.sendDeleteUser = function (user) { return this.dataService.but_deleteUser(user, this.locale); };
    UsersRepository.prototype.delete = function (user) {
        var index = this.users.findIndex(function (u) { return u.id === user.id; });
        if (index !== -1) {
            this.users.splice(index, 1);
        }
    };
    return UsersRepository;
}());
export { UsersRepository };
