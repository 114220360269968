import { LangsRepository } from '../../services/langs.repository';
var ComingsoonPage = /** @class */ (function () {
    function ComingsoonPage(langsRepository) {
        var _this = this;
        this.langsRepository = langsRepository;
        this.langsReady = false;
        this.langsRepository.ready.subscribe(function (r) {
            if (r) {
                _this.langsReady = true;
                var bannerElem = document.getElementById("banner");
                if (bannerElem) {
                    document.getElementById("banner").classList.add('full');
                }
            }
        });
    }
    Object.defineProperty(ComingsoonPage.prototype, "currentLang", {
        get: function () { return this.langsRepository.current; },
        enumerable: true,
        configurable: true
    });
    ComingsoonPage.prototype.back = function () { window.history.back(); };
    return ComingsoonPage;
}());
export { ComingsoonPage };
