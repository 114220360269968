import * as tslib_1 from "tslib";
import * as Sentry from "@sentry/angular";
import { Integrations } from '@sentry/tracing';
import * as i0 from "@angular/core";
var ErrorHandlerService = /** @class */ (function (_super) {
    tslib_1.__extends(ErrorHandlerService, _super);
    function ErrorHandlerService(config) {
        var _this = _super.call(this, config.options) || this;
        _this._options.extractor = function (e) { return e.rejection || _this._defaultExtractor(e); };
        var release = config.release && (typeof config.release === 'string' ? config.release : config.release());
        Sentry.init({
            release: release,
            environment: config.environment,
            dsn: config.dsn,
            integrations: config.browserTracking !== false ? [
                new Integrations.BrowserTracing(),
            ] : [],
            tracesSampleRate: config.tracesSampleRate,
            beforeSend: function (event, hint) {
                try {
                    if (hint.originalException instanceof Error) {
                        var err = hint.originalException;
                        // disable sentry log of errors that cannot be handled in handleError
                        if (ErrorHandlerService.skipErrorLog(err.message)) {
                            return null;
                        }
                    }
                }
                catch (err) { }
                return event;
            },
        });
        return _this;
    }
    // onError = new EventEmitter();
    ErrorHandlerService.skipErrorLog = function (error) {
        var message = "";
        if (error instanceof Error) {
            message = error.message;
        }
        else if (typeof error === 'object') {
            message = JSON.stringify(error);
        }
        else {
            message = String(error);
        }
        if (typeof message === "string" && (message.includes("WebSocket failed to connect") // signalR cannot connect to server
            || message.includes("Unknown Error"))) { // httpClient cannot connect to server
            return true;
        }
        return false;
    };
    ErrorHandlerService.prototype.setShowDialog = function (show) {
        this._options.showDialog = show;
    };
    ErrorHandlerService.prototype.handleError = function (error) {
        var _this = this;
        if (!ErrorHandlerService.skipErrorLog(error)) {
            // this.onError.emit(error);
            Sentry.withScope(function (scope) {
                scope.setExtra("errorExtraData", error);
                scope.setExtra("handlerSource", "service");
                _super.prototype.handleError.call(_this, error);
            });
        }
        // do not catch in dev mode
        // if (isDevMode()) {
        //   console.error(error);
        // } else {
        //   super.handleError(error);
        // }
    };
    ErrorHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorHandlerService_Factory() { return new ErrorHandlerService(i0.ɵɵinject("config")); }, token: ErrorHandlerService, providedIn: "root" });
    return ErrorHandlerService;
}(Sentry.SentryErrorHandler));
export { ErrorHandlerService };
