import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { StatusType } from 'src/app/Enums/status.type.enum';
import { LangsRepository } from 'src/app/services/langs.repository';
import { ProcessesRepositoryBUT } from 'src/app/services/processes.repository.but';
import { SubstatusesRepository } from 'src/app/services/substatuses.repository';
import { AppService } from '../../services/app.service';
var SubStatusReplaceOverlayComponent = /** @class */ (function () {
    function SubStatusReplaceOverlayComponent(langsRepository, substatusesRepository, processesRepository, appService, loadingCtrl) {
        this.langsRepository = langsRepository;
        this.substatusesRepository = substatusesRepository;
        this.processesRepository = processesRepository;
        this.appService = appService;
        this.loadingCtrl = loadingCtrl;
        this.ready = false;
        this.activeChange = new EventEmitter();
        this.cancel = new EventEmitter();
        this.substatusId = 0;
        this.globalStatus = 0;
        this.langsReady = false;
        this.substatusesReady = false;
        this.processesReady = false;
        this.ready = true;
        this.init();
    }
    SubStatusReplaceOverlayComponent.prototype.close = function () {
        this.active = false;
        this.activeChange.emit(false);
    };
    SubStatusReplaceOverlayComponent.prototype.init = function () {
        var _this = this;
        this.langsRepository.ready.subscribe(function (r) { if (r)
            _this.langsReady = true; });
    };
    Object.defineProperty(SubStatusReplaceOverlayComponent.prototype, "substatuses", {
        get: function () {
            var _this = this;
            var checkForCanDelete = this.substatusesRepository.substatuses.filter(function (x) { return x.id !== _this.substatusId; }).length == 0;
            if (checkForCanDelete) {
                return this.substatusesRepository.substatuses.filter(function (x) { return x.id !== _this.substatusId; });
            }
            return this.substatusesRepository.substatuses.filter(function (x) { return x.id !== _this.substatusId; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SubStatusReplaceOverlayComponent.prototype, "subStatusForInProcess", {
        get: function () {
            var _this = this;
            return this.substatusesRepository.substatuses.filter(function (x) { return x.statusId == StatusType.InProcess && x.id !== _this.substatusId; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SubStatusReplaceOverlayComponent.prototype, "subStatusForMeetingProcess", {
        get: function () {
            var _this = this;
            return this.substatusesRepository.substatuses.filter(function (x) { return x.statusId == StatusType.Meeting && x.id !== _this.substatusId; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SubStatusReplaceOverlayComponent.prototype, "subStatusForDealProcess", {
        get: function () {
            var _this = this;
            return this.substatusesRepository.substatuses.filter(function (x) { return x.statusId == StatusType.Closed && x.id !== _this.substatusId; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SubStatusReplaceOverlayComponent.prototype, "subStatusForNotGoodProcess", {
        get: function () {
            var _this = this;
            return this.substatusesRepository.substatuses.filter(function (x) { return x.statusId == StatusType.NotGood && x.id !== _this.substatusId; });
        },
        enumerable: true,
        configurable: true
    });
    SubStatusReplaceOverlayComponent.prototype.delete = function (ss) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sub, loading_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!confirm(this.currentLang.words["sure"])) return [3 /*break*/, 2];
                        this.appService.showNetworkstatus("sending data...");
                        sub = this.substatusesRepository.substatuses.find(function (x) { return x.id == _this.substatusId; });
                        ss.replaceStatusId = this.substatusId;
                        return [4 /*yield*/, this.loadingCtrl.create({
                                message: this.currentLang.words["wait"],
                            })];
                    case 1:
                        loading_1 = _a.sent();
                        loading_1.present();
                        this.substatusesRepository.sendDeleteSubstatus(ss).subscribe(function (res) {
                            if (res.responseCode === 200) {
                                _this.appService.showNetworkstatus("sent ok");
                                _this.processesRepository.load();
                            }
                            else
                                _this.appService.showError("API error", res.error || res.Error, true, res.responseCode);
                            loading_1.dismiss();
                            _this.close();
                        }, function (err) {
                            loading_1.dismiss();
                            var message = _this.appService.getErrorMessage(err);
                            _this.appService.showError("HTTP error", _this.currentLang.words["http_error"] + " in substatus-replace-overlay delete sendDeleteSubstatus: " + message, true);
                        });
                        this.substatusesRepository.delete(sub);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    SubStatusReplaceOverlayComponent.prototype.replaceToGlobalStatus = function (statusId) {
        var _this = this;
        if (confirm(this.currentLang.words["sure"])) {
            var ss = { statusId: statusId, position: 0, replaceStatusId: 0, id: 0 };
            this.appService.showNetworkstatus("sending data...");
            var sub = this.substatusesRepository.substatuses.find(function (x) { return x.id == _this.substatusId; });
            ss.replaceStatusId = this.substatusId;
            this.substatusesRepository.sendDeleteSubstatus(ss).subscribe(function (res) {
                if (res.responseCode === 200) {
                    _this.appService.showNetworkstatus("sent ok");
                    _this.processesRepository.load();
                    _this.substatusesRepository.delete(sub);
                    _this.close();
                }
                else
                    _this.appService.showError("API error", res.error || res.Error, true, res.responseCode);
            }, function (err) {
                var message = _this.appService.getErrorMessage(err);
                _this.appService.showError("HTTP error", _this.currentLang.words["http_error"] + " in substatus-replace-overlay replaceToGlobalStatus sendDeleteSubstatus: " + message, true);
            });
        }
    };
    return SubStatusReplaceOverlayComponent;
}());
export { SubStatusReplaceOverlayComponent };
