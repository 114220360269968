import * as tslib_1 from "tslib";
import { DataService } from './DataServices/data.service';
import { AssigneesRepository } from './assignees.repository';
import { AppService } from './app.service';
import { LangsRepository } from "./langs.repository";
var AssigneesRepositoryBUT = /** @class */ (function (_super) {
    tslib_1.__extends(AssigneesRepositoryBUT, _super);
    function AssigneesRepositoryBUT(dataService, appService, langsRepository) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.appService = appService;
        _this.langsRepository = langsRepository;
        _this.lastReload = 0;
        _this.cacheKey = "";
        _this.cacheKey = _this.getCurrentCacheKeyFromLocalStorage();
        return _this;
    }
    AssigneesRepositoryBUT.prototype.load = function () {
        var _this = this;
        this.dataService.token.subscribe(function (token) {
            _this.ready.next(false);
            _this.assignees = [];
            if (token) {
                _this.dataService.but_getAssignees().subscribe(function (res) {
                    if (res.responseCode === 200) {
                        _this.setCacheKeyIfNotExistToLocalStorage(res.settingsCacheToken);
                        _this.assignees = (res.data.length) ? res.data : [];
                        _this.lastReload = (new Date()).getTime();
                        _this.ready.next(true);
                    }
                    else {
                        _this.appService.showError("API error", res.error || res.Error, false, res.responseCode);
                    }
                }, function (err) {
                    var message = _this.appService.getErrorMessage(err);
                    _this.appService.showError("HTTP error", _this.langsRepository.current.words["http_error"] + " in AssigneesRepositoryBUT load but_getAssignees: " + message, true);
                });
            }
        });
    };
    AssigneesRepositoryBUT.prototype.prepare = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.ready.value && _this.getCurrentCacheKeyFromLocalStorage() == _this.cacheKey) {
                resolve();
            }
            else {
                _this.load();
                _this.ready.subscribe(function (r) {
                    if (r) {
                        resolve();
                        _this.updateCacheKeyInLocalStorage(_this.cacheKey);
                    }
                });
            }
        });
    };
    AssigneesRepositoryBUT.prototype.setCacheKeyIfNotExistToLocalStorage = function (key) {
        var storeKey = localStorage.getItem('cacheKey');
        if (!storeKey)
            localStorage.setItem('cacheKey', key);
    };
    AssigneesRepositoryBUT.prototype.getCurrentCacheKeyFromLocalStorage = function () {
        return localStorage.getItem('cacheKey');
    };
    AssigneesRepositoryBUT.prototype.updateCacheKeyInLocalStorage = function (key) {
        localStorage.setItem('cacheKey', key);
    };
    return AssigneesRepositoryBUT;
}(AssigneesRepository));
export { AssigneesRepositoryBUT };
