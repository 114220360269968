import { LeadClient } from '../model/leadClients/lead.client.model';
import { LeadClientNote } from '../model/leadClients/lead.client.note.model';
var LeadClientsRoutine = /** @class */ (function () {
    function LeadClientsRoutine(langsRepository, appService, authService, assigneesRepository, leadClientRepository, settingsRepository, processesRepository, platform) {
        this.langsRepository = langsRepository;
        this.appService = appService;
        this.authService = authService;
        this.assigneesRepository = assigneesRepository;
        this.leadClientRepository = leadClientRepository;
        this.settingsRepository = settingsRepository;
        this.processesRepository = processesRepository;
        this.platform = platform;
        this.id = 0;
        this.leadClient = null;
        this.leadClientsReady = false;
        this.assigneesReady = false;
        this.processesReady = false;
        this.langsReady = false;
        this.settingsReady = false;
        this.notesReady = false;
        this.appService.initIntroPopup();
    }
    Object.defineProperty(LeadClientsRoutine.prototype, "assignees", {
        get: function () { return this.assigneesRepository.assignees; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeadClientsRoutine.prototype, "currentLang", {
        get: function () { return this.langsRepository.current; },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(LeadClientsRoutine.prototype, "user", {
        get: function () { return this.authService.user; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeadClientsRoutine.prototype, "processes", {
        get: function () { return this.processesRepository.processes; },
        enumerable: true,
        configurable: true
    });
    LeadClientsRoutine.prototype.initLeadClient = function (token) {
        var _this = this;
        if (token === void 0) { token = null; }
        this.leadClientsReady = false; // in this function leadReady will not changed to true, because maybe prices and notes needed, so leadReady must be set manually!
        return new Promise(function (resolve, reject) {
            _this.leadClient = _this.leadClientRepository.getLeadClientById(_this.id);
            _this.leadClientRepository.ready.subscribe(function (r) {
                if (r) {
                    _this.leadClientRepository.loadLeadClient(_this.id).subscribe(function (res) {
                        _this.assigneesRepository.cacheKey = res.settingsCacheToken;
                        _this.processesRepository.cacheKey = res.settingsCacheToken;
                        if (res.responseCode === 200) {
                            _this.leadClient = (new LeadClient()).build(res.data);
                            _this.leadClientRepository.leadCLients.push(_this.leadClient); // if repository was empty, we may push lead to it fo further purposes
                            resolve();
                        }
                        else {
                            _this.catchError("API error", "code: " + res.responseCode + ", error: " + (res.error || res.Error), false, res.responseCode);
                            // reject(res.responseCode);
                        }
                    }, function (err) {
                        _this.catchError("HTTP error", "message: " + _this.currentLang.words["http_error"], true);
                        // reject(err.message);
                    });
                }
            }, function (err) { return console.error(err); });
        });
    };
    LeadClientsRoutine.prototype.initLeadClientNotes = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.leadClientRepository.ready.subscribe(function (r) {
                if (r) {
                    _this.leadClientRepository.getNotes(_this.id).subscribe(function (res) {
                        if (res.responseCode === 200) {
                            _this.leadClient.notes = (res.data.length) ? res.data.map(function (d) { return (new LeadClientNote()).build(d); }) : [];
                            resolve();
                        }
                        else {
                            _this.catchError("API error", "code: " + res.responseCode + ", error: " + (res.error || res.Error), false, res.responseCode);
                            reject(res.responseCode);
                        }
                    }, function (err) {
                        _this.catchError("HTTP error", "message: " + _this.currentLang.words["http_error"], true);
                        reject(err.message);
                    });
                }
            }, function (err) { return console.error(err); });
        });
    };
    LeadClientsRoutine.prototype.initLangs = function () {
        var _this = this;
        this.langsReady = false;
        return new Promise(function (resolve, reject) {
            _this.langsRepository.ready.subscribe(function (r) {
                if (r) {
                    _this.langsReady = true;
                    resolve();
                }
            }, function (err) { return console.error(err); });
        });
    };
    LeadClientsRoutine.prototype.initProcesses = function () {
        var _this = this;
        this.processesReady = false;
        return new Promise(function (resolve, reject) {
            _this.processesRepository.prepare().then(function () {
                _this.processesReady = true;
                resolve();
            });
        });
    };
    LeadClientsRoutine.prototype.initSettings = function () {
        var _this = this;
        this.settingsReady = false;
        return new Promise(function (resolve, reject) {
            _this.settingsRepository.ready.subscribe(function (r) {
                if (r) {
                    _this.settingsReady = true;
                    resolve();
                }
            }, function (err) { return console.error(err); });
        });
    };
    LeadClientsRoutine.prototype.initAssignees = function () {
        var _this = this;
        this.assigneesReady = false;
        return new Promise(function (resolve, reject) {
            _this.assigneesRepository.prepare().then(function () {
                _this.assigneesReady = true;
                resolve();
            });
        });
    };
    LeadClientsRoutine.prototype.catchError = function (errType, errMsg, isServiceNotAvailable, responseCode) {
        this.appService.showError(errType, errMsg, isServiceNotAvailable, responseCode);
    };
    LeadClientsRoutine.prototype.getLeadTemplate = function (leadClient) {
        return (leadClient.additionalStatusId) ? this.getLeadTemplateBySubstatus(leadClient) : this.getLeadTemplateByStatus(leadClient);
    };
    LeadClientsRoutine.prototype.getLeadTemplateByStatus = function (lead) {
        var _this = this;
        var template = this.settingsRepository.leadTemplates.find(function (lt) { return lt.lang === _this.currentLang.slug; });
        var schemaNo = lead.leadStatusId ? lead.leadStatusId.toString() : "0";
        if (schemaNo === "11" && !lead.additionalStatusId) {
            schemaNo = "11s";
        }
        var schema = "";
        if (template) {
            if (template.schemas) {
                schema = template.schemas[schemaNo] || template.schemas["0"];
            }
        }
        return this.getTemplateReplacement(schema, lead);
    };
    LeadClientsRoutine.prototype.getLeadTemplateBySubstatus = function (leadClient) {
        if (this.processesRepository.processes[0]) {
            var status_1 = this.processesRepository.processes[0].statuses.find(function (s) { return s.id === leadClient.leadStatusId; }) || null;
            if (status_1) {
                var substatus = status_1.subStatuses.find(function (ss) { return ss.id === leadClient.additionalStatusId; }) || null;
                if (substatus && substatus.template) {
                    var template = this.getTemplateReplacement(substatus.template, leadClient);
                    if (template == '') {
                        template = this.currentLang.words["empty-values"];
                    }
                    return template;
                }
                else {
                    return this.getLeadTemplateByStatus(leadClient);
                }
            }
            else {
                return this.getLeadTemplateByStatus(leadClient);
            }
        }
    };
    LeadClientsRoutine.prototype.getTemplateReplacement = function (template, leadClient) {
        template = this.replaceAll(template, "{fullname}", leadClient.fullName ? (this.user && this.user.canViewFullname ? leadClient.fullName : "*") : "");
        template = this.replaceAll(template, "{phone}", leadClient.phone ? (this.user && this.user.canViewPhone ? leadClient.phone.split('\n')[0] : "*") : "");
        template = this.replaceAll(template, "{email}", leadClient.email ? (this.user && this.user.canViewEmail ? leadClient.email.split('\n')[0] : "*") : "");
        template = this.replaceAll(template, "{createdate}", leadClient.formatCreatedate ? leadClient.formatCreatedate : "");
        template = this.replaceAll(template, "{followup}", leadClient.formatFollowup ? leadClient.formatFollowup : "");
        template = this.replaceAll(template, "{deal-amount}", leadClient.amount !== 0 && leadClient.amount ? leadClient.amount.toString() : "");
        template = this.replaceAll(template, "{status}", this.getLeadSubstatusName(leadClient) ? this.getLeadSubstatusName(leadClient) : "");
        template = this.replaceAll(template, "{stage}", this.getLeadStatusName(leadClient) ? this.getLeadStatusName(leadClient) : "");
        template = this.replaceAll(template, "{callstatus}", leadClient.callStatus || "");
        template = this.replaceAll(template, "{campaign}", leadClient.campaignTitle);
        template = this.replaceAll(template, "{campaigntitle}", leadClient.campaignTitle);
        template = this.replaceAll(template, "{leadtype}", '' ? '' : "");
        template = this.replaceAll(template, "{referer}", leadClient.referralUrl || "");
        template = this.replaceAll(template, "{recording-url}", leadClient.callRecordingUrl);
        return template
            .replace(/\s,\s/g, " ");
    };
    LeadClientsRoutine.prototype.replaceAll = function (template, search, replace) {
        return template.split(search).join(replace);
    };
    LeadClientsRoutine.prototype.getLeadSubstatusName = function (leadClient) {
        var res = "";
        var process = this.processesRepository.processes[0] || null;
        if (process) {
            var status_2 = process.statuses.find(function (s) { return s.id === leadClient.leadStatusId; }) || null;
            if (status_2) {
                var substatus = status_2.subStatuses.find(function (ss) { return ss.id === leadClient.additionalStatusId; }) || null;
                if (substatus) {
                    res = substatus.title;
                }
            }
        }
        return res;
    };
    LeadClientsRoutine.prototype.getLeadStatusName = function (leadClient) {
        var res = "";
        var process = this.processesRepository.processes[0] || null;
        if (process) {
            var status_3 = process.statuses.find(function (s) { return s.id === leadClient.leadStatusId; }) || null;
            if (status_3) {
                res = status_3.title;
            }
        }
        return res;
    };
    LeadClientsRoutine.prototype.isEmpty = function (v) {
        return v === null || v === undefined;
    };
    return LeadClientsRoutine;
}());
export { LeadClientsRoutine };
