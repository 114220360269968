import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { AppService } from '../../services/app.service';
import { LeadClientsCalendarComponent } from './../lead.clients.calendar';
var LeadCalendarWinComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LeadCalendarWinComponent, _super);
    function LeadCalendarWinComponent(appService) {
        var _this = _super.call(this, appService) || this;
        _this.appService = appService;
        _this.activeChange = new EventEmitter();
        return _this;
    }
    LeadCalendarWinComponent.prototype.close = function () {
        this.active = false;
        this.activeChange.emit(false);
    };
    return LeadCalendarWinComponent;
}(LeadClientsCalendarComponent));
export { LeadCalendarWinComponent };
