import * as tslib_1 from "tslib";
import { AppService } from "../app.service";
import { DataService } from "../DataServices/data.service";
import { LangsRepository } from "../langs.repository";
import { ProcessesRepository } from "../processes.repository";
var ProcessesRepositoryBPT = /** @class */ (function (_super) {
    tslib_1.__extends(ProcessesRepositoryBPT, _super);
    function ProcessesRepositoryBPT(dataService, langsRepository, appService) {
        var _this = _super.call(this, langsRepository) || this;
        _this.dataService = dataService;
        _this.langsRepository = langsRepository;
        _this.appService = appService;
        _this.lastReload = 0;
        _this.cacheKey = "";
        return _this;
    }
    ProcessesRepositoryBPT.prototype.load = function () {
        var _this = this;
        this.dataService.token.subscribe(function (token) {
            _this.ready.next(false);
            _this.processes = [];
            if (token) {
                _this.dataService.but_getProcesses(_this.locale).subscribe(function (res) {
                    if (res.responseCode === 200) {
                        _this.processes = (res.data.length) ? res.data : [];
                        _this.lastReload = (new Date()).getTime();
                        _this.setCacheKeyIfNotExistToLocalStorage(res.settingsCacheToken);
                        _this.ready.next(true);
                    }
                    else {
                        _this.appService.showError("API error", res.error || res.Error, false, res.responseCode);
                    }
                }, function (err) {
                    var message = _this.appService.getErrorMessage(err);
                    _this.appService.showError("HTTP error", _this.langsRepository.current.words["http_error"] + " in ProcessesRepositoryBPT load but_getProcesses: " + message);
                });
            }
        });
    };
    ProcessesRepositoryBPT.prototype.prepare = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.load();
            _this.ready.subscribe(function (r) {
                if (r) {
                    resolve();
                }
            });
        });
    };
    ProcessesRepositoryBPT.prototype.setCacheKeyIfNotExistToLocalStorage = function (key) {
        var storeKey = localStorage.getItem('cacheKey');
        if (!storeKey)
            localStorage.setItem('cacheKey', key);
    };
    ProcessesRepositoryBPT.prototype.getCurrentCacheKeyFromLocalStorage = function () {
        return localStorage.getItem('cacheKey');
    };
    ProcessesRepositoryBPT.prototype.updateCacheKeyInLocalStorage = function (key) {
        localStorage.setItem('cacheKey', key);
    };
    return ProcessesRepositoryBPT;
}(ProcessesRepository));
export { ProcessesRepositoryBPT };
