import { Location } from '@angular/common';
import { LangsRepository } from '../../services/langs.repository';
import { LogService } from 'src/app/services/log.service';
var TestPage = /** @class */ (function () {
    function TestPage(langsRepository, location, logService) {
        var _this = this;
        this.langsRepository = langsRepository;
        this.location = location;
        this.logService = logService;
        this.TOKEN_VALUE = "uco12345";
        this.errorText = "";
        this.langsReady = false;
        this.token = "";
        this.langsRepository.ready.subscribe(function (r) {
            if (r)
                _this.langsReady = true;
        });
    }
    Object.defineProperty(TestPage.prototype, "currentLang", {
        get: function () { return this.langsRepository.current; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TestPage.prototype, "logs", {
        get: function () {
            return this.logService.logs;
        },
        enumerable: true,
        configurable: true
    });
    TestPage.prototype.back = function () { this.location.back(); };
    TestPage.prototype.clearLogs = function () {
        this.logService.clear();
    };
    TestPage.prototype.sendTestError = function () {
        if (!this.token || this.token !== this.TOKEN_VALUE) {
            alert("Enter token");
            return;
        }
        if (!this.errorText) {
            alert("Enter error text");
            return;
        }
        throw new Error(this.errorText);
    };
    return TestPage;
}());
export { TestPage };
