var LeadsRepository = /** @class */ (function () {
    function LeadsRepository(dataService, langsRepository) {
        this.dataService = dataService;
        this.langsRepository = langsRepository;
        this.leads = [];
    }
    Object.defineProperty(LeadsRepository.prototype, "locale", {
        get: function () { return this.langsRepository.current.locale; },
        enumerable: true,
        configurable: true
    });
    LeadsRepository.prototype.sortNotes = function (lead) {
        if (lead.notes && lead.notes.length) {
            lead.notes.sort(function (a, b) {
                return b.id - a.id;
            });
        }
    };
    // WARNING! dir==0 <=> [old first], dir==1 <=> [new first]
    LeadsRepository.prototype.sortLeads = function (sorting, dir) {
        var realSorting = null;
        // we must sort only if sorting setted to followUp or lastUpdate, in other cases date will not change and sorting is not needed!
        if (sorting === "followUp")
            realSorting = "rawFollowup";
        if (realSorting)
            this.leads = this.leads.sort(function (a, b) { return (dir) ? b[realSorting] - a[realSorting] : a[realSorting] - b[realSorting]; });
    };
    LeadsRepository.prototype.reset = function () {
        this.leads = [];
    };
    return LeadsRepository;
}());
export { LeadsRepository };
