import { LangsRepository } from '../services/langs.repository';
import { AppService } from '../services/app.service';
import { ProcessesRepositoryBUT } from '../services/processes.repository.but';
import { Location } from '@angular/common';
import { AuthService } from '../services/auth.service';
var ProcessesPage = /** @class */ (function () {
    function ProcessesPage(langsRepository, processesRepository, authService, appService, location) {
        this.langsRepository = langsRepository;
        this.processesRepository = processesRepository;
        this.authService = authService;
        this.appService = appService;
        this.location = location;
        this.langsReady = false;
        this.processesReady = false;
        this.processNewActive = false;
        this.currentProcessToEdit = null;
        this.processEditActive = false;
        this.processReplaceActive = false;
        this.currentReplaceProcessId = 0;
        this.init();
    }
    ProcessesPage.prototype.back = function () {
        this.location.back();
    };
    ProcessesPage.prototype.init = function () {
        var _this = this;
        this.langsRepository.ready.subscribe(function (r) { if (r) {
            _this.langsReady = true;
        } });
        this.processesRepository.prepare().then(function () { _this.processesReady = true; });
    };
    Object.defineProperty(ProcessesPage.prototype, "currentLang", {
        get: function () { return this.langsRepository.current; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProcessesPage.prototype, "processes", {
        get: function () { return this.processesRepository.processes; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProcessesPage.prototype, "menuClass", {
        set: function (v) { this.appService.menuClass = v; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProcessesPage.prototype, "user", {
        get: function () { return this.authService.user; },
        enumerable: true,
        configurable: true
    });
    // public sortBy(item1: ISubstatus, item2: ISubstatus) {
    //     if (item1.position < item2.position) return -1;
    //     if (item1.position > item2.position) return 1;
    //     if (item1.title > item2.title) return 1;
    //     else if (item1.title < item2.title) return -1;
    //     return 0;
    // }
    ProcessesPage.prototype.goToEdit = function (process) {
        this.currentProcessToEdit = process;
        this.processEditActive = true;
    };
    return ProcessesPage;
}());
export { ProcessesPage };
