import * as tslib_1 from "tslib";
import { AppService } from "../app.service";
import { AssigneesRepository } from "../assignees.repository";
import { DataService } from "../DataServices/data.service";
import { WhatsAppDataService } from "../DataServices/whatsApp.data.service";
import { LangsRepository } from "../langs.repository";
var AssigneesRepositoryBPT = /** @class */ (function (_super) {
    tslib_1.__extends(AssigneesRepositoryBPT, _super);
    function AssigneesRepositoryBPT(whatsAppDataService, appService, langsRepository, dataService) {
        var _this = _super.call(this) || this;
        _this.whatsAppDataService = whatsAppDataService;
        _this.appService = appService;
        _this.langsRepository = langsRepository;
        _this.dataService = dataService;
        _this.lastReload = 0;
        _this.cacheKey = "";
        return _this;
    }
    AssigneesRepositoryBPT.prototype.load = function () {
        var _this = this;
        this.dataService.token.subscribe(function (token) {
            _this.ready.next(false);
            _this.assignees = [];
            if (token) {
                _this.dataService.but_getAssignees().subscribe(function (res) {
                    if (res.responseCode === 200) {
                        _this.setCacheKeyIfNotExistToLocalStorage(res.settingsCacheToken);
                        _this.assignees = (res.data.length) ? res.data : [];
                        _this.lastReload = (new Date()).getTime();
                        _this.ready.next(true);
                    }
                    else {
                        _this.appService.showError("API error", res.error || res.Error, false, res.responseCode);
                    }
                }, function (err) {
                    var message = _this.appService.getErrorMessage(err);
                    _this.appService.showError("HTTP error", _this.langsRepository.current.words["http_error"] + " in AssigneesRepositoryBPT load but_getAssignees: " + message);
                });
            }
        });
    };
    AssigneesRepositoryBPT.prototype.prepare = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.ready.value && _this.getCurrentCacheKeyFromLocalStorage() === _this.cacheKey) {
                resolve();
            }
            else {
                _this.load();
                _this.ready.subscribe(function (r) {
                    if (r) {
                        resolve();
                    }
                });
            }
        });
    };
    AssigneesRepositoryBPT.prototype.setCacheKeyIfNotExistToLocalStorage = function (key) {
        var storeKey = localStorage.getItem('cacheKey');
        if (!storeKey)
            localStorage.setItem('cacheKey', key);
    };
    AssigneesRepositoryBPT.prototype.getCurrentCacheKeyFromLocalStorage = function () {
        return localStorage.getItem('cacheKey');
    };
    AssigneesRepositoryBPT.prototype.updateCacheKeyInLocalStorage = function (key) {
        localStorage.setItem('cacheKey', key);
    };
    return AssigneesRepositoryBPT;
}(AssigneesRepository));
export { AssigneesRepositoryBPT };
