import { LangsRepository } from '../../services/langs.repository';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
var PromoPage = /** @class */ (function () {
    function PromoPage(langsRepository, route, location) {
        var _this = this;
        this.langsRepository = langsRepository;
        this.route = route;
        this.location = location;
        this.langsReady = false;
        this.token = "";
        this.route.params.subscribe(function (p) {
            _this.token = p["token"];
        });
        this.langsRepository.ready.subscribe(function (r) {
            if (r)
                _this.langsReady = true;
        });
    }
    Object.defineProperty(PromoPage.prototype, "currentLang", {
        get: function () { return this.langsRepository.current; },
        enumerable: true,
        configurable: true
    });
    PromoPage.prototype.back = function () { this.location.back(); };
    return PromoPage;
}());
export { PromoPage };
