import * as tslib_1 from "tslib";
import { Model } from './../model';
var LeadClientNote = /** @class */ (function (_super) {
    tslib_1.__extends(LeadClientNote, _super);
    function LeadClientNote(assigneeId, note, createdDate, gpsLatitude, gpsLongitude, id, 
    // not from API
    rawCreateDate) {
        var _this = _super.call(this) || this;
        _this.assigneeId = assigneeId;
        _this.note = note;
        _this.createdDate = createdDate;
        _this.gpsLatitude = gpsLatitude;
        _this.gpsLongitude = gpsLongitude;
        _this.id = id;
        _this.rawCreateDate = rawCreateDate;
        return _this;
    }
    LeadClientNote.prototype.build = function (o) {
        _super.prototype.build.call(this, o);
        this.rawCreateDate = new Date(this.createdDate);
        return this;
    };
    LeadClientNote.prototype.init = function () {
        this.assigneeId = 0;
        this.note = "";
        this.rawCreateDate = new Date();
        this.createdDate = (new Date()).toISOString();
        this.gpsLatitude = "";
        this.gpsLongitude = "";
        return this;
    };
    Object.defineProperty(LeadClientNote.prototype, "formatCreateDate", {
        get: function () {
            return this.rawCreateDate.getDate() + "/" + this.twoDigits(this.rawCreateDate.getMonth() + 1) + "/" + this.rawCreateDate.getFullYear() + " " + this.rawCreateDate.getHours() + ":" + this.twoDigits(this.rawCreateDate.getMinutes());
        },
        enumerable: true,
        configurable: true
    });
    return LeadClientNote;
}(Model));
export { LeadClientNote };
