import * as tslib_1 from "tslib";
import { DataService } from './DataServices/data.service';
import { BehaviorSubject } from 'rxjs';
import { AssigneesRepository } from './assignees.repository';
import { AppService } from './app.service';
import { LangsRepository } from "./langs.repository";
var AssigneesRepositoryBLT = /** @class */ (function (_super) {
    tslib_1.__extends(AssigneesRepositoryBLT, _super);
    function AssigneesRepositoryBLT(dataService, appService, langsRepository) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.appService = appService;
        _this.langsRepository = langsRepository;
        _this.token = new BehaviorSubject("");
        _this.token.subscribe(function (token) {
            _this.ready.next(false);
            if (token) {
                _this.dataService.blt_getAssignees(token).subscribe(function (res) {
                    if (res.responseCode === 200) {
                        _this.assignees = (res.data.length) ? res.data : [];
                        _this.ready.next(true);
                    }
                    else {
                        _this.appService.showError("API error", res.error || res.Error, false, res.responseCode);
                    }
                }, function (err) {
                    var message = _this.appService.getErrorMessage(err);
                    _this.appService.showError("HTTP error", _this.langsRepository.current.words["http_error"] + " in AssigneesRepositoryBLT constructor blt_getAssignees: " + message, true);
                });
            }
        });
        return _this;
    }
    return AssigneesRepositoryBLT;
}(AssigneesRepository));
export { AssigneesRepositoryBLT };
