import * as tslib_1 from "tslib";
import { LeadsRepository } from './leads.repository';
var LeadsRepositoryBLT = /** @class */ (function (_super) {
    tslib_1.__extends(LeadsRepositoryBLT, _super);
    function LeadsRepositoryBLT() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LeadsRepositoryBLT.prototype.loadLeadByToken = function (token) {
        return this.dataService.blt_getLead(token, this.locale);
    };
    LeadsRepositoryBLT.prototype.getLeadByToken = function (token) {
        return this.leads.find(function (l) { return l.token === token; }) || null;
    };
    LeadsRepositoryBLT.prototype.loadLeadPrices = function (token) {
        return this.dataService.blt_getLeadPrices(token);
    };
    LeadsRepositoryBLT.prototype.loadLeadNotes = function (token) {
        return this.dataService.blt_getLeadNotes(token);
    };
    LeadsRepositoryBLT.prototype.sendUpdateLeadFollowup = function (token, followUp) {
        return this.dataService.blt_updateLeadFollowup(token, followUp);
    };
    LeadsRepositoryBLT.prototype.sendAddLeadNote = function (token, note) {
        return this.dataService.blt_addLeadNote(token, note);
    };
    LeadsRepositoryBLT.prototype.sendUpdateLead = function (token, lead) {
        return this.dataService.blt_updateLead(token, lead);
    };
    LeadsRepositoryBLT.prototype.sendUpdateLeadStatus = function (token, statusId) {
        return this.dataService.blt_updateLeadStatus(token, statusId);
    };
    LeadsRepositoryBLT.prototype.sendUpdateLeadSubstatus = function (token, substatusId) {
        return this.dataService.blt_updateLeadSubstatus(token, substatusId);
    };
    LeadsRepositoryBLT.prototype.sendUpdateLeadDealAmount = function (token, amount) {
        return this.dataService.blt_updateLeadDealAmount(token, amount);
    };
    return LeadsRepositoryBLT;
}(LeadsRepository));
export { LeadsRepositoryBLT };
