import { LangsRepository } from '../services/langs.repository';
import { AppService } from '../services/app.service';
import { UsersRepository } from '../services/users.repository';
import { AssigneesRepositoryBUT } from '../services/assignees.repository.but';
import { AuthService } from '../services/auth.service';
var UsersPage = /** @class */ (function () {
    function UsersPage(langsRepository, usersRepository, appService, authService, assigneesRepositoryBUT) {
        this.langsRepository = langsRepository;
        this.usersRepository = usersRepository;
        this.appService = appService;
        this.authService = authService;
        this.assigneesRepositoryBUT = assigneesRepositoryBUT;
        this.langsReady = false;
        this.usersReady = false;
        this.userNewActive = false;
        this.currentUserToEdit = null;
        this.userEditActive = false;
        this.userReplaceActive = false;
        this.currentReplaceUserId = 0;
        this.init();
    }
    UsersPage.prototype.init = function () {
        var _this = this;
        this.langsRepository.ready.subscribe(function (r) { if (r) {
            _this.langsReady = true;
        } });
        this.usersRepository.prepare().then(function () { _this.usersReady = true; });
    };
    Object.defineProperty(UsersPage.prototype, "currentLang", {
        get: function () { return this.langsRepository.current; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UsersPage.prototype, "users", {
        get: function () { return this.usersRepository.users; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UsersPage.prototype, "user", {
        get: function () { return this.authService.user; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UsersPage.prototype, "menuClass", {
        set: function (v) { this.appService.menuClass = v; },
        enumerable: true,
        configurable: true
    });
    UsersPage.prototype.delete = function (user) {
        var _this = this;
        if (confirm(this.currentLang.words["sure"])) {
            this.appService.showNetworkstatus("sending data...");
            this.usersRepository.sendDeleteUser(user).subscribe(function (res) {
                if (!res.data) {
                    _this.appService.showError("API error", _this.currentLang.words['delete-admin'], true, res.responseCode);
                }
                else if (res.responseCode === 200) {
                    _this.appService.showNetworkstatus("sent ok");
                    _this.usersRepository.delete(user);
                    _this.assigneesRepositoryBUT.cacheKey = res.settingsCacheToken;
                }
                else {
                    _this.appService.showError("API error", res.error || res.Error, true, res.responseCode);
                }
            }, function (err) {
                var message = _this.appService.getErrorMessage(err);
                _this.appService.showError("HTTP error", _this.currentLang.words["http_error"] + " in users-page delete sendDeleteUser: " + message, true);
            });
        }
    };
    UsersPage.prototype.goToEdit = function (user) {
        this.currentUserToEdit = user;
        this.userEditActive = true;
    };
    return UsersPage;
}());
export { UsersPage };
