import { EventEmitter } from '@angular/core';
import { LangsRepository } from 'src/app/services/langs.repository';
import { UsersRepository } from 'src/app/services/users.repository';
import { AppService } from 'src/app/services/app.service';
var UserReplaceOverlayComponent = /** @class */ (function () {
    function UserReplaceOverlayComponent(langsRepository, usersRepository, appService) {
        this.langsRepository = langsRepository;
        this.usersRepository = usersRepository;
        this.appService = appService;
        this.ready = false;
        this.activeChange = new EventEmitter();
        this.cancel = new EventEmitter();
        this.userId = 0;
        this.langsReady = false;
        this.usersReady = false;
        this.ready = true;
        this.init();
    }
    UserReplaceOverlayComponent.prototype.close = function () {
        this.active = false;
        this.activeChange.emit(false);
    };
    UserReplaceOverlayComponent.prototype.init = function () {
        var _this = this;
        this.langsRepository.ready.subscribe(function (r) { if (r)
            _this.langsReady = true; });
        this.usersRepository.prepare().then(function () { _this.usersReady = true; });
    };
    Object.defineProperty(UserReplaceOverlayComponent.prototype, "users", {
        get: function () {
            var _this = this;
            var checkForCanDelete = this.usersRepository.users.filter(function (x) { return x.id !== _this.userId; }).length == 0;
            if (checkForCanDelete) {
                return this.usersRepository.users.filter(function (x) { return x.id !== _this.userId; });
            }
            return this.usersRepository.users.filter(function (x) { return x.id !== _this.userId; });
        },
        enumerable: true,
        configurable: true
    });
    UserReplaceOverlayComponent.prototype.delete = function (user) {
        var _this = this;
        if (confirm(this.currentLang.words["sure"])) {
            this.appService.showNetworkstatus("sending data...");
            var replacedUser = this.usersRepository.users.find(function (x) { return x.id == _this.userId; });
            replacedUser.replaceUserId = user.id;
            this.usersRepository.sendDeleteUser(replacedUser).subscribe(function (res) {
                if (res.responseCode === 200) {
                    _this.appService.showNetworkstatus("sent ok");
                    _this.usersRepository.load();
                    _this.appService.leadClients.forEach(function (leadClient) {
                        leadClient.assigneeId = replacedUser.replaceUserId;
                    });
                }
                else {
                    _this.appService.showError("API error", res.error || res.Error, true, res.responseCode);
                }
                _this.close();
            }, function (err) {
                var message = _this.appService.getErrorMessage(err);
                _this.appService.showError("HTTP error", _this.currentLang.words["http_error"] + " in users-replace-overlay delete sendDeleteUser: " + message, true);
            });
            this.usersRepository.delete(replacedUser);
        }
    };
    return UserReplaceOverlayComponent;
}());
export { UserReplaceOverlayComponent };
