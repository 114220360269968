import * as tslib_1 from "tslib";
import { DataService } from './DataServices/data.service';
import { BehaviorSubject } from 'rxjs';
import { LangsRepository } from './langs.repository';
import { ProcessesRepository } from './processes.repository';
import { AppService } from './app.service';
var ProcessesRepositoryBLT = /** @class */ (function (_super) {
    tslib_1.__extends(ProcessesRepositoryBLT, _super);
    function ProcessesRepositoryBLT(dataService, langsRepository, appService) {
        var _this = _super.call(this, langsRepository) || this;
        _this.dataService = dataService;
        _this.langsRepository = langsRepository;
        _this.appService = appService;
        _this.token = new BehaviorSubject("");
        _this.token.subscribe(function (token) {
            _this.ready.next(false);
            if (token) {
                _this.dataService.blt_getProcesses(token, _this.locale).subscribe(function (res) {
                    if (res.responseCode === 200) {
                        _this.processes = (res.data.length) ? res.data : [];
                        _this.ready.next(true);
                    }
                    else {
                        _this.appService.showError("API error", res.error || res.Error, false, res.responseCode);
                    }
                }, function (err) {
                    var message = _this.appService.getErrorMessage(err);
                    _this.appService.showError("HTTP error", _this.langsRepository.current.words["http_error"] + " in ProcessesRepositoryBLT constructor blt_getProcesses: " + message);
                });
            }
        });
        return _this;
    }
    return ProcessesRepositoryBLT;
}(ProcessesRepository));
export { ProcessesRepositoryBLT };
