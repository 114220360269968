import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { LangsRepository } from 'src/app/services/langs.repository';
import { ProcessesRepositoryBUT } from 'src/app/services/processes.repository.but';
import { SubstatusesRepository } from 'src/app/services/substatuses.repository';
import { AppService } from '../../services/app.service';
var SubStatusNewOverlayComponent = /** @class */ (function () {
    function SubStatusNewOverlayComponent(appService, langsRepository, substatusesRepository, processesRepository, loadingCtrl) {
        this.appService = appService;
        this.langsRepository = langsRepository;
        this.substatusesRepository = substatusesRepository;
        this.processesRepository = processesRepository;
        this.loadingCtrl = loadingCtrl;
        this.ready = false;
        this.activeChange = new EventEmitter();
        this.cancel = new EventEmitter();
        this.globalStatus = 0;
        this.processId = 0;
        this.langsReady = false;
        this.substatusesReady = false;
        this.processesReady = false;
        this.substatus = { title: "", statusId: null, template: "", position: 0 };
        this.advanced = false;
        this.ready = true;
        this.init();
    }
    SubStatusNewOverlayComponent.prototype.close = function () {
        this.active = false;
        this.activeChange.emit(false);
    };
    SubStatusNewOverlayComponent.prototype.init = function () {
        var _this = this;
        this.langsRepository.ready.subscribe(function (r) { if (r) {
            _this.langsReady = true;
        } });
        this.processesRepository.prepare().then(function () { _this.processesReady = true; });
        this.substatus = { title: "", statusId: null, template: "", position: 0 };
        this.substatusesRepository.prepare().then(function () { _this.substatusesReady = true; });
    };
    Object.defineProperty(SubStatusNewOverlayComponent.prototype, "statuses", {
        get: function () {
            var process = this.processesRepository.processes[0];
            return process ? process.statuses : [];
        },
        enumerable: true,
        configurable: true
    });
    SubStatusNewOverlayComponent.prototype.validate = function () {
        var err = false;
        if (!this.substatus.title.trim().length) {
            this.substatus.titleError = this.currentLang.words['enter-status'];
            err = true;
        }
        else if (this.substatus.title.trim().length > 250) {
            this.substatus.titleError = this.currentLang.words['substatus-limit-error'];
            err = true;
        }
        else if (!/^[0-9a-zA-Z\u0590-\u05FF _-]+$/.test(this.substatus.title)) {
            this.substatus.titleError = this.currentLang.words['substatus-title-error'];
            err = true;
        }
        else {
            this.substatus.titleError = "";
        }
        if (!this.substatus.position) {
            this.substatus.positionError = this.currentLang.words['substatus-position-error'];
            err = true;
        }
        if (this.substatus.position && this.substatus.position.toString().length > 10) {
            this.substatus.positionError = this.currentLang.words['substatus-position-error'];
            err = true;
        }
        if (!this.substatus.statusId) {
            this.substatus.statusIdError = this.currentLang.words['enter-status-stage'];
            err = true;
        }
        else {
            this.substatus.statusIdError = "";
        }
        return !err;
    };
    SubStatusNewOverlayComponent.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var defaultProcesses, loading_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.substatus.template = this.appService.formatText(this.substatus.template);
                        this.substatus.statusId = this.globalStatus;
                        this.substatus.processId = this.processId;
                        if (!this.validate()) return [3 /*break*/, 2];
                        this.appService.showNetworkstatus("sending data...");
                        if (this.substatus.processId == null) {
                            this.substatus.processId = this.processesRepository.processes[0].id;
                        }
                        if (this.substatus.processId === 0) {
                            defaultProcesses = this.processesRepository.processes.filter(function (i) { return i.isDefault; });
                            if (defaultProcesses.length > 0) {
                                this.substatus.processId = defaultProcesses[0].id;
                            }
                        }
                        return [4 /*yield*/, this.loadingCtrl.create({
                                message: this.currentLang.words["wait"],
                            })];
                    case 1:
                        loading_1 = _a.sent();
                        loading_1.present();
                        this.substatusesRepository.sendAddSubstatus(this.substatus).subscribe(function (res) {
                            if (res.responseCode === 200) {
                                _this.appService.showNetworkstatus("sent ok");
                                _this.substatusesRepository.substatuses.push(res.data);
                                _this.processesRepository.load();
                                _this.substatusesRepository.load();
                                _this.substatusesRepository.sort();
                                _this.close();
                                _this.substatus = { title: "", statusId: null, template: "", position: 0 };
                            }
                            else if (res.responseCode === 400) {
                                _this.substatus.titleError = _this.currentLang.words['status-exist'];
                            }
                            else {
                                _this.appService.showError("API error", res.error || res.Error, true, res.responseCode);
                            }
                            loading_1.dismiss();
                        }, function (err) {
                            loading_1.dismiss();
                            var message = _this.appService.getErrorMessage(err);
                            _this.appService.showError("HTTP error", _this.currentLang.words["http_error"] + " in substatus-new-overlay save sendAddSubstatus: " + message, true);
                        });
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    return SubStatusNewOverlayComponent;
}());
export { SubStatusNewOverlayComponent };
