import * as Sentry from "@sentry/angular";
import { DataService } from './DataServices/data.service';
import { NotificationService } from './notification.service';
import { LangsRepository } from './langs.repository';
import { WhatsAppDataService } from "./DataServices/whatsApp.data.service";
import { WhatsappService } from "./whatsapp.service";
var AuthService = /** @class */ (function () {
    function AuthService(dataService, whatsAppDataService, notificationService, whatsappService, langsRepository) {
        var _this = this;
        this.dataService = dataService;
        this.whatsAppDataService = whatsAppDataService;
        this.notificationService = notificationService;
        this.whatsappService = whatsappService;
        this.langsRepository = langsRepository;
        this.authenticated = false;
        this.user = null;
        this.prevUrl = "";
        this.hasRestriction = function (type) {
            if (!_this.user || !_this.user.activeRestrictions) {
                return false;
            }
            return _this.user.activeRestrictions.some(function (i) { return i.restriction === type; });
        };
        var authData = JSON.parse(localStorage.getItem(this.authKey));
        console.log('AuthService constructor', authData ? authData.user.email : "", this.authKey, authData);
        if (authData) {
            this.dataService.token.next(authData.token);
            if (authData.user) {
                this.dataService.userId.next(authData.user.id);
            }
            this.user = authData.user;
            this.notificationService.subscribe(this.user.id);
            this.whatsappService.subscribe(this.user);
            this.authenticated = true;
        }
    }
    Object.defineProperty(AuthService.prototype, "authKey", {
        get: function () {
            var isInIframe = (parent !== window);
            var hostname = isInIframe ? document.referrer : window.location.hostname;
            return "fxa-" + hostname;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.login = function (username, password) {
        var locale = this.langsRepository.current.locale;
        return this.dataService.login(username, password, locale);
    };
    AuthService.prototype.forgotPassword = function (username) {
        var locale = this.langsRepository.current.locale;
        return this.dataService.forgotPassword(username, locale);
    };
    AuthService.prototype.getToken = function () {
        return this.dataService.token.value;
    };
    AuthService.prototype.getClientToken = function () {
        return this.dataService.authGetToken();
    };
    AuthService.prototype.getServerApplicationVersion = function () {
        return this.dataService.getCurrentVersionFromServer();
    };
    AuthService.prototype.saveAuthData = function (authData) {
        var _this = this;
        this.dataService.token.next(authData.token);
        if (authData.user) {
            this.dataService.userId.next(authData.user.id);
        }
        this.user = authData.user;
        console.log('saveAuthData', authData ? authData.user.email : "", this.authKey, authData);
        localStorage.setItem(this.authKey, JSON.stringify(authData));
        Sentry.configureScope(function (scope) {
            if (_this.user) {
                scope.setUser({
                    id: _this.user.id.toString(),
                    email: _this.user.email,
                    username: _this.user.firstName + " " + _this.user.lastName,
                });
            }
        });
        setTimeout(function () {
            if (_this.user) {
                _this.notificationService.subscribe(_this.user.id);
                _this.whatsappService.subscribe(_this.user);
            }
        }, 1000);
        this.authenticated = true;
    };
    AuthService.prototype.logout = function () {
        if (this.user) {
            this.notificationService.unsubscribe(this.user.id);
            this.whatsappService.unsubscribe(this.user);
        }
        console.log('logout');
        localStorage.removeItem(this.authKey);
        this.dataService.token.next("");
        this.dataService.userId.next(null);
        this.user = null;
        this.authenticated = false;
    };
    AuthService.prototype.updateUser = function (user, token) {
        var _this = this;
        this.notificationService.unsubscribe(this.user.id);
        this.whatsappService.unsubscribe(this.user);
        this.user = user;
        var authData = { token: token, user: this.user };
        console.log('updateUser', authData ? authData.user.email : "", this.authKey, authData);
        localStorage.setItem(this.authKey, JSON.stringify(authData));
        setTimeout(function () {
            _this.notificationService.subscribe(_this.user.id);
            _this.whatsappService.subscribe(_this.user);
        }, 1000);
        this.dataService.token.next(token);
        if (authData.user) {
            this.dataService.userId.next(authData.user.id);
        }
    };
    AuthService.prototype.updateUserSettings = function (user) {
        var item = localStorage.getItem(this.authKey);
        var authData = JSON.parse(item);
        console.log('updateUserSettings', authData ? authData.user.email : "", this.authKey, authData);
        authData.user = user;
        localStorage.setItem(this.authKey, JSON.stringify(authData));
    };
    AuthService.prototype.getPaymentIframe = function (token, login) {
        return this.dataService.blt_getPaymentIframe(token, this.langsRepository.current.locale, login);
    };
    AuthService.prototype.addUserBySms = function (token, smsCode, login) {
        return this.dataService.blt_addUserBySms(token, smsCode, login, this.langsRepository.current.locale);
    };
    AuthService.prototype.registrationComplete = function (token, smsCode, phone, email, firstName, lastName) {
        return this.dataService.blt_registrationComplete(token, smsCode, email, phone, this.langsRepository.current.locale, firstName, lastName);
    };
    AuthService.prototype.registrationProcess = function (token, phone, email, firstName, lastName) {
        return this.dataService.blt_registrationProcess(token, email, phone, this.langsRepository.current.locale, firstName, lastName);
    };
    AuthService.prototype.resendSmsForRegistration = function (token, id) {
        return this.dataService.blt_resendSmsForRegistration(token, id, this.langsRepository.current.locale);
    };
    AuthService.prototype.sendLogAs = function (clientToken) {
        return this.dataService.authLogAs(clientToken);
    };
    AuthService.prototype.setWhatsAppToken = function (token) {
        this.whatsAppDataService.token.next(token);
    };
    AuthService.prototype.getUserByWhatsAppToken = function () {
        var _this = this;
        this.whatsAppDataService.getUserByToken().subscribe(function (res) { _this.user = res.data; });
    };
    return AuthService;
}());
export { AuthService };
