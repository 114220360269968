import { EventEmitter } from '@angular/core';
import { AppService } from '../.././services/app.service';
var IntroComponent = /** @class */ (function () {
    function IntroComponent(appService) {
        this.appService = appService;
        this.activeChange = new EventEmitter();
        this.n = 3;
        this.step = 1;
        this.pos = 0;
        this.draggable = false;
        this.transitionDuration = "0.3s";
        this.prevX = 0;
    }
    Object.defineProperty(IntroComponent.prototype, "dir", {
        get: function () { return (this.currentLang.dir === "ltr") ? -1 : 1; },
        enumerable: true,
        configurable: true
    });
    IntroComponent.prototype.close = function () {
        this.active = false;
        this.activeChange.emit(false);
        this.appService.initPermissionPopup();
    };
    IntroComponent.prototype.toStep = function (i) {
        this.step = i;
        this.setPosByStep();
    };
    IntroComponent.prototype.next = function () {
        if (this.step < this.n) {
            this.step++;
            this.setPosByStep();
        }
        else {
            this.close();
        }
    };
    IntroComponent.prototype.prev = function () {
        if (this.step > 1) {
            this.step--;
            this.setPosByStep();
        }
    };
    IntroComponent.prototype.setPosByStep = function () {
        this.pos = this.dir * 100 * (this.step - 1);
    };
    IntroComponent.prototype.onMouseDown = function (event) {
        if (event.button === 0) {
            this.draggable = true;
            this.transitionDuration = "0s";
            this.prevX = event.clientX;
        }
    };
    IntroComponent.prototype.onMouseMove = function (event) {
        if (this.draggable) {
            this.move(event.clientX);
        }
    };
    IntroComponent.prototype.onMouseUp = function (event) {
        if (event.button === 0) {
            this.stop();
            this.setPosByStep();
        }
    };
    IntroComponent.prototype.onTouchStart = function (event) {
        this.draggable = true;
        this.transitionDuration = "0s";
        this.prevX = event.touches[0].clientX;
    };
    IntroComponent.prototype.onTouchMove = function (event) {
        if (this.draggable) {
            this.move(event.touches[0].clientX);
        }
    };
    IntroComponent.prototype.onTouchEnd = function (event) {
        this.stop();
        this.setPosByStep();
    };
    IntroComponent.prototype.onMouseLeave = function (event) {
        this.onMouseUp(event);
    };
    IntroComponent.prototype.stop = function () {
        this.draggable = false;
        this.transitionDuration = "0.3s";
    };
    IntroComponent.prototype.move = function (x) {
        var prepos = 0;
        if (this.prevX > x) {
            prepos = this.pos - 1;
        }
        else if (this.prevX < x) {
            prepos = this.pos + 1;
        }
        else {
            prepos = this.pos;
        }
        if (this.dir === 1 && prepos >= 0 && prepos <= (this.n - 1) * 100) { // rtl, can move          
            if (prepos > this.pos && prepos % 100 > 10) { // closer next
                this.stop();
                this.next();
            }
            else if (prepos < this.pos && prepos % 100 < 90) { // closer prev
                this.stop();
                this.prev();
            }
            else { // move on
                this.pos = prepos;
            }
        }
        if (this.dir === -1 && prepos <= 0 && prepos >= -(this.n - 1) * 100) { // ltr, can move          
            if (prepos < this.pos && prepos % 100 < -10) { // closer next
                this.stop();
                this.next();
            }
            else if (prepos > this.pos && prepos % 100 > -90) { // closer prev
                this.stop();
                this.prev();
            }
            else { // move on
                this.pos = prepos;
            }
        }
        this.prevX = x;
    };
    return IntroComponent;
}());
export { IntroComponent };
