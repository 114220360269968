import * as tslib_1 from "tslib";
import { Platform } from '@ionic/angular';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LangsRepository } from '../../services/langs.repository';
import { AppService } from '../../services/app.service';
import { AuthService } from '../../services/auth.service';
import { AssigneesRepositoryBUT } from '../../services/assignees.repository.but';
import { LeadClientsRoutine } from '../lead.clients.routine';
import { LeadClientRepositoryBUT } from '../../services/lead.clients.repository.but';
import { ProcessesRepositoryBUT } from '../../services/processes.repository.but';
import { SettingsRepository } from '../../services/settings.repository';
import { LeadClient } from 'src/app/model/leadClients/lead.client.model';
import { SortStore } from 'src/app/model/sort.store.model';
import { FilterStore } from 'src/app/model/filter.store.model';
import { StatusType } from 'src/app/Enums/status.type.enum';
import { environment } from 'src/environments/environment';
import { LeadClientMeetingStatus } from 'src/app/model/leadClients/lead.client.meeting.meetingstatus';
var LeadClientsBUTPage = /** @class */ (function (_super) {
    tslib_1.__extends(LeadClientsBUTPage, _super);
    function LeadClientsBUTPage(langsRepository, appService, authService, assigneeRepository, route, platform, leadClientsRepository, settingsRepository, processesRepository, router) {
        var _this = _super.call(this, langsRepository, appService, authService, assigneeRepository, leadClientsRepository, settingsRepository, processesRepository, platform) || this;
        _this.langsRepository = langsRepository;
        _this.appService = appService;
        _this.authService = authService;
        _this.assigneeRepository = assigneeRepository;
        _this.route = route;
        _this.platform = platform;
        _this.leadClientsRepository = leadClientsRepository;
        _this.settingsRepository = settingsRepository;
        _this.processesRepository = processesRepository;
        _this.router = router;
        _this.langsReady = false;
        _this.leadClientsReady = false;
        _this.loading = false;
        _this.perLoad = 20;
        _this.loaded = 0;
        _this.total = 0;
        _this.leadClients = [];
        // search
        _this.advancedSearchActive = false;
        _this.presetsMinimized = false;
        _this.sortingMinimized = false;
        _this.searchKey = "";
        _this.processesSearchActive = false;
        //filters and sorting
        _this.sortActive = false;
        _this.filter = 0;
        // public dir: number = 0;
        // calendar win
        _this.leadClient4Calendar = (new LeadClient()).init();
        // public calendarActive: boolean = false;
        _this.eventSchema = '';
        // statuses win
        _this.leadClient4Statuses = (new LeadClient()).init();
        _this.leadClientParamsChanged = false;
        _this.leadClientFollowupChanged = false;
        setTimeout(_this.SetHtmlElements, 100);
        window.addEventListener("resize", _this.SetHtmlElements);
        if (_this.isCordova) {
            _this.authService.getServerApplicationVersion().toPromise().then(function (response) {
                var serverVersion = response.data.split('.')[2];
                var appVersion = environment.version.split('.')[2];
                if (+serverVersion > +appVersion) {
                    _this.appService.initVersionUpdatePopup();
                }
            });
        }
        _this.initLangs().then(function () { });
        _this.route.params.subscribe(function (params) { _this.id = parseInt(params["id"]); });
        _this.initAssignees().then(function () { });
        _this.initProcesses().then(function () {
            _this.SetupLeadClients();
        });
        _this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function (event) {
            if (event.url === '/leadClients' && _this.filter !== _this.currentFilter) {
                _this.SetupLeadClients();
            }
            setTimeout(_this.SetHtmlElements, 100);
        });
        return _this;
    }
    LeadClientsBUTPage.prototype.ionViewWillEnter = function () { };
    Object.defineProperty(LeadClientsBUTPage.prototype, "menuClass", {
        set: function (v) { this.appService.menuClass = v; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeadClientsBUTPage.prototype, "isCordova", {
        get: function () { return this.platform.is('cordova'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeadClientsBUTPage.prototype, "isAndroid", {
        get: function () { return this.platform.is('android'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeadClientsBUTPage.prototype, "currentFilter", {
        get: function () { return this.appService.currentFilter; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeadClientsBUTPage.prototype, "isExistProcesses", {
        get: function () { return this.processesRepository.processes.length != 0 && this.processesRepository.processes[0].id > 0; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeadClientsBUTPage.prototype, "processes", {
        get: function () { return this.processesRepository.processes; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeadClientsBUTPage.prototype, "statuses", {
        get: function () {
            var _this = this;
            if (this.leadClient4Statuses) {
                var process = this.processesRepository.processes.find(function (x) { return x.id == _this.leadClient4Statuses.processId; }) || this.processesRepository.processes[0];
                if (process) {
                    return process.statuses;
                }
            }
            return [];
        },
        enumerable: true,
        configurable: true
    });
    LeadClientsBUTPage.prototype.SetHtmlElements = function () {
        var elem = document.getElementsByClassName('lead-client-routing')[0];
        var bannerElem = document.getElementById("banner");
        if (bannerElem) {
            if (location.hash === "#/leadClients"
                || location.hash === "#/auth/login"
                || location.hash === "#/auth/forgotPassword"
                || location.hash === "#/comingsoon"
                || location.hash === "#/testpage"
                || location.hash === "#/privacy-policy") {
                bannerElem.style.width = "50%";
            }
            else {
                bannerElem.style.width = "25%";
            }
        }
        if (elem) {
            if (location.hash === "#/leadClients") {
                elem.classList.remove('lead-client-visible');
                elem.classList.add('lead-client-hidden');
            }
            else {
                elem.classList.remove('lead-client-hidden');
                elem.classList.add('lead-client-visible');
            }
        }
    };
    LeadClientsBUTPage.prototype.SetupLeadClients = function () {
        var _this = this;
        this.leadClientsReady = false;
        this.leadClientsRepository.ready.subscribe(function (r) {
            if (r) {
                _this.appService.leadClientsGroup = [];
                _this.advancedSearchActive = false;
                _this.loading = true;
                _this.leadClientsRepository.leadCLients = [];
                _this.loaded = 0;
                _this.loadLeadClients().then(function (leadClients) {
                    _this.appService.leadClients = leadClients;
                    _this.appService.buildGroups(leadClients);
                    _this.loading = false;
                    _this.leadClientsReady = true;
                    _this.appService.leadClients.forEach(function (x) { return x.current = false; });
                    _this.appService.leadClientsGroup.forEach(function (x) { return x.leadClients.map(function (c) { return c.current = false; }); });
                    if (_this.id > 0) {
                        var currentLeadClient = _this.appService.leadClients.find(function (x) { return x.id == _this.id; });
                        if (currentLeadClient) {
                            currentLeadClient.current = true;
                        }
                    }
                }).catch(function (err) {
                    _this.loading = false;
                    _this.leadClientsReady = true;
                });
            }
        });
    };
    LeadClientsBUTPage.prototype.onScroll = function (event) {
        var _this = this;
        if (this.loaded < this.total && !this.loading) {
            var elem = event.target;
            if (elem.scrollTop > elem.scrollHeight - elem.offsetHeight - 600) {
                this.loading = true;
                this.loadLeadClients().then(function (leadClients) {
                    _this.appService.buildGroups(leadClients);
                    _this.loading = false;
                }).catch(function (err) {
                    _this.loading = false;
                    var message = _this.appService.getErrorMessage(err);
                    _this.appService.showError(err.errType, _this.currentLang.words["http_error"] + " in leadClients-but-page onScroll loadLeadClients: " + message, true, err.errResponseCode);
                });
            }
        }
    };
    Object.defineProperty(LeadClientsBUTPage.prototype, "filterName", {
        get: function () {
            var s = "";
            switch (this.appService.currentFilter) {
                case 0:
                    s = this.currentLang.words["all-filter"];
                    break;
                case 1:
                    s = this.currentLang.words["closed-deals"];
                    break;
                case 2:
                    s = this.currentLang.words["not-relevant-leads"];
                    break;
                case 3:
                    s = this.currentLang.words["overdue-leads"];
                    break;
                case 4:
                    s = this.currentLang.words["in-process-filter"];
                    break;
                case 5:
                    s = this.currentLang.words["all-meetings"];
                    break;
                case 6:
                    s = this.currentLang.words["future-meetings-filter"];
                    break;
                case 7:
                    s = this.currentLang.words["today-meetings"];
                    break;
                case 8:
                    s = this.currentLang.words["new-filter"];
                    break;
                default: break;
            }
            return s;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeadClientsBUTPage.prototype, "sortingName", {
        get: function () {
            if (this.appService.sorting == 'followUp')
                return this.currentLang.words["followUp"];
            if (this.appService.sorting == 'createDate')
                return this.currentLang.words["createDate"];
            if (this.appService.sorting == 'updateDate')
                return this.currentLang.words["updateDate"];
            if (this.appService.sorting == 'lastMessageDate')
                return this.currentLang.words["last-message-date"];
            return this.currentLang.words["sorting"];
        },
        enumerable: true,
        configurable: true
    });
    LeadClientsBUTPage.prototype.getStatusClass = function (leadClient) {
        switch (leadClient.leadStatusId) {
            case 0: return "new";
            case 4: return "inprocess";
            case 7: return "meeting";
            case 9: return "deal";
            case 11: return "notrelevant";
            default: return "";
        }
    };
    LeadClientsBUTPage.prototype.changeFilterProcess = function (processId) {
        this.appService.processId = processId == 0 ? null : processId;
        this.searchKey = "";
        this.leadClientRepository.invalidate();
        this.leadClientsRepository.leadCLients = [];
        this.appService.leadClients = [];
        this.processesSearchActive = false;
        if (location.hash === "#/leadClients") {
            this.reloadLeadClients();
        }
        else {
            this.router.navigateByUrl("/leadClients");
            this.hideLeadClient();
            this.reloadLeadClients();
        }
    };
    LeadClientsBUTPage.prototype.changeFilter = function (filter) {
        // if (this.appService.currentFilter != filter) {
        this.appService.currentFilter = filter;
        this.searchKey = "";
        this.filter = filter;
        this.saveFilterToLocalStorage(this.appService.currentFilter, this.user.id);
        if ((filter === 0 || filter === 8) && this.appService.sorting !== "lastMessageDate") { // "All clients" and "New clients"
            this.changeSorting("lastMessageDate", false);
        }
        else if ((filter === 4 || filter === 6) && this.appService.sorting !== "followUp") { // "In process" and "Meetings"
            this.changeSorting("followUp", false);
        }
        this.leadClientRepository.invalidate();
        this.leadClientsRepository.leadCLients = [];
        this.appService.leadClients = [];
        if (location.hash === "#/leadClients") {
            this.reloadLeadClients();
        }
        else {
            this.router.navigateByUrl("/leadClients");
            this.hideLeadClient();
            this.reloadLeadClients();
        }
        // }
    };
    LeadClientsBUTPage.prototype.hideLeadClient = function () {
        var elem = document.getElementsByClassName('lead-client-routing')[0];
        if (elem) {
            elem.classList.remove('lead-client-visible');
            elem.classList.add('lead-client-hidden');
        }
        var bannerElem = document.getElementById("banner");
        if (bannerElem) {
            document.getElementById("banner").classList.add('full');
        }
    };
    LeadClientsBUTPage.prototype.changeSorting = function (sorting, reloadLeadClients) {
        if (reloadLeadClients === void 0) { reloadLeadClients = true; }
        this.appService.hideError();
        if (this.appService.sorting !== sorting) {
            this.appService.sorting = sorting;
            //this.appService.dir = (this.appService.sorting === "createDate" || this.appService.sorting === "lastMessageDate") ? 1 : 0;
            this.saveSortToLocalStorage(this.appService.sorting, this.user.id);
            if (reloadLeadClients) {
                this.leadClientRepository.invalidate();
                this.leadClientsRepository.leadCLients = [];
                this.appService.leadClients = [];
                this.reloadLeadClients();
            }
        }
        else {
            this.sortActive = false;
        }
    };
    LeadClientsBUTPage.prototype.changeDirection = function (direction) {
        this.appService.hideError();
        this.appService.dir = direction;
        this.reloadLeadClients();
    };
    LeadClientsBUTPage.prototype.search = function () {
        this.reloadLeadClients();
    };
    LeadClientsBUTPage.prototype.tryCloseSearch = function (event) {
        if (event.target.id === "leads-advancedsearch") {
            this.advancedSearchActive = false;
        }
    };
    LeadClientsBUTPage.prototype.showCalendarWin = function (leadClient) {
        this.leadClient4Calendar = leadClient;
        this.appService.calendarActive = true;
    };
    LeadClientsBUTPage.prototype.changeLeadClientFollowup = function (leadClientdatetime) {
        var _this = this;
        var newDate = this.appService.leaddatetime2date(leadClientdatetime);
        this.leadClient4Calendar.rawFollowup = newDate;
        this.leadClient4Calendar.followUp = newDate.toISOString();
        var serverDate = new Date(this.leadClient4Calendar.rawFollowup.getTime() - this.leadClient4Calendar.rawFollowup.getTimezoneOffset() * 60000);
        this.appService.calendarActive = false;
        this.appService.showNetworkstatus("sending data...");
        this.leadClientRepository.sendUpdateLeadClientFollowup(this.leadClient4Calendar.id, serverDate.toISOString())
            .subscribe(function (res) {
            if (res.responseCode === 200) {
                _this.appService.showNetworkstatus("sent ok");
                // set event in calendar for meeting
                if (_this.isCordova && _this.leadClient4Calendar.leadStatusId === 7) {
                    _this.appService.SetCalendarEvent(_this.leadClient4Calendar, _this.eventSchema, null);
                }
                _this.reloadLeadClients();
            }
            else {
                _this.appService.showNetworkstatus("error");
            }
        });
    };
    LeadClientsBUTPage.prototype.showStatusesWin = function (leadClient) {
        this.leadClient4Statuses = leadClient;
        this.leadClientParamsChanged = false;
        this.leadClientFollowupChanged = false;
        this.appService.statusesActive = true;
    };
    LeadClientsBUTPage.prototype.onStatusesWinActiveChanged = function (event) {
        var _this = this;
        this.appService.statusesActive = event;
        if (!this.appService.statusesActive && this.leadClientParamsChanged) {
            this.appService.showNetworkstatus("sending data...");
            this.leadClientsReady = true;
            // update hole lead, doesnt matter, what was changed!
            var founded = this.leadClientsRepository.leadCLients.find(function (x) { return x.id == _this.leadClient4Statuses.id; });
            if (founded) {
                founded.leadStatusId = this.leadClient4Statuses.leadStatusId;
                founded.preview = this.getLeadTemplate(this.leadClient4Statuses);
            }
            this.leadClientRepository.sendUpdateLeadClientStatus(this.leadClient4Statuses).subscribe(function (res) {
                if (res.responseCode === 200) {
                    if (_this.leadClient4Statuses.leadStatusId === StatusType.Closed) {
                        var now = new Date();
                        var serverDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
                        _this.leadClientRepository.sendLeadClientDeal(0, _this.leadClient4Statuses.id, "", _this.leadClient4Statuses.amount, _this.leadClient4Statuses.assigneeId, serverDate).subscribe(function (res) {
                            if (res.responseCode === 200) {
                                _this.appService.needResyncLeadClient = true;
                                _this.appService.showNetworkstatus("sent ok");
                                if (_this.leadClientParamsChanged) {
                                    _this.reloadLeadClients();
                                }
                                else {
                                    _this.reloadLeadClients();
                                    if (!_this.appService.currentFilter) {
                                        _this.leadClient4Statuses.preview = _this.getLeadTemplate(_this.leadClient4Statuses);
                                    }
                                }
                            }
                            else {
                                _this.appService.showNetworkstatus("error");
                            }
                        });
                    }
                    if (_this.leadClient4Statuses.leadStatusId === StatusType.Meeting) {
                        _this.leadClientsRepository.addMeeting(0, _this.leadClient4Statuses.id, _this.leadClient4Statuses.followUp, _this.leadClient4Statuses.followUp, "default subject", _this.user.id, 0, "default note", LeadClientMeetingStatus.None).subscribe(function (res) {
                            if (res.responseCode === 200) {
                                _this.appService.showNetworkstatus("sent ok");
                                _this.leadClientRepository.invalidate();
                                var founded = _this.leadClientsRepository.leadCLients.find(function (x) { return x.id == _this.leadClient4Statuses.id; });
                                if (founded) {
                                    founded.leadStatusId = _this.leadClient4Statuses.leadStatusId;
                                    founded.preview = _this.getLeadTemplate(_this.leadClient4Statuses);
                                    _this.appService.needResyncLeadClient = true;
                                    founded.followUp = _this.leadClient4Statuses.followUp;
                                    founded.rawFollowup = new Date(founded.followUp);
                                }
                                if (_this.leadClientFollowupChanged) {
                                    _this.reloadLeadClients();
                                }
                                else {
                                    _this.reloadLeadClients();
                                    if (!_this.appService.currentFilter) {
                                        _this.leadClient4Statuses.preview = _this.getLeadTemplate(_this.leadClient4Statuses);
                                    }
                                }
                                if (_this.isCordova) {
                                    _this.appService.SetCalendarEvent(_this.leadClient, _this.eventSchema, null);
                                    _this.appService.showNetworkstatus(_this.currentLang.words["add-to-local-calendar"]);
                                }
                            }
                            else {
                                _this.appService.showNetworkstatus("error");
                            }
                        });
                    }
                }
                else {
                    _this.appService.showNetworkstatus("error");
                }
            });
        }
    };
    LeadClientsBUTPage.prototype.loadLeadClients = function () {
        var _this = this;
        this.appService.hideError();
        return new Promise(function (resolve, reject) {
            if (_this.user) {
                var storeFilter = _this.getFilterLocalStorage(_this.user.id);
                if (storeFilter) {
                    _this.appService.currentFilter = +storeFilter;
                    _this.filter = +storeFilter;
                }
                var storeSort = _this.getSortLocalStorage(_this.user.id);
                if (storeSort) {
                    _this.appService.sorting = storeSort;
                    // this.appService.dir = (this.appService.sorting === "createDate" || this.appService.sorting === "updateDate" || this.appService.sorting === "lastMessageDate") ? 1 : 0;
                }
            }
            if (_this.searchKey) {
                _this.appService.currentFilter = 0;
            }
            var startTime = performance.now();
            _this.leadClientsRepository
                .loadLeadClients(_this.loaded, _this.perLoad, _this.searchKey, _this.appService.currentFilter, _this.appService.sorting, _this.appService.dir, _this.appService.processId)
                .subscribe(function (res) {
                if (res.responseCode == 200) {
                    _this.assigneesRepository.cacheKey = res.settingsCacheToken;
                    var loadedLeadClients = [];
                    if (res.data.leadClients.length) {
                        loadedLeadClients = res.data.leadClients.map(function (x) { return _this.buildLeadClient(x); });
                        _this.leadClients = res.data.leadClients;
                        _this.leadClientsRepository.leadCLients = _this.leadClientsRepository.leadCLients.concat(loadedLeadClients);
                        _this.appService.leadClients = _this.appService.leadClients.concat(loadedLeadClients);
                        _this.appService.buildGroups(_this.leadClientsRepository.leadCLients);
                        _this.leadClientsRepository.lastReload = (new Date()).getTime();
                        _this.loaded = _this.leadClientsRepository.leadCLients.length;
                    }
                    _this.total = res.data.totalCount;
                    _this.leadClientsRepository.setLeadClientsReady(true);
                    resolve(loadedLeadClients);
                    _this.loading = false;
                }
                else {
                    _this.loading = false;
                    _this.appService.showError("API error", (res.error || res.Error) + " in leadClients-but-page loadLeadClients", true, res.responseCode);
                    reject({ errType: "API error", errMsg: (res.error || res.Error) + " in leadClients-but-page loadLeadClients", errResponseCode: res.responseCode });
                }
                var endTime = performance.now();
                _this.appService.logTimeToSentry("leadClients-but-page loadLeadClients", startTime, endTime);
            }, function (err) {
                var endTime = performance.now();
                _this.appService.logTimeToSentry("leadClients-but-page loadLeadClients exc", startTime, endTime);
                var message = _this.appService.getErrorMessage(err);
                _this.appService.showError("HTTP error", _this.currentLang.words["http_error"] + " in leadClients-but-page loadLeadClients: " + message, true);
                reject({ errType: "HTTP error", errMsg: _this.currentLang.words["http_error"] + " in leadClients-but-page loadLeadClients: " + message });
            });
        });
    };
    LeadClientsBUTPage.prototype.buildLeadClient = function (data) {
        var leadClient = (new LeadClient()).build(data);
        leadClient.preview = this.getLeadTemplate(leadClient);
        return leadClient;
    };
    LeadClientsBUTPage.prototype.reloadLeadClients = function () {
        var _this = this;
        this.appService.hideError();
        this.advancedSearchActive = false;
        this.sortActive = false;
        this.leadClientsReady = false;
        this.leadClientsRepository.leadCLients = [];
        this.appService.leadClientsGroup = [];
        this.loaded = 0;
        this.loading = true;
        this.loadLeadClients().then(function (leadClients) {
            _this.appService.buildGroups(leadClients);
            _this.loading = false;
            _this.leadClientsReady = true;
        }).catch(function (err) {
            var message = _this.appService.getErrorMessage(err);
            _this.appService.showError(err.errType, _this.currentLang.words["http_error"] + " in leadClients-but-page reloadLeadClients loadLeadClients: " + message, true, err.errResponseCode, true, "lc-but-page reloadLeadClients: " + message);
            _this.loading = false;
            _this.leadClientsReady = true;
        });
    };
    LeadClientsBUTPage.prototype.saveFilterToLocalStorage = function (filter, userId) {
        var store = localStorage.getItem("currentLeadFilter");
        var arr = JSON.parse(store);
        var storeData = arr.find(function (x) { return x.userId === userId; });
        if (storeData) {
            storeData.filter = filter;
        }
        else {
            arr.push(new FilterStore(userId, filter));
        }
        localStorage.setItem("currentLeadFilter", JSON.stringify(arr));
    };
    LeadClientsBUTPage.prototype.saveSortToLocalStorage = function (sort, userId) {
        var store = localStorage.getItem("currentLeadSort");
        var arr = JSON.parse(store);
        var storeData = arr.find(function (x) { return x.userId === userId; });
        if (storeData) {
            storeData.sort = sort;
        }
        else {
            arr.push(new SortStore(userId, sort));
        }
        localStorage.setItem("currentLeadSort", JSON.stringify(arr));
    };
    LeadClientsBUTPage.prototype.getSortLocalStorage = function (userId) {
        var store = localStorage.getItem("currentLeadSort");
        if (store) {
            var arr = JSON.parse(store);
            var existRow = arr.find(function (x) { return x.userId === userId; });
            if (existRow) {
                return existRow.sort;
            }
        }
        else {
            localStorage.setItem("currentLeadSort", "[]");
        }
        return "";
    };
    LeadClientsBUTPage.prototype.getFilterLocalStorage = function (userId) {
        var store = localStorage.getItem("currentLeadFilter");
        if (store) {
            var arr = JSON.parse(store);
            var existRow = arr.find(function (x) { return x.userId === userId; });
            if (existRow) {
                return existRow.filter;
            }
        }
        else {
            localStorage.setItem("currentLeadFilter", "[]");
        }
        return 0;
    };
    LeadClientsBUTPage.prototype.clearSearchInput = function () {
        this.searchKey = '';
        this.search();
    };
    return LeadClientsBUTPage;
}(LeadClientsRoutine));
export { LeadClientsBUTPage };
